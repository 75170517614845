import React from 'react'; 
import { useHistory } from 'react-router-dom'; 

import classes from '../cabecalhos/headerHome.module.css';

import logo from '../../../assets/logo_bco.png';

import MobileNavigation from './mobileNavigationAln'; 
import Navigation from './navigationAln';

function HeaderHomeAln() { 

    let history = useHistory();

    return (
        <div className={classes.containercb}>
            <div className={classes.menu}>
                <div className={classes.titulo}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" onClick={() => history.push("/")} style={{cursor: 'pointer'}} />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
                </div>
                <div className={classes.menuSection}>             
                    <Navigation />
                    <MobileNavigation />                    
                </div>                               
            </div>                              
        </div>      
    );
}
  
export default HeaderHomeAln;