import React, {  useState, useEffect } from 'react'; 

import classesApp from '../../../App.module.css'; 

import api from '../../../../services/api';

function Instrucoes( { renderiza, teste, defTxtDados, txtDados, aln_tt_id } ) { 

    //const [dadosTes, setDadosTes] = useState
    // gerar respCloze    
    // pegar dados relacionados do texto com o teste em /txtdados/tes_id, percorrer e quando for lacuna inserir em um array temporário, depois enviá-lo para api para gravar como respostas do aluno vazia, ao carregar o teste, pegar os valores diretamente do teste do aluno e guardar a resposta no local storage 
    useEffect(() => {
        pegaPosicoes();
    }, []);

    async function pegaPosicoes() {
        try {
            const response = await api.get('txtdados/' + teste.tes_id);                
            defTxtDados(response.data); 
        } catch (error) { 
            defTxtDados([]); 
        } 
    }

    async function geraRespAln() {
        let respTemp = []; 
        txtDados.forEach(function(item) {
            if (item.tdd_tipo === 'lac' || item.tdd_tipo === 'lacf') {
                respTemp.push(item.tdd_posicao_arr);
            }            
          });
        
        const dados = {
            aln_tt_id, posicoes_resp: respTemp
        }
        try {
            const response = await api.post('/respcloze', dados); 
            alert("Texto de " + response.data.message + " palavras definido para o teste com sucesso!"); 
            // muda etapa
            const responseMdEtpPes = await api.patch('/alnteste/mdetapa', {aln_tt_etapa: 3, aln_tt_id});
            renderiza(3); 
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }                
    }

    return (
        <div>    
            <h1 style={{fontSize: '3rem', marginLeft: '1.8rem', marginBottom: '0rem'}}>Instruções</h1>        
            <div style={{display: 'flex'}}>
                <textarea 
                    name="txaPol" 
                    id="txaPol" 
                    cols="50" 
                    rows="500" 
                    className={classesApp.fonteTxa}
                    readOnly 
                >  
                    {teste.tes_instrucoes}    
                </textarea> 
            </div>            
            <div className={classesApp.containerBtnAcoes}>                    
                <label className={classesApp.btnAcoes} onClick={() => geraRespAln()} >Próxima etapa</label>
            </div>                           
        </div>        
    );
}
  
export default Instrucoes;