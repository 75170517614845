import React, { useEffect, useState } from 'react'; 

import classesApp from '../../App.module.css';

import { MdSearch } from "react-icons/md"; 

import { dados } from '../../../services/auth'; 
import MaskedInput from './maskedInput';

function TestesPesquisa( { renderiza, apresentacao, txtPesquisa, defPesquisa, tpPesq, vlrPesq, listaEscolas, verCad }) {

    const [texto, setTexto] = useState('Adicionar escola');  

    useEffect(() => {
        if (apresentacao === 3) {
            setTexto('Cancelar');
        } else {
            setTexto('Adicionar escola');
        }
    }, [apresentacao]);

    function txtBtnMenu() {
        if (apresentacao === 3) {            
            renderiza(0);
        } else {
            verCad();
        }
    }

    return (
        <div className={classesApp.containerMenuPg}>
            {
                (dados.acesso === 'dende' || dados.acesso === 'buma') && (
                    <label className={classesApp.botaoMenu} onClick={() => txtBtnMenu()}>{texto}</label> 
                )
            }            
            <div className={classesApp.containerPesquisa}>
                <label style={{marginLeft: '1rem'}}>Filtrar por:</label>   
                <input type="radio" id="rdIdentificacao" checked={tpPesq === 'nm'} value="nm" 
                    onClick={() => {
                            defPesquisa('nm'); 
                            txtPesquisa('');
                        }
                    } 
                />
                <label htmlFor="rdIdentificacao">Identificação</label>
                <input type="radio" id="rdCnpj" checked={tpPesq === 'pj'} value="pj" 
                    onClick={() => {
                        defPesquisa('pj'); 
                        txtPesquisa('');
                    }
                }
                />
                <label htmlFor="rdCnpj">CNPJ</label>                             
                
                {tpPesq === 'nm' && ( // visualiza e edita
                    <input type="text" maxLength={30} style={{marginLeft: '1rem'}} onChange={(e) => txtPesquisa(e.target.value)} value={vlrPesq} /> 
                )}

                {tpPesq === 'pj' && ( // visualiza e edita
                    <MaskedInput value={vlrPesq} onChange={(e) => txtPesquisa(e.target.value)} /> 
                )}

                <div>
                    <MdSearch 
                        size={'3rem'} 
                        className={classesApp.iconeMenuPg} 
                        onClick={() => {
                            listaEscolas(); 
                            apresentacao === 0 ? renderiza(-1) : renderiza(0);
                            }
                        }
                    /> 
                </div>                
            </div>
        </div>       
    );
}
  
export default TestesPesquisa;