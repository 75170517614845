import classes from '../cabecalhos/headerHome.module.css';
import NavLinks from './navLinksAln';

function NavigationAln() {
    return (
        <nav className={classes.Navigation}>
            <NavLinks />
        </nav>        
    );
}

export default NavigationAln;