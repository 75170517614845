import React, { useState, useEffect } from 'react'; 

import classes from '../publicas.module.css'; 
import classesApp from '../../../App.module.css';

import api from '../../../../services/api';

import logo from '../../../../assets/logo_rxo.png'; 

function Termos( { confirmaTermos } ) { 

    // atualizar na api a resposta, não aceitar em branco e retornar true após a validação na api, atualizando o status em login, receber props necessárias para realizar ações, criar state para resp 
    const [mensagem, setMensagem] = useState(''); 
    const [aceito, setAceito] = useState(false);
    const [termos, setTermos] = useState(''); 

    useEffect(() => {           
        mostraTermos(); 
    }, []); 

    function valida() { 
        if (aceito === false) {
            setMensagem('É preciso aceitar os termos para prosseguir'); 
        } else {
            confirmaTermos();
        }
        
    } 

    async function mostraTermos() {
        try { 
            const response = await api.get('/termos/ult');
            setTermos(response.data.term_texto); 
        } catch (error) { 
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }  
            setTermos([]); 
        } 
    }

    return(
        <div className={classes.centralizaTerPol}>
            <div className={classes.tituloTerPol}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" />
                    <label className={classes.txtTituloTerPol}>OnlineCloze</label>
            </div>
            <h3>Termos de uso</h3>
            <div style={{margin: '0.5rem 0.5rem'}}>
                <div className={classes.txtTerPol}>
                    <textarea 
                        name="txaTer" 
                        id="txaTer" 
                        cols="50" 
                        rows="500" 
                        className={classes.fonteTxa}
                        readOnly 
                        value={termos}
                    ></textarea> 
                </div>
                <div style={{margin: '1rem 0rem', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <label>Li, concorco e aceito os termos apresentados acima</label>
                    <input type="checkbox" value={aceito} onChange={e => aceito === true ? setAceito(false) : setAceito(true)} checked={aceito} />
                </div>                
                <div className={classes.btnCadastro}> 
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                        <label 
                            className={classesApp.btnAcaoSecundariaEscuro} 
                            style={{padding: '0.5rem 10rem'}}
                            onClick={() => valida()}                             
                        >Próxima etapa</label>
                        <label className={classes.avisoErro} style={{margin: '0rem'}}>{mensagem}</label>
                    </div>                                   
                </div>                    
            </div>                
        </div>
    );
}

export default Termos;