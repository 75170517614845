import React, { useState, useEffect } from 'react'; 
import { useHistory } from "react-router-dom"; 
import InputMask from 'react-input-mask'; 

import classesApp from '../../App.module.css';

import { MdContentCopy, MdAddBusiness } from "react-icons/md"; 

import api from '../../../services/api'; 
import pad from '../../../services/pad'; // corrige valor do mês e dia retornado 
import { dados } from '../../../services/auth'; 

function EscolasCadastro( { renderiza, escolaSel, apresentacao, ins_cnpjVer } ) {
    //console.log(escolaSel);

    const data = new Date(); 
    const dtCtr = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate());

    const [ins_nome, setIns_nome] = useState('');
    const [ins_status, setIns_status] = useState(1);
    const [ins_tipo, setIns_tipo] = useState(0);
    const [ins_cod_cad_prof, setIns_cod_cad_prof] = useState('VALOR GERADO APÓS O CADASTRO!');
    const [ins_n_aln, setIns_n_aln] = useState(0);
    const [ins_prof, setIns_prof] = useState(0);
    const [ins_dt_cad, setIns_dt_cad] = useState(dtCtr);
    const [ins_dt_controle, setIns_dt_controle] = useState(dtCtr);
    const [ins_classificacao, setIns_classificacao] = useState(0);
    const [uf_sigla, setUf_sigla] = useState(''); // talvez não use após o map
    const [cid_id, setCid_id] = useState(''); 
    const [ins_cnpj, setIns_cnpj] = useState(ins_cnpjVer);
    const [ins_limite_aln, setIns_limite_aln] = useState(0); 
    const [ufs, setUfs] = useState([]); 
    const [cidades, setCidades] = useState([]); 
    const [txtBtn, setTxtBtn] = useState('Cadastrar'); 
    const [ins_id, setInsId] = useState(0); 
    const [vinculadoIns, setVinculadoIns] = useState(false); 
    const [usuLog, setUsuLog] = useState(dados.id); 

    let history = useHistory();

    useEffect(() => {
        listaUfs(); 
        if (apresentacao <= 2 || apresentacao === 4) {
            carregaEscola(); 
        }
        if (apresentacao < 3) { 
            verificaVinculo();
            setTxtBtn('Atualizar dados');
        } else if (apresentacao === 3) {
            setTxtBtn('Cadastrar'); 
            //setIns_cnpj(ins_cnpjVer);
        } else {
            setTxtBtn('Voltar');
        }
    }, [uf_sigla, apresentacao]);

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText('Insira o código a seguir ao realizar seu cadastro: ' + copyMe);
            //alert('Código copiado');
        } catch (err) {
            alert('Falha ao copiar!');
        }
      };

    async function handleCreate(){
        const dadosCad = {
            ins_nome, 
            ins_status, 
            ins_tipo, 
            ins_n_aln, 
            ins_prof, 
            ins_dt_cad, 
            ins_dt_controle, 
            ins_classificacao, 
            cid_id, 
            ins_cnpj, 
            ins_limite_aln
        };
        
        try {
            const response = await api.post('/instituicoes', dadosCad); 
            alert("Código para cadastro de professores: " + response.data);            
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }        
    }

    async function listaUfs() {
        const response = await api.get('/ufs');
        setUfs(response.data);        
    }

    async function listaCidades(sigla_uf) {
        setUf_sigla(sigla_uf);
        const response = await api.get('/cidadesuf?sigla=' + sigla_uf);
        setCidades(response.data);
    }

    function carregaEscola() {
            setInsId(escolaSel[0].ins_id);
            setIns_nome(escolaSel[0].ins_nome); 
            setIns_status(escolaSel[0].ins_status); 
            setIns_tipo(escolaSel[0].ins_tipo); 
            setIns_cod_cad_prof(escolaSel[0].ins_cod_cad_prof); 
            setIns_n_aln(escolaSel[0].ins_n_aln); 
            setIns_prof(escolaSel[0].ins_prof); 

            let data = new Date(escolaSel[0].ins_dt_cad); 
            const dtCad = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate());
            setIns_dt_cad(dtCad); 
            
            data = new Date(escolaSel[0].ins_dt_controle); 
            const dtCtr = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate());
            setIns_dt_controle(dtCtr); 

            setIns_classificacao(escolaSel[0].ins_classificacao); 
                        
            idUf(escolaSel[0].cid_id);
            listaCidades(uf_sigla);
            setCid_id(escolaSel[0].cid_id);             
            //console.log('cidade: ' + escolaSel[0].cid_id);

            setIns_cnpj(escolaSel[0].ins_cnpj); 
            setIns_limite_aln(escolaSel[0].ins_limite_aln);
    }

    async function idUf(id_cid) {
        const response = await api.get('/cidades/' + id_cid);
        setUf_sigla(response.data[0].uf_sigla);        
    }

    async function handleUpdate(id) {
        const dadosAt = {
            ins_nome, 
            ins_status, 
            ins_tipo, 
            ins_cod_cad_prof, 
            ins_n_aln, 
            ins_prof, 
            ins_dt_cad, 
            ins_dt_controle, 
            ins_classificacao, 
            cid_id, 
            ins_cnpj, 
            ins_limite_aln
        };
        
        try {
            await api.patch('/instituicoes/' + id, dadosAt); 
            alert("Dados atualizados");
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }
    }

    function handleCadAt(id) {
        if (handleValida() === true) {
            if (apresentacao < 4) {
                if (apresentacao === 3) {
                    handleCreate();            
                } else {
                    if (dados.acesso === 'dende') {
                        handleUpdate(id);
                    } else {
                        alert('Seu usuário não tem autorização para modificar os dados!');
                    }
                }
            }            
            renderiza(-1); 
        }        
    }

    function handleValida() { 
        let irregulares = '';
        if (ins_nome === '') irregulares += '- nome' + '\n'; 
        if (ins_nome.length > 0 && ins_nome.length <= 8) irregulares += '- inserir o nome completo da escola' + '\n';
        if (ins_status === '' || ins_status === '-1') irregulares += '- status' + '\n'; 
        if (ins_tipo === '' || ins_tipo === '-1') irregulares += '- tipo' + '\n';  
        if (ins_dt_controle === '') irregulares += '- data de controle' + '\n';  
        if (ins_classificacao === '-1') irregulares += '- classificação' + '\n';  
        if (cid_id === '-1') irregulares += '- cidade' + '\n';  
        if (ins_cnpj === '') irregulares += '- CNPJ' + '\n';  
        if (ins_limite_aln === '') irregulares += '- limite de alunos' + '\n';  
                    
        if (irregulares === '') {
            return true;
        } else {
            alert("Campos a serem preenchidos: " + '\n' + irregulares);
            return false;
        }
    } 

    async function verificaVinculo() {         
        try {
            const response = await api.get('/usuins?ins=' + ins_id + '&usu=' + usuLog);  
            if (response.data[0]) {
                setVinculadoIns(true); 
            } else { 
                setVinculadoIns(false);
            }
        } catch {
            setVinculadoIns(false); 
        } 
    }

    async function vinculaUsuIns(){ 
        const usin_vinculado = 2; 
        const usu_id = usuLog;
        const dados = {
            usu_id, 
            ins_id, 
            usin_vinculado
        };
        
        try {
            const response = await api.post('/usuins', dados); 
            alert("Id do vínculo: " + response.data);    
            history.push("/homeprof");
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }        
    }

    return (
        <div>            
            <label htmlFor="nomeEscola">Nome escola</label>
            <input type="text" name='nomeEscola' maxLength={60} value={ins_nome} onChange={e => setIns_nome(e.target.value)} />
            <div className={classesApp.colunas}>
                <label className={classesApp.lrg20}>Estado</label>
                <label className={classesApp.lrg40} style={{marginLeft: '1rem'}}>Cidade</label>
                <label className={classesApp.lrg40} style={{marginLeft: '1rem'}}>CNPJ</label>
            </div>
            <div className={classesApp.colunas}>
                <select className={classesApp.lrg20} value={uf_sigla} onChange={e => listaCidades(e.target.value)} name="selUf" id="selUf">
                    <option value="-1" style={{height: '2rem'}}>-</option>
                    {
                        ufs.map(uf => (
                            <option key={uf.uf_id} value={uf.uf_sigla}>{uf.uf_sigla}</option>
                        ))
                    }
                </select>
                <select className={classesApp.lrg40} style={{marginLeft: '1rem'}} value={cid_id} onChange={e => setCid_id(e.target.value)} name="selCid" id="selCid">
                    <option value="-1">-</option>
                    {
                        cidades.map(cidade => (
                            <option key={cidade.cid_id} value={cidade.cid_id}>{cidade.cid_nome}</option>
                        ))
                    }
                </select>
                <InputMask mask={"99.999.999/9999-99"} value={ins_cnpj} disabled className={classesApp.lrg40} maxLength={18} style={{marginLeft: '1rem'}} />
            </div>

            <div className={classesApp.colunas}>
                <label className={classesApp.lrg50}>Cód. Cad. Professor</label>
                <label className={classesApp.lrg30} style={{marginLeft: '1rem'}}>Classificação</label>
                <label className={classesApp.lrg20} style={{marginLeft: '1rem'}}>Limite Aln.</label>
            </div>                
            <div className={classesApp.colunas}>
                <div className={classesApp.lrg50}>
                    <input type="text" value={ins_cod_cad_prof} disabled maxLength={12} style={{textTransform: 'uppercase'}} />
                    <MdContentCopy className={classesApp.iconeForm} size={'3rem'} onClick={() => copyToClipBoard(ins_cod_cad_prof)} />
                </div>
                <select value={ins_classificacao} onChange={e => setIns_classificacao(e.target.value)} className={classesApp.lrg30} style={{marginLeft: '1rem'}}>
                    <option value="-1">-</option>
                    <option value="0">Pública</option>
                    <option value="1">Privada</option>
                    <option value="2">Empresa</option>
                </select>
                <input type="number" value={ins_limite_aln} onChange={e => setIns_limite_aln(e.target.value)} className={classesApp.lrg20} style={{marginLeft: '1rem'}} />
            </div>                
            
            <div className={classesApp.colunas}>
                <label className={classesApp.lrg30}>Status</label>
                <label className={classesApp.lrg30} style={{marginLeft: '1rem'}}>Tipo</label>
                <label className={classesApp.lrg20} style={{marginLeft: '1rem'}}>Nº alunos</label>
                <label className={classesApp.lrg20} style={{marginLeft: '1rem'}}>Nº professores</label>
            </div>
            <div className={classesApp.colunas}>
                <select value={ins_status} onChange={e => setIns_status(e.target.value)} className={classesApp.lrg30}>
                    <option value="-1">-</option>
                    <option value="0">Inativa</option>
                    <option value="1">Ativa</option>
                </select>
                <select value={ins_tipo} onChange={e => setIns_tipo(e.target.value)} className={classesApp.lrg30} style={{marginLeft: '1rem'}}>
                    <option value="-1">-</option>
                    <option value="0">Pesquisa</option>
                    <option value="1">Aplicação</option>
                </select>
                <input type="number" value={ins_n_aln} disabled onChange={e => setIns_n_aln(e.target.value)} className={classesApp.lrg20} style={{marginLeft: '1rem'}} />
                <input type="number" value={ins_prof} disabled onChange={e => setIns_prof(e.target.value)} className={classesApp.lrg20} style={{marginLeft: '1rem'}} />
            </div>

            <div className={classesApp.colunas}>
                <label className={classesApp.lrg50}>Data cadastro</label>
                <label className={classesApp.lrg50} style={{marginLeft: '1rem'}}>Data controle</label>
            </div>
            <div className={classesApp.colunas}>
                <input type="date" value={ins_dt_cad} disabled onChange={e => setIns_dt_cad(e.target.value)} className={classesApp.lrg50} />
                <input type="date" value={ins_dt_controle} disabled onChange={e => setIns_dt_controle(e.target.value)} className={classesApp.lrg50} style={{marginLeft: '1rem'}} />
            </div>

            <div className={classesApp.containerBtnAcoes}>                             
                <label 
                    className={classesApp.btnAcoes} 
                    style={(apresentacao === 3 || vinculadoIns === true) ? {display: 'none'} : { display: 'flex', alignItems: 'center' }}  
                    onClick={() => vinculaUsuIns()}                   
                >
                    <MdAddBusiness size={'2.5rem'} className={classesApp.iconeMenu} /> 
                    Vincular a meu usuário
                </label>              
                <label className={classesApp.btnAcoes} onClick={() => handleCadAt(ins_id)}>{txtBtn}</label>
            </div> 
        </div>       
    );
}
  
export default EscolasCadastro;