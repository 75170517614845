import React, { useState } from 'react'; 
import { Link, useParams, useHistory } from 'react-router-dom'; 

import classes from './publicas.module.css'; 
import classesApp from '../../App.module.css';

import logo from '../../../assets/logo_rxo.png'; 
import { MdVisibility } from "react-icons/md"; 

import api from '../../../services/api'; 
import pad from '../../../services/pad'; // corrige valor do mês e dia retornado

function Mensagem( { sucesso }) { 
    return(
        <div className={classes.centralizaLog}>
            <div className={classes.titulo}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
            </div>
            <label style={{fontSize: '3rem', margin: '3rem 0rem'}} >{sucesso}</label>
            {/*<label style={{margin: '2rem 1rem', textAlign:'center'}}>Em instantes você receberá um e-mail solicitando a ativação de seu usuário.</label>*/}
            <Link 
                to='/'
                className={classesApp.btnAcaoSecundariaEscuro} 
                style={{width:'40%', textAlign:'center', margin:'2rem'}}
            >Prosseguir</Link>        
        </div>
    ); 
}

function Cadastro( { mudaEtapa, defMessage } ) {  

    const {codigo = ''} = useParams();
    const data = new Date(); 
    const dtCtr = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate());
    const [mensErro, setMensErro] = useState(''); 

    let history = useHistory();

    const [mostraSenha, setMostraSenha] = useState(false); 
    const [nome, setNome] = useState(''); 
    const [email, setEmail] = useState(''); // valida na api
    const [codInsc, setCodInsc] = useState(codigo); // valida na api
    const [senha, setSenha] = useState(''); 
    const [senhaConf, setSenhaConf] = useState(''); 
    const [dataCad, setDataCad] = useState(dtCtr); 
    let usu_tipo = ''; // para Usuário 

    // -Verificar na validação de acordo com o código P = pesquisador, D = doscente, A = Aluno
    // USUÁRIOS: 0 – Escola, 1 – Professor, 2 – Pesquisador, 3 – ADM, 9 - aluno
    // Diferenciar se a letra, pois Alunos ficam em outra tabela     



    function exibe() {
        mostraSenha ? setMostraSenha(false) : setMostraSenha(true); 
    }    

    async function handleCreateUsu() { 
        let usu_nome = nome.toUpperCase(); 
        let usu_email = email.toLocaleLowerCase(); 
        let usu_chave_acesso = senha; 
        let usu_dt_cadastro = dataCad;

        const dados = {
            usu_nome, 
            usu_email, 
            codInsc, 
            usu_chave_acesso, 
            usu_tipo, 
            usu_dt_cadastro
        }

        try {
            const response = await api.post('/usuarios', dados); 
            defMessage(response.data.message);  
            mudaEtapa();                 
        } catch (err) { 
            setMensErro(err.response.data.message); 
        }
    } 

    async function handleCreateAln() { 
        let aln_nome = nome.toUpperCase(); 
        let aln_email = email.toLowerCase(); 
        let aln_chave_acesso = senha; 
        let aln_dt_cad = dataCad;

        const dados = {
            aln_nome, 
            aln_email, 
            codInsc, 
            aln_chave_acesso, 
            aln_dt_cad
        }

        try {
            const response = await api.post('/alunos', dados); 
            defMessage(response.data.message);   
            mudaEtapa();        
        } catch (err) { 
            setMensErro(err.response.data.message); 
        }
    } 

    function handleValida() { 
        let irregulares = '';
        if (nome === '') irregulares += '- nome' + '\n'; 
        if (nome.length > 0 && nome.length <= 7) irregulares += '- inserir seu nome completo' + '\n'; 
        if (email > 0 && email <= 7) irregulares += '- inserir o endereço de e-mail completo' + '\n'; 
        if (validateEmail(email) === false) irregulares += '- e-mail inválido' + '\n';
        if (senha === '') irregulares += '- senha' + '\n'; 
        if (senha.length > 0 && senha.length <= 5) irregulares += '- a senha deve ter pelo menos 6 caracteres' + '\n'; 
        let codigoLtr = '';
        if (codInsc !== '') { 
            codigoLtr = codInsc.substring(0, 1);
        }        
        if (codigoLtr == 'P') { // pesquisador 
            usu_tipo = 2;
        } else if (codigoLtr == 'D') { // doscente 
            usu_tipo = 1;            
        } else if (codigoLtr == 'A') { // aluno
            usu_tipo = 9;
        } else { // inválido
            irregulares += '- código de inscrição inválido' + '\n';
        }

        if (!(senha === '' && senhaConf === '')) {
            if (senha !== senhaConf) irregulares += '- o valor da senha e da confirmação da senha devem coincidir';
        }
        
        if (irregulares === '') {
            return true;
        } else {
            alert("Campos a serem preenchidos: " + '\n' + irregulares);
            return false;
        }
    }

    function validateEmail(email) { 
    // https://www.horadecodar.com.br/2020/09/13/como-validar-email-com-javascript/
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    } 

    function handleCad() { 
        const validacao = handleValida();
        if (validacao === true) { 
            if (usu_tipo === 9) {
                handleCreateAln(); 
            } else {
                handleCreateUsu(); 
            }            
        } 
    }

    function recebeCodInsc(cod){
        const codUpper = cod.toUpperCase();
        setCodInsc(codUpper); 
    }

    return(
        <div className={classes.centralizaCad}>
            <div className={classes.titulo}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" onClick={() => history.push("/")} style={{cursor: 'pointer'}} />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
            </div>
            <div className={classes.cadastro}>
                <label>Nome completo</label>
                <input type="text" maxLength={60} value={nome} onChange={e => setNome(e.target.value)} />         
                <label>E-mail</label>
                <input type="text" maxLength={70} value={email} onChange={e => setEmail(e.target.value)} />
                <div>
                    <label>Código de inscrição </label><label className={classesApp.duvida}>? <label className={classesApp.infoDuvida}> Fornecido pela instituição. Pesquisadores devem solicitar o código através do e-mail: contato@onlinecloze.com.br. Caso o valor já esteja preenchido, favor não alterar!'</label></label>
                </div>
                <input 
                    type="text" 
                    maxLength={12} 
                    //style={{textTransform:'uppercase'}} 
                    onChange={e => recebeCodInsc(e.target.value)}
                    value={codInsc} 
                />
                <div>
                    <div className={classesApp.colunas}>
                        <label className={classesApp.lrg50}>Senha</label>
                        <label className={classesApp.lrg50} style={{marginLeft: '1rem'}}>Confirmar senha</label>
                    </div>
                    <div className={classesApp.colunas}>
                        <div className={classesApp.lrg50} >                            
                            <input type={mostraSenha ? "text" : "password"} maxLength={20} value={senha} onChange={e => setSenha(e.target.value)} />
                            <MdVisibility size={'2.5rem'} className={classesApp.iconeForm} style={{position: 'absolute', right: '22.5rem'}} onClick={() => exibe()} /> 
                        </div>
                        <div className={classesApp.lrg50} style={{marginLeft: '1rem'}}>
                            <input type={mostraSenha ? "text" : "password"} maxLength={20} value={senhaConf} onChange={e => setSenhaConf(e.target.value)} />
                            <MdVisibility size={'2.5rem'} className={classesApp.iconeForm} style={{position: 'absolute', right: '2.6rem'}} onClick={() => exibe()} /> 
                        </div>
                                                
                    </div>                    
                </div>                
                <div className={classes.btnCadastro}>     
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                        <label className={classes.avisoErro}>{mensErro}</label>
                        <label 
                            className={classesApp.btnAcaoSecundariaEscuro} 
                            style={{margin: '1rem 2rem', padding: '1rem 10rem'}}
                            onClick={() => handleCad()} 
                        >Registrar</label>                    
                    </div>                                   
                </div>                    
            </div>                
        </div>
    );
}

function CadUsu() {  
    const [sucesso, setSucesso] = useState('');
    const [etapa, setEtapa] = useState(false);

    function mudaEtapa() {
        setEtapa(true);
    } 

    function defMessage(valor) { 
        setSucesso(valor);
    }

    if (etapa === false) 
        return  <Cadastro mudaEtapa={mudaEtapa} defMessage={defMessage} /> // Cadastro(mudaEtapa={mudaEtapa}); 
    else 
        return <Mensagem sucesso={sucesso} />;
} 

export default CadUsu;