import React from 'react';

import classes from './publicas.module.css';
import classesApp from '../../App.module.css';

import PageHeader from '../cabecalhos/headerPages';

import { MdMenuBook } from "react-icons/md";

function Sobre() {
    return (
        <div className={classesApp.centraliza}>
            <div className={classesApp.home} id='sobre'>
                <PageHeader title={'Sobre o OnlineCloze'}/>                
                {/*<h2>Sobre o projeto</h2>*/}
                <p>
                Com o objetivo de facilitar a criação deste tipo de atividade, será necessário apenas selecionar um texto, inserir na plataforma e definir lacunas que serão inseridas no lugar de palavras. Ao realizar a atividade o aluno precisa preencher as lacunas com a palavra correta. 
                </p>
                <p>
                Professores e pesquisadores cadastrados aqui podem criar testes de forma automática com diferentes textos, para compartilhar com alunos que estejam registrados no sistema. Os resultados podem ser acessados e filtrados por turma, por escola e por características dos estudantes.                     
                </p>
                <p>
                O ambiente do aluno permite acessar um teste e verificar os já realizados. A pontuação, a respostas do aluno, as respostas corretas e a correção podem ser acessadas apenas com autorização do criador do teste.                    
                </p>
                <p>
                Este site foi desenvolvido como produto no programa de Mestrado Profissional em Gestão e Desenvolvimento da Educação Profissional por Ewerton José da Silva, orientado pela Profa. Dra. Neide de Brito Cunha.
                </p>
                <div className={classes.imgCentro}>
                    <MdMenuBook 
                        size={'18.0rem'} 
                        color='#320b86'                         
                    /> 
                </div>                
            </div>                                                                         
        </div>              
    );
}
  
export default Sobre;