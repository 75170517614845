import { useState } from 'react';

import classes from '../cabecalhos/headerHome.module.css';
import NavLinks from './navLinksAln';

import { MdMenu, MdClose } from "react-icons/md";

function MobileNavigationAln() {

    const [open, setOpen] = useState(false); 

    const hamburgerIcon = <MdMenu 
                            className={classes.Hamburger} 
                            size='4.0rem' 
                            color='#fafafa' 
                            onClick={() => setOpen(!open)}
                        /> 

    const closeIcon = <MdClose 
                        className={classes.Hamburger} 
                        size='4.0rem' 
                        color='#fafafa' 
                        onClick={() => setOpen(!open)}
                    />                        

    const closeMobileMenu = () => setOpen(false);
    
    return ( 
        <nav className={classes.MobileNavigation}>
            {open ? closeIcon : hamburgerIcon}
            {open && <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu} />}
        </nav>        
    );
}

export default MobileNavigationAln;