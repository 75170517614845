import React, { useState } from 'react'; 
import { Link, useHistory  } from 'react-router-dom'; 

import classes from '../publicas.module.css'; 
import classesApp from '../../../App.module.css';

import logo from '../../../../assets/logo_rxo.png'; 

function Email( { identificaUsu, mensagem, apresMensagem } ) { 

    const [email, setEmail] = useState(''); 
    let history = useHistory();

    return(
        <div  className={classes.centralizaLog}>
            <div className={classes.titulo}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" onClick={() => history.push("/")} style={{cursor: 'pointer'}} />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
                </div>
                <label>Fazer login</label>
                <input 
                    type="email" 
                    placeholder='e-mail' 
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);                        
                    }}
                />
                <div className={classes.containerBtnAuxilio}>
                    <label 
                        className={classes.btnAuxilio} 
                        onClick={() => apresMensagem('Comunique ao professor responsável')}
                    >Esqueceu o e-mail?</label>
                </div>
                <div className={classes.containerBtnPub}>
                    <Link 
                        to='/cadusu'  
                        style={{textDecoration:'none'}}                                                
                    ><label className={classesApp.btnAcaoSecundariaClaro}>Criar conta</label></Link>
                    <label 
                        className={classesApp.btnAcaoSecundariaEscuro}
                        onClick={() => {
                            identificaUsu(email);          
                        }}
                    >Prosseguir</label>
                </div>
                {mensagem && (
                    <div className={classes.avisoErro} style={{textAlign: 'center', marginTop: '1rem'}}>
                        <label style={{lineHeight: 'normal', fontSize: '1.6rem'}}>{mensagem}</label>
                    </div>
                )
                }                
        </div>
    );
} 

export default Email;