import React, { useEffect } from 'react';

import classesApp from '../../App.module.css'; 

import pad from '../../../services/pad';

function PoliticasLista( { politicas, listaPoliticas, exibePolitica } ) {  

    useEffect(() => {
        listaPoliticas();
    }, []); 

    function apresentaData(dtBd) {
        let data = new Date(dtBd); 
        const dt =  pad(data.getDate()) + '/' + pad(data.getUTCMonth() + 1) + '/' + data.getFullYear(); 
        return dt;
    } 

    if (politicas[0]) {
        return (
            <>                
                <div className={classesApp.bordaTable}>
                    <table>
                        <thead>
                            <tr>
                                <th className={classesApp.ttTable}>Id</th>
                                <th className={classesApp.ttTable}>Data</th>   
                            </tr>
                        </thead>  
                        <tbody>
                            {
                                politicas.map(politica => (
                                    <tr key={politica.pol_id} onClick={() => exibePolitica(politica.pol_id)}>
                                        <th>{politica.pol_id}</th>
                                        <th>{apresentaData(politica.pol_dt_pub)}</th> 
                                    </tr>
                                ))
                            }                                              
                        </tbody>  
                    </table>                
                </div>                  
            </>     
        );
    } else {
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                Nenhum termo foi encontrado!
            </div>
        );
    }
    
}
  
export default PoliticasLista;