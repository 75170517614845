import React, { useState, useEffect } from 'react'; 

import CadTextos from './CadTextos'; 
import ApresentaResultado from './ApresentaResultado';

/*import '../../App.css'; */
import './Controle.css';

function TextosPrincipal() { 

    const [etapa, setEtapa] = useState(1);
    const [arrTxt, setArrTxt] = useState([]); // array com palavras e pontuações separados
    const [texto, setTexto] = useState(''); // armazena texto inserido no txtArea 
    const [txtLbl, setTxtLbl] = useState(); // array gerado map para renderização de labels
    const [txtInp, setTxtInp] = useState(); // array gerado map para renderização de inputs
    const [plvExc, setPlvExc] = useState([]); // tipos de itens do arrTxt 
    const [respostas, setRespostas] = useState([]); // armazena respostas no array 
    let arrTpPlv = []; // array temporário para controle de envio de dados para plvExc 

    function atualizaTexto(e) {
        setTexto(e.target.value); // função que permite atualizar o texto do txtArea
    }    

    useEffect(() => {
        // 1ª função chamada paraa separar os pontos e as palavras
        function preparaTxt() { 
            let txt = texto;
            let tamanho = txt.length; // pega o tamanho do parâmtro passado que tem o state txtInserido
            let tempArray = []; // criado para troca de valores com arrTxt onde passa as palavras separadas
            let ehPlv = true; // verifica se vai formar uma palavra
            let anterior = 'esp'; // armazena o tipo da posição anterior do array
            let palavra = ''; // permite formar uma palavra para gravar em uma possição do array
            let tipo = 'ltr'; //esp, ltr, pnt - guarda o tipo de valor de uma posição do array com o texto original
            let ctrlPt = 'false'; // usada para distinguir quando tem "..."
            const regex = /[!, ., ?, ' ', (, ), :, “, ”, ", ;]/; // usado para armazenar valores condiderados pontuação (n consegui trat "..."" aqui)
            
            for (let n = 0; n < tamanho; n++) {
                // percorre todo o texto
                ehPlv = false; // identificador para verificar se uma palavra será composta
                /* 
                forçar um \n no final e sempre verificar se o próximos 2 formam um \n, caso não tenha pontuação inserir
                por enquanto todo texto deve ter um ponto finalizando o último paragráfo
                */
                if (regex.test(txt[n]) === true) {
                    // diferencia pontos do regex e caracteres comuns
                    tipo = 'pnt'; // se for um sinal
                } else {
                    tipo = 'ltr'; // se for uma letra
                    ehPlv = true; // se for letra pode compor uma palavra
                }

                if (anterior !== 'ltr' && txt[n] === '-') {
                    // verifica se o "-" está no início de uma palavra
                    tipo = 'pnt'; // "-" para inicio de diálogo é considerado pontuação
                    ehPlv = false; // dessa forma palavra é falso
                }
                
                if (txt[n] === ' ') {
                    tipo = 'esp'; // verifica se é um espaço vazio para ignorar posteriormente
                }

                if (ehPlv === true && tipo === 'ltr') {
                    // quebra de linha é representada por 2 caracteres
                    palavra += txt[n]; // inicialmente é tratado como palavra
                    if (palavra === '\n') {
                        // se for identificada uma quebra ela será postada como pontuação
                        tempArray.push(palavra);
                        palavra = ''; // reseta a variável de composição de palavras
                        anterior = 'esp'; // a quebra é definida como espaço
                    }
                }

                if (anterior === 'ltr' && tipo !== 'ltr') {
                    // se o caractere depois de uma letra não for outra, definimos uma palavra a ser gravada
                    tempArray.push(palavra);
                    palavra = '';
                }

                if (tipo === 'pnt') {
                    // tratamento para pontuação e evita problemas com "..."
                    if (txt[n] === '.') {
                        ctrlPt = txt[n - 1] === '.' || txt[n + 1] === '.';
                        if (ctrlPt === false) {
                            tempArray.push(txt[n]);
                        }
                    } else {
                        tempArray.push(txt[n]);
                    }
                }

                if (txt[n - 2] === '.' && txt[n - 1] === '.' && txt[n] === '.') {
                    // insere pontuação de "..."
                    tempArray.push('...');
                }

                anterior = tipo; // passa um valor para controle do caractere anterior
            }
            setArrTxt(tempArray); // finaliza a função passando os valores separados para o state como um array        
        }          
        
        preparaTxt();
    }, [texto]); 

    

    // 2ª função usada para identificar o que é palavra e o que não é - o parâmetro é state array com palavras e pontuações separados
    function pLabel(txt) {
        let arrExcluidas = []; // array temporário
        let codlbl; // usada para armazenar o retorno que será em jsx
        const atpl = /[“, (]/; //sinais antes palavra
        const dppl = /[!, ., ..., ?, ), :, ”, ;]/; //sinais depois da palavra
        const sinais = /[“, (, !, ., ?, ), :, ”, ;]/; // todos os sinais
        let tAtpl; // armazena resultado da verificação se é um sinal que vem antes da palavra
        let tDppl; // armazena resultado da verificação se é um sinal que vem depois da palavra
        let tSinal; // armazena resultado da verificação se é um sinal
        let tipo; // variável temporária para o tipo
        let arrlab = txt.map((vlr, i) => {
            tAtpl = atpl.test(vlr); //verifica se eh sinal antes
            tDppl = dppl.test(vlr); // verifica se eh sinal depois
            tSinal = sinais.test(vlr); // verifica só se é sinal
            // verifica se atual é sinal antes e próximo é palavra ou se é palavra e próximo é sinal depois
            if (tSinal === true) {
            if (tAtpl === true) {
                tipo = 'sat'; // sinal antes de palavra
            }
            if (tDppl === true) {
                const idfptesp = sinais.test(txt[i + 1]);
                if (idfptesp === true) {
                tipo = 'sat';
                } else {
                tipo = 'sdp'; // sinal depois de palavra
                }
            }
            } else {
            // se estiver classificado como palavra distingue quebra e palavras
            if (vlr === '\n') {
                tipo = 'br';
            } else {
                const idf = dppl.test(txt[i + 1]); // verifica se o próximo valor é uma pontuação
                //console.log(txt[i] + ' - ' + idf);
                if (idf === true) {
                tipo = 'plnf'; // palavra em final de paragrafo se o próximo for pontuação
                } else {
                tipo = 'pln'; // palavra normal, onde será inserido um espaço após a inserção da palavra no label
                }
            }
            }
            arrExcluidas.push(tipo); // insere o tipo referente a posição no array da palavra

            codlbl = geraComponente(i, vlr, tipo); // gera o que será renderizado em jsx para cada posição do array
            return codlbl; // esta função retornar o jsx de cada posição para o array temporário
        });

        arrTpPlv = arrExcluidas; // passa valor do tipo das posições para variável de auxílio - precisei dela para atualizar os valores corretamente através do setPlvExl
        setPlvExc(arrTpPlv); // atualiza state com array de tipo de palavras
        setTxtLbl(arrlab); // atualizar array que exibe o texto em labels
    }

    // 3ª função usada para retornar um JSX com a palavra ou pontuação
    function geraComponente(chave, valor, tipo) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp

        if (tipo === 'br') {
            return <br key={chave} />;
        }

        if (tipo === 'pln') {
            return (
                <label
                    key={chave}
                    value={valor}
                    onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plnf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor}
                </label>
                );
        }

        if (tipo === 'pls') {
            return (
                <label
                    key={chave}
                    value={valor}
                    onClick={() => excPlv(chave)}
                    className="txt_lbl_s" // formatação diferente para palavras que foram excluídas da possibilidade de se tornar lacunas
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plsf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    onClick={() => excPlv(chave)}
                    className="txt_lbl_s" // formatação diferente para palavras que foram excluídas da possibilidade de se tornar lacunas
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sat') {
            return (
                <label key={chave} value={valor} className="txt_lbl_n">
                    {valor}
                </label>
            );
        }

        if (tipo === 'sdp') {
            return (
                <label key={chave} value={valor} className="txt_lbl_n">
                    {valor + ' '}
                </label>
            );
        }
    }    

    // 4ª função retirar palavras do texto
    function excPlv(chave) {
        let plvExcTemp = arrTpPlv; // array tipo palavra será atualizado, por isso criado um temporário

        // mudar valor ao clicar, com base no valor anterior o atual é invertido
        if (plvExcTemp[chave] === 'plnf') {
            plvExcTemp[chave] = 'plsf';
        } else if (plvExcTemp[chave] === 'pln') {
            plvExcTemp[chave] = 'pls';
        } else if (plvExcTemp[chave] === 'plsf') {
            plvExcTemp[chave] = 'plnf';
        } else if (plvExcTemp[chave] === 'pls') {
            plvExcTemp[chave] = 'pln';
        }

        // renderizar
        let codlbl;
        // percorre todo array temporário de tipo de palavras para gerar uma visualização atualizada, destacando a palavra excluida da possibilidade de se tornar uma lacuna
        let arrlab = arrTxt.map((vlr, i) => {
            codlbl = geraComponente(i, vlr, plvExcTemp[i]);
            return codlbl;
        });

        // atualiza os valores da lista de tipos e o que será renderizado como labels
        arrTpPlv = plvExcTemp;
        setPlvExc(arrTpPlv);
        setTxtLbl(arrlab);
    }
    
    // 5ª função gerar input 5ª palavra
    function lacunaCinco() {
        let plvTipoTemp = plvExc; // var temporária para atualizar o tipo da palavra no array
        // !!! - controle para identificar o 5º elemento (POSSÍVEL PERMITIR QUE O USUÁRIO ESCOLHA A QUANTIDADE DE INTERVALO! - IMPLEMENTAR - PARA ISSO CRIAR UMA VARIÁVEL QUE RECECBA ESTE INTERVALO QUE POR ENQUANTO É FIXO)
        let controle = 0;

        plvTipoTemp.forEach((valor, i) => {
            // se o tipo for referente a uma palavra que pode se tornar lacuna incrementa o controle
            if (valor === 'plnf' || valor === 'pln') {
                controle++;
            }
            // se for a quinta palavra que faz parte da contagem muda seu tipo para lacuna
            if (controle === 5) {
            // distinção entre palavra que está no final ou no meio de um paragráfo
            if (valor === 'plnf') {
                plvTipoTemp[i] = 'lacf';
            } else {
                plvTipoTemp[i] = 'lac';
            }
            controle = 0; // reseta o controle para começar a contagem novamente
            }
        });

        // prepara nova renderização, agora com inputs nos locais de acordo com o nº passado, respeitando a exclusão de palavras
        let codlbl;
        let arrlab = arrTxt.map((vlr, i) => {
            codlbl = geraAtividade(i, vlr, plvTipoTemp[i]);
            return codlbl;
        });

        // atualiza valores para exibição
        arrTpPlv = plvTipoTemp;
        setPlvExc(arrTpPlv);
        setTxtInp(arrlab);
    }

    // 6ª função usada para retornar um JSX inputs
    function geraAtividade(chave, valor, tipo) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp
        // lacf (lacuna no final), lac (lacuna) 
        //console.log(plvExc); - usado para gerar valores da tabela 
        if (tipo === 'br') {
            return <br key={chave} />;
        }

        if (tipo === 'pln') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)} --> excluídos evento programado, das palavras
                    className="txt_lbl_n"
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plnf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'pls') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plsf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sat') {
            return (
                <label key={chave} value={valor} className="txt_lbl_n">
                    {valor}
                </label>
            );
        }

        if (tipo === 'sdp') {
            return (
                <label key={chave} value={valor} className="txt_lbl_n">
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'lacf') {
            return (
                <input 
                    key={chave}
                    type="text"
                    style={{
                        width: valor.length * 13, // fui testando multiplicadores para encontrar um tamanho que se ajustasse a  fonte
                        fontSize: 'large',
                        fontFamily: 'monospace', // tipo de fonte usado para garantir que todas letras ocupem o mesmo espaço
                        textAlign: 'center',  
                    }} 
                    id={chave}                    
                    onChange={(e) => gravaResp(e)}
                />
            );
        }

        if (tipo === 'lac') {
            return (
                /* 
                    coloquei lacunas de meio de paragráfo gerando um input e um label com espaço em um span para garantir o 
                    espaço entre o input e a próxima palavra, no anterior não precisa, porque o próximo caractere 
                    será uma pontuação
                */
                <span key={chave + '_' + valor + 'spn'}>
                    <input 
                        key={chave}
                        type="text"
                        style={{
                            width: valor.length * 13,
                            fontSize: 'large',
                            fontFamily: 'monospace', 
                            textAlign: 'center', 
                        }} 
                        id={chave}
                        onChange={(e) => gravaResp(e)}
                    />
                    <label key={chave + '_' + valor + 'lbl'} className='txt_lbl_espaco'> </label>
                </span>
            );
        }
    } 

    // 7ª função usada para retornar mostrar respostas corretas
    function mostraCorretas(chave, valor, tipo) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp
        // lacf (lacuna no final), lac (lacuna)
        if (tipo === 'br') {
            return <br key={chave} />;
        }

        if (tipo === 'pln') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)} --> excluídos evento programado, das palavras
                    className="txt_lbl_n"
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plnf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'pls') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plsf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sat') {
            return (
                <label key={chave} value={valor} className="txt_lbl_n">
                    {valor}
                </label>
            );
        }

        if (tipo === 'sdp') {
            return (
                <label key={chave} value={valor} className="txt_lbl_n">
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'lacf') {
            return (
                <input 
                    key={chave}
                    type='text' 
                    className='inputRespCorreta' 
                    style={{
                        width: valor.length + 1 + 'ch', // define o tamanho do input pelo número de caracteres
                    }}
                    value={valor} 
                    readOnly
                />
            );
        }

        if (tipo === 'lac') {
            return (
                /* 
                    coloquei lacunas de meio de paragráfo gerando um input e um label com espaço em um span para garantir o 
                    espaço entre o input e a próxima palavra, no anterior não precisa, porque o próximo caractere 
                    será uma pontuação
                */
                <span key={chave + '_' + valor + 'spn'}>
                    <input 
                        key={chave}
                        type='text' 
                        className='inputRespCorreta' 
                        style={{
                            width: valor.length + 1 + 'ch',
                        }}
                        value={valor} 
                        readOnly
                    />
                    <label key={chave + '_' + valor + 'lbl'} className='txt_lbl_espaco'> </label>
                </span>
            );
        }
    }  

    // armazena respostas
    function gravaResp(e) {
        let arrResp = respostas; 
        arrResp[e.target.id] = e.target.value;
        setRespostas(arrResp); 
        //console.log(arrResp);
    }
    
    // apresenta texto com input e respostas corretas
    function correcao() {
        let codlbl;
        let arrlab = arrTxt.map((vlr, i) => {
            codlbl = mostraCorretas(i, vlr, plvExc[i]);
            return codlbl;
        });
        return arrlab;
    }

    // 8ª função usada para retornar mostrar respostas do usuario
    function mostraRespUsu(chave, valor, tipo) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp
        // lacf (lacuna no final), lac (lacuna)    
        let correta = false; 

        if (valor === respostas[chave]) {
            correta = true;
        }

        if (tipo === 'br') {
            return <br key={chave} />;
        }

        if (tipo === 'pln') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)} --> excluídos evento programado, das palavras
                    className="txt_lbl_n"
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plnf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'pls') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plsf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    className="txt_lbl_n"
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sat') {
            return (
                <label key={chave} value={valor} className="txt_lbl_n">
                    {valor}
                </label>
            );
        }

        if (tipo === 'sdp') {
            return (
                <label key={chave} value={valor} className="txt_lbl_n">
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'lacf') {
            return (
                <input 
                    key={chave}
                    type='text' 
                    className= { correta === false ? 'inputRespErrada' : 'inputRespCorreta'} 
                    style={{
                        width: valor.length + 1 + 'ch', // define o tamanho do input pelo número de caracteres
                    }}
                    value={respostas[chave]} 
                    readOnly
                />
            );
        }

        if (tipo === 'lac') {
            return (
                /* 
                    coloquei lacunas de meio de paragráfo gerando um input e um label com espaço em um span para garantir o 
                    espaço entre o input e a próxima palavra, no anterior não precisa, porque o próximo caractere 
                    será uma pontuação
                */
                <span key={chave + '_' + valor + 'spn'}>
                    <input 
                        key={chave}
                        type='text' 
                        className= { correta === false ? 'inputRespErrada' : 'inputRespCorreta'}
                        style={{
                            width: valor.length + 1 + 'ch',
                        }}
                        value={respostas[chave]} 
                        readOnly
                    />
                    <label key={chave + '_' + valor + 'lbl'} className='txt_lbl_espaco'> </label>
                </span>
            );
        }
    }

    // apresenta texto com input e respostas do usuario
    function respUsu() { 
        let codlbl;
        let arrlab = arrTxt.map((vlr, i) => {
            codlbl = mostraRespUsu(i, vlr, plvExc[i]);
            return codlbl;
        });
        return arrlab;
    }

    // apresenta visualização e chama funções de acordo com as etapas
    function etapasMais() {
        if (etapa === 1) {
            pLabel(arrTxt);
        } 
        if (etapa === 2) {
            lacunaCinco();
        }
        setEtapa(etapa +1);
    } 

    // apresenta visualização e chama funções de acordo com as etapas
    function etapaMenos() {        
        setEtapa(etapa -1);
        if (etapa === 3) { 
            pLabel(arrTxt);
        }         
    } 

    // controle de exibição condicional quando necessário mais de uma opção
    function exibeInput () {
        if (etapa === 3 || etapa === 4) {
            return txtInp 
        }
    }
 
    return (
        <div className='container'>
            {/* controle de exibição condicional simples */}
            {etapa === 1 && (
                <CadTextos texto={texto} atualizaTexto={atualizaTexto} />
            )}
            
            {etapa === 2  && (
                <>
                    {txtLbl}
                </>
            )}

            {exibeInput()}  

            {etapa >= 5 && (                
                <ApresentaResultado  
                    controleBtn={etapa} 
                    etapasMais={etapasMais} 
                    etapaMenos={etapaMenos} 
                    arrTxt={arrTxt} 
                    plvExc={plvExc} 
                    respostas={respostas} 
                />
            )} 

            {etapa === 5 && (
                //correcao()
                respUsu() 
            )}            

            {etapa === 6 && (
                correcao()
                //respUsu() 
            )}             
            

            <div className='navegacao'> 
                <div className='txtEtapa'>
                    <label className='txtEtapa'>Etapa {etapa} de 3</label>                     
                </div> 
                <div className='divBotoes'>
                    {etapa > 1 && (
                        <button 
                            className='buttonEtapa' 
                            onClick={() => etapaMenos()}
                        >
                            Etapa anterior
                        </button>
                    )}
                    {etapa < 3 && (
                        <button 
                            className='buttonEtapa' 
                            onClick={() => etapasMais()}
                        >
                            Próxima etapa
                        </button>
                    )}

                    {etapa === 3 && (
                        <button 
                            className='buttonSimulacao' 
                            onClick={() => etapasMais()}
                        >
                            Simulação atividade
                        </button>
                    )}

                    {etapa === 4 && (
                        <button 
                            className='buttonSimulacao' 
                            onClick={() => etapasMais()}
                        >
                            Finalizar atividade
                        </button>
                    )}                  
                </div> 
            </div>
        </div>      
    );
}
  
export default TextosPrincipal;
  