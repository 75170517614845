import React, { useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css'; 

import api from '../../../services/api'; 
import { dados } from '../../../services/auth'; 
import MaskedInput from './maskedInput';

import Header from '../professores/headerHomeProf';
import Menu from './escolasMenu';
import Lista from './escolasLista'; 
import Cadastro from './escolasCadastro';

function Escolas() {
    const [apresentacao, setApresentacao] = useState(0); 
    const [escolas, setEscolas] = useState([]); 
    const [escolaSel, setEscolaSel] = useState([]); 
    const [vlrPesq, setVlrPesq] = useState(''); 
    const [tpPesq, setTpPesq] = useState('nm'); 
    const [showMessage, setShowMessage] = useState(false); 
    const [ins_cnpj, setIns_cnpj] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0); // usado para posicionar no início da tela ao carregar esta página
    }, []);

    async function listaEscolas() {
        let response = [];
        if (tpPesq === 'nm') {
            try {
                if (dados.acesso === 'dende') {
                    response = await api.get('/instituicoes?nome=' + vlrPesq); 
                } else {
                    response = await api.get('/instituicoes?usu_id=' + dados.id + '&nome=' + vlrPesq); 
                }
                setEscolas(response.data); 
            } catch(erro) { 
                console.log('Erro: ' + erro);
                setEscolas([]); 
            }
        } else {
            try {
                response = await api.get('/instituicoes/cnpj/' + vlrPesq);                 
                setEscolas(response.data); 
            } catch(erro) { 
                console.log('Erro: ' + erro);
                setEscolas([]);                 
            }
        }
        
    }  

    function renderiza(op) {
        setApresentacao(op);
    }

    function carregaEscola(escola) {
        setEscolaSel(escola);
    }
    
    function defPesquisa(vlr) {
        setTpPesq(vlr);
    }

    function txtPesquisa(vlr) {
        setVlrPesq(vlr);
    }

    function verCad() {
        setShowMessage(true); 
    } 

    async function verCnpj() {
        try {
            const response = await api.get('/instituicoes/cnpj/' + ins_cnpj);   
            setEscolaSel(response.data);            
            if (response.data.length > 0) {
                alert("Instituição já cadastrada!")
                setApresentacao(4);                 
            } else {
                setApresentacao(3);      
            }
        } catch {
            setApresentacao(3); 
            console.log('ERRO!');
        }
        setIns_cnpj('');
    }

    return (
        <div className={classesApp.centraliza}>
            <Header />
            <div className={classesApp.home}> 
                
                <div 
                    className={classesApp.formPopup} 
                    style={!showMessage ? {display: 'none'} : { display: 'flex' }}
                >
                    <h2 style={{color: '#fafafa'}}>Insira o CNPJ da instituição que deseja cadastrar</h2>
                    <MaskedInput value={ins_cnpj} onChange={(e) => setIns_cnpj(e.target.value)} /> 
                    {/*<input type="text" value={ins_cnpj} maxLength={18} onChange={e => setIns_cnpj(e.target.value)} style={{margin: '2rem 0rem', textAlign: 'center'}} />*/}
                    <div style={{display: 'flex', justifyContent: 'right'}}>
                        <label 
                            onClick={() => {                                
                                setShowMessage(false);
                                verCnpj();
                            }}
                        >Continuar</label>
                    </div>                
                </div>
                
                <h1>Escolas</h1> 
                <Menu renderiza={renderiza} apresentacao={apresentacao} vlrPesq={vlrPesq} defPesquisa={defPesquisa} tpPesq={tpPesq} txtPesquisa={txtPesquisa} listaEscolas={listaEscolas} verCad={verCad} />
                {/* inicia com listagem das escolas com paginação */} 
                {apresentacao <= 2 && ( // só lista
                    <Lista renderiza={renderiza} carregaEscola={carregaEscola} apresentacao={apresentacao} escolas={escolas} listaEscolas={listaEscolas} />
                )}
                {apresentacao === 1 && ( // visualiza e edita
                    <Cadastro renderiza={renderiza} escolaSel={escolaSel} apresentacao={apresentacao}  />
                )} 
                {apresentacao === 2 && ( // visualiza e edita                
                    <Cadastro renderiza={renderiza} escolaSel={escolaSel} apresentacao={apresentacao}  />
                )}
                {apresentacao === 3 && ( // cadastro
                    <Cadastro renderiza={renderiza} escolaSel={escolaSel} apresentacao={apresentacao} ins_cnpjVer={ins_cnpj} />
                )}
                {apresentacao === 4 && ( // Carrega instituição já cadastrada
                    <Cadastro renderiza={renderiza} escolaSel={escolaSel} apresentacao={apresentacao} />
                )}
            </div>              
        </div>        
    );
}
  
export default Escolas;