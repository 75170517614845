import React, { useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css';

import { MdContentCopy } from "react-icons/md"; 

import api from '../../../services/api'; 
import { dados } from '../../../services/auth'; 
import pad from '../../../services/pad'; // corrige valor do mês e dia retornado

function TestesCadastro( { renderiza, apresentacao, txtSelTeste, tesSel, defineDdTemp, tesTemp, carregarTxt, defineIdTes } ) {

    // txtSelTeste, carregarTxt -> carregar txt sel

    const data = new Date(); 
    const dtCtr = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate()); 

    const [tes_id, setTes_id] = useState(0);
    const [txt_id, setTxt_id] = useState(txtSelTeste[0]);
    const [tes_identificacao, setTes_identificacao] = useState('');
    const [usu_id, setUsu_id] = useState(dados.id); // valor temp até login estar pronto
    const [tes_tipo, setTes_tipo] = useState('-1');
    const [tes_cod_acesso, setTes_cod_acesso] = useState('SERÁ GERADO APÓS A CRIAÇÃO DO TESTE');
    const [tes_dt_criacao, setTes_dt_criacao] = useState(dtCtr);
    const [tes_instrucoes, setTes_intrucoes] = useState('O texto a seguir apresenta algumas lacunas. Por favor, leia o texto todo e depois volte ao início e preencha as lacunas com as palavras que julgar mais adequadas, levando em consideração o assunto apresentado. Obrigado pela colaboração!');
    const [tes_link_form, setTes_link_form] = useState('');
    const [tes_apres_resp, setTes_apres_resp] = useState('-1'); 
    const [txt_titulo, setTxt_titulo] = useState(txtSelTeste[1]); 
    const [tes_situacao, setTes_situacao] = useState('-1');

    useEffect(() => {           
        if (apresentacao === 1 || apresentacao === 2) {
            handleCarregaTes(); 
        } 
        if (apresentacao === 3 && dados.tipo !== 'buma') {
            setTes_tipo(0);
        } else {
            setTes_tipo(1);
        }

        if (tesTemp != [] && apresentacao === 3) {
            setTes_identificacao(tesTemp.tes_identificacao); 
            setUsu_id(tesTemp.usuario); 
            setTes_tipo(tesTemp.tes_tipo); 
            setTes_intrucoes(tesTemp.tes_instrucoes); 
            setTes_link_form(tesTemp.tes_link_form);  
            setTes_apres_resp(tesTemp.tes_apres_resp); 
            setTes_situacao(tesTemp.tes_situacao); 
        }
    }, []);

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText('Insira o código a seguir para acessar o teste: ' + copyMe);
            //alert('Código copiado');
        } catch (err) {
            alert('Falha ao copiar!');
        }
    }; 

    async function handleCarregaTes() { 
        setTes_id(tesSel.tes_id); 
        setTxt_id(tesSel.txt_id);
        setTes_identificacao(tesSel.tes_identificacao); 
        setUsu_id(tesSel.usu_id); 
        setTes_tipo(tesSel.tes_tipo); 
        setTes_cod_acesso(tesSel.tes_cod_acesso);         
        
        let data = new Date(tesSel.tes_dt_criacao); 
        const dtCad = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate());
        setTes_dt_criacao(dtCad);
        
        setTes_intrucoes(tesSel.tes_instrucoes); 
        setTes_link_form(tesSel.tes_link_form);  
        setTes_apres_resp(tesSel.tes_apres_resp); 
        setTes_situacao(tesSel.tes_situacao); 

        const response = await api.get('/textos/perfil/' + tesSel.txt_id);         
        //const titulo = (response.data[0].txt_titulo); 
        //console.log(tesSel[0].tes_id);
        //console.log(response.data[0]);
        setTxt_titulo(response.data[0].txt_titulo);
    }

    async function handleCreate() {
        const dadosCad = {
            txt_id, tes_identificacao, usu_id: dados.id, tes_tipo, tes_dt_criacao, tes_instrucoes, tes_link_form, tes_apres_resp, tes_situacao
        };

        try {
            const response = await api.post('/testes', dadosCad); 
            // passar o id do teste cadastrado para gerar as lacunas relacionadas 
            defineIdTes(response.data.idCad);
            alert("Código de acesso ao teste criado: " + response.data.tes_cod_acesso);                 
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }
    }

    async function handleCatAt() { 
        //console.log(txt_id); // passa o id seja em modo cadastro ou visualização 
        if (apresentacao === 3) { // vai ser no cadastro
            const response = await api.get('/textos/perfil/' + txt_id);     
            preparaTxt(response.data[0].txt_completo); // array com palavras e pontuações separados
            // passar texto do teste
        }        
        if (handleValida() === true) {
            if (apresentacao === 3) {
                handleCreate();                 
                renderiza(4); // 4 - - para continuar o cadastro
            } else {
                // visualiza teste
                renderiza(6);
            }
            
        }     
    }

    function handleValida() {
        let irregulares = '';
        if (tes_identificacao === '') irregulares += '- identificação do teste' + '\n'; 
        if (tes_identificacao.length > 0 && tes_identificacao.length <= 5) irregulares += '- inserir uma identificação mais relevante para o teste' + '\n'; 
        if (tes_tipo === '-1') { 
            irregulares += '- definir o tipo de teste que será aplicado' + '\n';  
        }
        if (tes_apres_resp === '-1') irregulares += '- definir como os resultados serão apresentados' + '\n';  
        if (tes_situacao === '-1') irregulares += '- definir a situação do teste' + '\n';
        if (tes_instrucoes === '') irregulares += '- as instruções não podem ficar em branco' + '\n'; 
        if (tes_instrucoes.length > 0 && tes_instrucoes.length <= 5) irregulares += '- definir as instruções com mais detalhes' + '\n'; 
        if (txtSelTeste === []) irregulares += '- selecione o texto para o teste' + '\n'; 
       // if (tes_link_form === '') irregulares += '- insira um link de formulário para aplicar antes do teste' + '\n'; 
       // if (tes_link_form.length > 0 && txt_titulo.length <= 10) irregulares += '- inserir o link completo' + '\n';

       if (irregulares === '') {
            return true;
        } else {
            alert("Campos a serem preenchidos: " + '\n' + irregulares);
            return false;
        }
    }

    // 1ª função chamada para separar os pontos e as palavras
    function preparaTxt(txtPrep) { 
        let txt = txtPrep;
        let tamanho = txt.length; // pega o tamanho do parâmtro passado que tem o state txtInserido
        let tempArray = []; // criado para troca de valores com arrTxt onde passa as palavras separadas
        let ehPlv = true; // verifica se vai formar uma palavra
        let anterior = 'esp'; // armazena o tipo da posição anterior do array
        let palavra = ''; // permite formar uma palavra para gravar em uma possição do array
        let tipo = 'ltr'; //esp, ltr, pnt - guarda o tipo de valor de uma posição do array com o texto original
        let ctrlPt = 'false'; // usada para distinguir quando tem "..."
        //const regex = /[!, ., ?, ' ', (, ), :, “, ”, ", ;]/; // usado para armazenar valores condiderados pontuação (n consegui trat "..."" aqui)
        //const regex = /[.’'\[\](){}⟨⟩:,،、‒–—―…!.‹›«»‐\-?‘’“”'";/⁄·\&*@\•^†‡°”¡¿※#№÷×ºª%‰+−=‱¶′″‴§~_|‖¦©℗®℠™¤]/; 
        const regex = /[a-zA-Zà-úÀ-Ú0-9]/;
        for (let n = 0; n < tamanho; n++) {
            // percorre todo o texto
            ehPlv = false; // identificador para verificar se uma palavra será composta
            /* 
            forçar um \n no final e sempre verificar se o próximos 2 formam um \n, caso não tenha pontuação inserir
            por enquanto todo texto deve ter um ponto finalizando o último paragráfo
            */
            if (!regex.test(txt[n]) === true) {
                // diferencia pontos do regex e caracteres comuns
                tipo = 'pnt'; // se for um sinal
            } else {             
                tipo = 'ltr'; // se for uma letra
                ehPlv = true; // se for letra pode compor uma palavra                              
            }

            if (anterior !== 'ltr' && txt[n] === '-') {
                // verifica se o "-" está no início de uma palavra
                tipo = 'pnt'; // "-" para inicio de diálogo é considerado pontuação
                ehPlv = false; // dessa forma palavra é falso
            }
            
            if (txt[n] === ' ') {
                tipo = 'esp'; // verifica se é um espaço vazio para ignorar posteriormente
            }

            if (ehPlv === true && tipo === 'ltr') {
                // quebra de linha é representada por 2 caracteres
                palavra += txt[n]; // inicialmente é tratado como palavra
                if (palavra === '\n') {
                    // se for identificada uma quebra ela será postada como pontuação
                    tempArray.push(palavra);
                    palavra = ''; // reseta a variável de composição de palavras
                    anterior = 'esp'; // a quebra é definida como espaço
                }
            }

            if (anterior === 'ltr' && tipo !== 'ltr') {
                // se o caractere depois de uma letra não for outra, definimos uma palavra a ser gravada
                tempArray.push(palavra);
                palavra = '';
            }

            if (tipo === 'pnt') {
                // tratamento para pontuação e evita problemas com "..."
                if (txt[n] === '.') {
                    ctrlPt = txt[n - 1] === '.' || txt[n + 1] === '.';
                    if (ctrlPt === false) {
                        tempArray.push(txt[n]);
                    }
                } else {
                    tempArray.push(txt[n]);
                }
            }

            if (txt[n - 2] === '.' && txt[n - 1] === '.' && txt[n] === '.') {
                // insere pontuação de "..."
                tempArray.push('...');
            }

            anterior = tipo; // passa um valor para controle do caractere anterior
        }
        carregarTxt(tempArray); // finaliza a função passando os valores separados para o state como um array        
    } 
    
    async function alteraSituacao(vlr) {
        setTes_situacao(vlr); 
        if (renderiza !== 3 && vlr !== '-1') { 
            const dadosAt = {
                tes_id, tes_situacao: vlr
            };
            
            try {
                await api.patch('/testes/situacao', dadosAt); 
                //alert(tes_id);
            } catch (err) {
                alert("Erro no cadastro, tente novamente.");
            }
        }
    } 

    async function alteraApresResp(vlr) {
        setTes_apres_resp(vlr); 
        if (renderiza !== 3 && vlr !== '-1') { 
            const dadosAt = {
                tes_id, tes_apres_resp: vlr
            };
            
            try {
                await api.patch('/testes/apresres', dadosAt); 
                //alert(tes_id);
            } catch (err) {
                alert("Erro no cadastro, tente novamente.");
            }
        }
    }

    return (
        <div>            
            <label>Identificação do teste</label>
            <input type="text" maxLength={120} value={tes_identificacao} onChange={e => setTes_identificacao(e.target.value)} /> 
            <div className={classesApp.colunas}>
                <label className={classesApp.lrg25}>Tipo de teste</label>
                <label className={classesApp.lrg25} style={{marginLeft: '1rem'}}>Situação</label>
                <label className={classesApp.lrg50} style={{marginLeft: '1rem'}}>Apresentação de resultados</label>
            </div>
            <div className={classesApp.colunas}>

                {dados.tipo === 'dende' && (
                    <select name="tipoTeste" id="tipoTeste" className={classesApp.lrg25} value={tes_tipo} onChange={e => setTes_tipo(e.target.value)} >
                        <option value="-1">-</option>
                        <option value="0">Pesquisa</option>
                        <option value="1">Atividade</option>
                    </select>
                )} 
                {dados.tipo !== 'dende' && (
                    <input type="text" maxLength={500} className={classesApp.lrg25} disabled value={tes_tipo === 0 ? 'Pesquisa' : 'Atividade'} /> 
                )} 
                
                <select name="tipoTeste" id="tipoTeste" className={classesApp.lrg25} style={{marginLeft: '1rem'}} value={tes_situacao} onChange={e => alteraSituacao(e.target.value)} >
                    <option value="-1">-</option>
                    <option value="0">Bloqueado</option>
                    <option value="1">Liberado</option>
                </select>
                <select name="apresRes" id="apresRes" className={classesApp.lrg50} style={{marginLeft: '1rem'}} value={tes_apres_resp} onChange={e => alteraApresResp(e.target.value)}>
                    <option value="-1">-</option>
                    <option value="0">Nada</option>
                    <option value="1">Apenas pontuação</option>
                    <option value="2">Somente respostas do aluno sem correção</option>
                    <option value="3">Somente respostas do aluno apontando os erros e acertos</option>
                    <option value="4">Mostrar tudo</option>
                </select>
            </div>            
            <label>Código de acesso ao teste</label>
            <div className={classesApp.inputIcone100}>
                <input type="text" maxLength={30} style={{textTransform:'uppercase'}} disabled value={tes_cod_acesso} />
                <MdContentCopy className={classesApp.iconeForm} size={'3rem'} onClick={() => copyToClipBoard(tes_cod_acesso)} />
            </div>                
            <label>Instruções </label><label style={{fontWeight: '400'}}>(o texto apresentado pode ser modificado conforme a necessidade)</label>
            <textarea name="txaInst" id="txaInst" cols="30" rows="8" maxLength={500} value={tes_instrucoes} onChange={e => setTes_intrucoes(e.target.value)}></textarea>
            <label>Texto</label> 
            <div className={classesApp.inputIcone100}>
                <input type="text" maxLength={500} style={{marginBottom: '0rem'}} disabled value={txt_titulo} /> 
                {/* Não será link, a tela de pesquisa de texto vai abrir aqui como um componente */}
                <label className={classesApp.btnAcaoSecundariaClaro} style={{padding: '0.4rem', border: 'solid 0.3rem', margin: '0rem', marginLeft: '1rem', width: '15rem', textAlign: 'center'}} onClick={() => { 
                    defineDdTemp(
                        {tes_identificacao, usu_id, tes_tipo, tes_instrucoes, tes_link_form, tes_apres_resp, tes_situacao}
                    );
                    renderiza(8); 
                }}>Escolher...</label>
            </div>
            <label>Link do Formulário com termos e/ou pesquisa </label><label style={{fontWeight: '400'}}>(campo opcional)</label>
            <textarea name="txaLnk" id="txaLnk" cols="30" rows="3" maxLength={500} value={tes_link_form} onChange={e => setTes_link_form(e.target.value)} style={{padding: '0.8rem'}}></textarea>
            <div className={classesApp.containerBtnAcoes}>                    
                <label className={classesApp.btnAcoes} onClick={() => handleCatAt()}>{apresentacao === 3 ? 'Salvar' : 'Ver teste'}</label>                                             
            </div>                            
        </div>        
    );
}
  
export default TestesCadastro;