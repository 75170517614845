import React, { useState, useEffect } from 'react'; 

import classes from '../publicas.module.css'; 
import classesApp from '../../../App.module.css';

import api from '../../../../services/api';

import logo from '../../../../assets/logo_rxo.png'; 

function Politicas( { confirmaPoliticas } ) { 

    const [mensagem, setMensagem] = useState(''); 
    const [aceito, setAceito] = useState(false); 
    const [politicas, setPoliticas] = useState(''); 

    useEffect(() => {           
        mostraPoliticas(); 
    }, []); 

    function valida() { 
        if (aceito === false) {
            setMensagem('É preciso aceitar os termos para prosseguir'); 
        } else {
            confirmaPoliticas();
        }
        
    }

    async function mostraPoliticas() {
        try { 
            const response = await api.get('/politicas/ult');
            setPoliticas(response.data.pol_texto); 
        } catch (error) { 
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }  
            setPoliticas([]); 
        } 
    }
    
    return(
        <div className={classes.centralizaTerPol}>
            <div className={classes.tituloTerPol}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" />
                    <label className={classes.txtTituloTerPol}>OnlineCloze</label>
            </div>
            <h3>Políticas de privacidade</h3>
            <div style={{margin: '0.5rem 0.5rem'}}>
                <div className={classes.txtTerPol}>
                    <textarea 
                        name="txaPol" 
                        id="txaPol" 
                        cols="50" 
                        rows="500" 
                        className={classes.fonteTxa}
                        readOnly 
                        value={politicas}
                    ></textarea> 
                </div> 
                <div style={{margin: '1rem 0rem', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <label>Li, concorco e aceito as políticas apresentadas acima</label>
                    <input type="checkbox" value={aceito} onChange={e => aceito === true ? setAceito(false) : setAceito(true)} checked={aceito} />
                </div>                
                <div className={classes.btnCadastro}>     
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                        <label 
                            className={classesApp.btnAcaoSecundariaEscuro} 
                            style={{padding: '0.5rem 10rem'}}
                            onClick={() => valida()}                             
                        >Próxima etapa</label>
                        <label className={classes.avisoErro} style={{margin: '0rem'}}>{mensagem}</label>
                    </div>                                   
                </div>                    
            </div>                
        </div>
    );
}

export default Politicas;