import React, { useState, useEffect } from 'react'; 

/*import '../../App.css';*/

function ApresentaResultado({ controleBtn, etapasMais, etapaMenos, arrTxt, plvExc, respostas }) { 

    const [pts, setPts] = useState([0, 0]); 

    useEffect(() => {
        respUsu();
    }, [pts]); 

    // 8ª função usada para retornar mostrar respostas do usuario
    function mostraRespUsu(valor, chave) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp
        // lacf (lacuna no final), lac (lacuna) 
        let res = pts;   
        let respCorr = 0;
        let nLacunas = 0; 
        if (plvExc[chave] === 'lac' || plvExc[chave] === 'lacf') {
           nLacunas++;            
        }

        if (valor === respostas[chave]) {
            respCorr++; 
        }
        
        res[0] = pts[0] + nLacunas; 
        res[1] = pts[1] + respCorr;
        setPts(res);

    }

    // apresenta texto com input e respostas do usuario
    function respUsu() { 
        arrTxt.forEach(mostraRespUsu);
    }

    return (
        <div className='navegacao'> 
            <div className='divBotoes'>
                <div className='txtEtapa'>
                    <label className='txtEtapa'>
                        Total acertos: {pts[1]} de {pts[0]}
                    </label>                     
                </div> 
                {controleBtn === 5 && (
                    <button 
                        className='buttonEtapa' 
                        onClick={() => etapasMais()}
                    >
                        Ver respostas corretas
                    </button>
                )}
                {controleBtn === 6 && (
                    <button 
                        className='buttonEtapa' 
                        onClick={() => etapaMenos()}
                    >
                        Ver minhas respostas
                    </button>
                )}                
            </div> 
        </div>         
    );
}
  
export default ApresentaResultado;

