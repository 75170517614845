import React, { useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css';

import { MdContentCopy } from "react-icons/md"; 

import api from '../../../services/api';

function CadTurmas( { renderiza, idInst, idCur, statusVis } ) { 

    const [cur_id, setCur_id] = useState(idCur[0]); 
    const [trm_nome, setTrm_nome] = useState(''); 
    const [trm_turno, setTrm_turno] = useState(-1); 
    const [trm_periodo, setTrm_periodo] = useState(-1); 
    const [trm_ano_inicio, setTrm_ano_inicio] = useState(''); 
    const [trm_semestre_inicio, setTrm_semestre_inicio] = useState(-1); 
    const [trm_situacao_curso, set_Trm_situacao_curso] = useState(0);     
    const [trm_cod_cad_aln, setTrm_cod_cad_aln] = useState('Gerado após o cadastro da turma!'); 

    async function handleCreate(){ 
        const nome_curso = idCur[1];
        const dados = { 
            cur_id, 
            trm_nome, 
            trm_turno, 
            trm_periodo, 
            trm_ano_inicio, 
            trm_semestre_inicio, 
            trm_situacao_curso, 
            nome_curso
        };
        
        try {
            const response = await api.post('/turmas', dados); 
            alert("Id da turma: " + response.data);            
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }        
    } 

    function handleValida() { 
        let irregulares = '';
        if (trm_nome === '') irregulares += '- nome da turma' + '\n';   
        if (trm_turno == -1) irregulares += '- selecione o turno da turma' + '\n';  
        if (trm_periodo == -1) irregulares += '- selecione o período da turma' + '\n';    
        if (trm_ano_inicio === '') irregulares += '- digite o ano de início da turma' + '\n'; 
        if (trm_ano_inicio.length !== 4) irregulares += '- o valor do ano deve conter 4 dígitos' + '\n'; 
        if (trm_semestre_inicio == -1) irregulares += '- selecione o semestre em que a turma iniciou' + '\n'; 
                    
        if (irregulares === '') {
            return true;
        } else {
            alert("Campos a serem preenchidos: " + '\n' + irregulares);
            return false;
        }
    }

    function cadastradaTurma() {
        if (handleValida() === true) { 
            handleCreate();
            if (statusVis === 1) {
                renderiza(4);    
            } else {
                renderiza(3);
            }  
        }  
    }

    return (
        <div> 
            <h1>Cadastro de turmas</h1>
            <h2 style={{marginBottom: '2rem'}}>{idInst[1]} - {idCur[1]}</h2>
            <label>Nome da turma</label>
            <input type="text" maxLength={60} name='nomeTurma' id='nomeTurma' value={trm_nome} onChange={e => setTrm_nome(e.target.value)} /> 
            <div className={classesApp.colunas}>
                <label className={classesApp.lrg25}>Turno</label>                    
                <label className={classesApp.lrg25} style={{marginLeft: '1rem'}}>Período</label>
                <label className={classesApp.lrg25} style={{marginLeft: '1rem'}}>Ano Início</label>
                <label className={classesApp.lrg25} style={{marginLeft: '1rem'}}>Sem. Início</label>
            </div> 
            <div className={classesApp.colunas}>
                <select name="turnoTrm" id="turnoTrm" className={classesApp.lrg25} value={trm_turno} onChange={e => setTrm_turno(e.target.value)}>
                    <option value="-1">-</option>
                    <option value="0">Matutino</option>
                    <option value="1">Vespertino</option>
                    <option value="2">Noturno</option>
                    <option value="3">Integral</option>
                </select>  
                <select name="perTrm" id="perTrm" className={classesApp.lrg25} style={{marginLeft: '1rem'}} value={trm_periodo} onChange={e => setTrm_periodo(e.target.value)}>
                    <option value="-1">-</option>
                    <option value="0">Semestral</option>
                    <option value="1">Anual</option>
                </select>  
                <input type="number" min={2015} max={2099} className={classesApp.lrg25} style={{marginLeft: '1rem'}} value={trm_ano_inicio} onChange={e => setTrm_ano_inicio(e.target.value)}/>
                <select name="semCur" id="semCur" className={classesApp.lrg25} style={{marginLeft: '1rem'}} value={trm_semestre_inicio} onChange={e => setTrm_semestre_inicio(e.target.value)}>
                    <option value="-1">-</option>
                    <option value="1">1º</option>
                    <option value="2">2º</option>                    
                </select>
            </div>                                                       
            <div className={classesApp.colunas}>
                <label className={classesApp.lrg25}>Situação</label>                    
                <label className={classesApp.lrg75} style={{marginLeft: '1rem'}}>Código para cadastro dos alunos</label>
            </div>
            <div className={classesApp.colunas}>
                <select name="sitCur" id="sitCur" className={classesApp.lrg25} value={trm_situacao_curso} onChange={e => set_Trm_situacao_curso(e.target.value)}>
                    <option value="-1">-</option>
                    <option value="0">Em andamento</option>
                    <option value="1">Concluído</option>                    
                </select>
                <div  className={classesApp.lrg75} style={{marginLeft: '1rem', marginBottom: '0rem'}}>
                    <input type="text" maxLength={12} name='codCadAlnnTurma' id='codCadAlnnTurma' disabled value={trm_cod_cad_aln} />
                    <MdContentCopy className={classesApp.iconeForm} size={'3rem'} />
                </div> 
            </div>

            <div className={classesApp.containerBtnAcoes}> 
                <label className={classesApp.btnAcoes} onClick={() => renderiza(3)}>Cancelar</label>
                <label className={classesApp.btnAcoes} onClick={() => cadastradaTurma()} >Registrar nova turma</label>
            </div>                                                   
        </div>                          
    );
}
  
export default CadTurmas;