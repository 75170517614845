import React, {  useState, useEffect } from 'react'; 

import classes from './homeTestes.module.css'
import classesApp from '../../App.module.css'; 
import classesAln from '../alunos/alnTeste/alnTeste.module.css';

import { MdVisibility } from "react-icons/md";

import api from '../../../services/api';

// mostra esse componente somente quando finalizar um cadastro de teste ou ao visualizar um teste pronto
// PROFESSOR - renderiza, idTes*
// ALUNO - alnVisualiza, renderizaAln, resolvendo, renderizaTeste, idTes*
// * ver e passar o mesmo parâmetro de telas diferentes funciona
function TestesVisualiza( { renderiza, idTes, alnVisualiza, renderizaAln, resolvendo, renderizaTeste, aln_tt_id, usuario, teste } ) { 
    //console.log(aln_tt_id);
    // - carregar o id do teste *professor e aluno 
    // - carregar as respostas *aluno - o array vazio com as respostas será gerado na etapa de confirmação de dados do aluno 
    // - professor visualiza lacunas em branco sem opção de responder 
    // - professor clica em visão e vê as respostas corretas
    // - alunos visualiza lacunas em branco com evento para registrar respostas - dar update a cada minuto para garantir armazenamento de respostas em caso d queda de conexão (implementar depois ver como fica apenas com state!) 
    // - visão só será habilitado para o aluno se a forma de apresentação de resposta for: 0 – Nada, 1 – Apenas pontuação, 2 – Somente respostas do aluno sem correção, 3 – Somente respostas do aluno apontando os erros e acertos, 4 – Mostrar tudo --- (carregar diferentes componentes para op 0 e 1, tratar a apresentação nas opções 2, 3 e 4)
    const STORAGE_KEY_ALN = 'onlineClozeAluno'; // define nome constante armazenar respostas
    const estaRepondendo = !!localStorage.getItem(STORAGE_KEY_ALN); // retorna v ou f
    const gravaRespNav = resp => localStorage.setItem(STORAGE_KEY_ALN, resp); // função definir valor no navegador
    const dadosRespAln = JSON.parse(localStorage.getItem(STORAGE_KEY_ALN)); // recupera dados navegador
    const limpaDadosResp = () => localStorage.removeItem(STORAGE_KEY_ALN); 
    //gravaRespNav(JSON.stringify(objLogado)); 

    const [etapaRen, setEtapaRen] = useState(0); // etapa para controle de renderização
    const [texto, setTexto] = useState(''); // texto do teste 
    const [titulo, setTitulo] = useState(''); // título do texto
    const [referencia, setReferencia] = useState(''); // referência do texto, só é apresentado para o aluno após a correção
    const [txtPrep, setTxtPrep] = useState([]); // texto organizado para gerar renderização
    const [arrPlv, setArrPlv] = useState([]); // tipo de rederização por palavra 
    const [apresentaAtv, setApresentaAtv] = useState([]); 
    const [mResp, setMresp] = useState(false); 
    // tamanhos para o input com a quantidade de caracteres representada no array
    const tam30carc = [0.00, 2.20, 3.80, 5.20, 6.50, 8.20, 9.40, 10.80, 12.40, 13.70, 15.10, 16.50, 17.70, 18.80, 20.50, 21.50, 22.90, 24.10, 25.50, 27.00, 28.30, 29.80, 31.00, 32.80, 34.00, 35.20, 36.70, 37.90, 39.30, 40.70, 42.20]; 
    const [respostas, setRespostas] = useState([]); 
    const [arrResp, setArrResp] = useState([]); 
    const [nLac, setNLac] = useState(0); 
    const [arrIdRespClz, setArrIdRespClz] = useState([]); 
    const [corr, setCorr] = useState([]); 
    const [respCorr, setRespCorr] = useState([]); // carrega acertos e erros como 1 e 0 para pintar borda na correção
    const [conhece, setConhece] = useState(-1);
    const [plvDesc, setPlvDesc] = useState(-1);
    const [disp, setDisp] = useState(-1); 
    const [txtMostraResp, setTxtMostraResp] = useState('Ver respostas corretas'); 

    const [temp, setTemp] = useState(false); // tempo de espera para finalizar

    useEffect(() => { 
        if (etapaRen === 0) idTxt(idTes); 
        if (etapaRen === 1) preparaTxt(texto); 
        if (etapaRen === 2) carregaRespAln(); 
        if (etapaRen === 3) { 
            somaLac();
            apresentaAtividade(); 
        }
        // ou teste se estiver fazendo carregar respostas aluno com aln_tt_id
        //if (etapaRen === 4) console.log(nLac);
        // identificar quando o estado for visualizando e as permissões - (permissão de só ver pontuação só é apresentada sem essa tela, direcionar de homeAln - também chamar a função de carregar resp aln ao apresentar respostas) 
        if (resolvendo) {
            setTimeout(() => { setTemp(true); }, 420000); // 1000 = 1s   7 min         
        } else {
            setTemp(true); 
        }  
                            
    }, [etapaRen]);

    // botão sair - funções para aluno ou professor
    async function handleAcaoBtnSair() {
        // quando o aluno está visualizando o resultado
        if (alnVisualiza) {
            renderizaAln(0); 
            // testar a visualização e implementar apresentação do resultado, acertos e cores na correção
        }

        // quando o aluno está resolvendo as questões
        if (!alnVisualiza) {             
            if (resolvendo) { 
                if (valida()) { 
                    somaLac();
                    confRespUsu();
                    const dadosResp = { 
                        arrIdRespClz, // id para gravar resp
                        arrResp, // respostas do aluno 
                        corr, // correção verifica no react
                        aln_tt_id, 
                        nLac
                    } 
    
                    try {
                        const registraResp = await api.patch('/respcloze', dadosResp);
                        const finalizaTeste = await api.patch('/alnteste/finaliza', {aln_tt_id, aln_tt_conhece_txt: conhece, aln_tt_plv_desc: plvDesc, aln_tt_dispositivo: disp});
                        const responseMdEtpPes = await api.patch('/alnteste/mdetapa', {aln_tt_etapa: 4, aln_tt_id}); 
                        limpaDadosResp();
                        renderizaTeste(4); // aluno resolvendo teste -- direciona para pontuação 
                    } catch (error) {
                        if (error.response) {
                            alert(error.response.data.message);
                        } else {
                            alert(error);
                        }
                    } 
                }                                            
            } else {
                // professor visualizando teste
                renderiza(0);
            }            
        }
    }

    function valida() {
        let mensagem = ''; 
        if (conhece === -1) mensagem += '- Responder se conhecia ou não o texto\n'; 
        if (plvDesc === -1) mensagem += '- Responder se havia ou não palvras desconhecidas no texto\n';
        if (disp === -1) mensagem += '- Responder em que tipo de dispositivo o teste foi realizado'; 

        if (mensagem === '') {
            return true;
        } else {
            alert('Antes de finalizar o teste é necessário verificar o(s) item(s) abaixo:\n' + mensagem); 
            return false;
        }
    }

    // carregar teste com lacunas sem evento (professor) - para aluno com onChange para atualizar o state
    // pel id do teste identificar o texto, pegar o texto, aplicar a primeira função, depois aplicar o gerar atividade
    
    async function idTxt(id) { 
        // carrega array de posição dos componentes
        try { 
            const response = await api.get('/txtdados/' + id); 
            const idPlv = response.data; 
            setArrPlv(idPlv);
        } catch (error) {
            alert('Erro ao listar dados: ' + error);
        }

        // carrega texto
        try { 
            const response = await api.get('/testes/atv/' + id); 
            const txt = response.data[0].txt_completo; 
            const tt = response.data[0].txt_titulo; 
            const ref = response.data[0].txt_ref; 
            setTexto(txt);
            setTitulo(tt);
            setReferencia(ref);
        } catch (error) {
            alert('Erro ao listar dados: ' + error);
        }
        setEtapaRen(1);
    }

    // usada para aluno e professor
    function preparaTxt(txt) {
        let tamanho = txt.length; // pega o tamanho do parâmtro passado que tem o state txtInserido
        let tempArray = []; // criado para troca de valores com arrTxt onde passa as palavras separadas
        let ehPlv = true; // verifica se vai formar uma palavra
        let anterior = 'esp'; // armazena o tipo da posição anterior do array
        let palavra = ''; // permite formar uma palavra para gravar em uma possição do array
        let tipo = 'ltr'; //esp, ltr, pnt - guarda o tipo de valor de uma posição do array com o texto original
        let ctrlPt = 'false'; // usada para distinguir quando tem "..."
        const regex = /[a-zA-Zà-úÀ-Ú0-9]/; // usado para armazenar valores condiderados pontuação (n consegui trat "..."" aqui)
        
        for (let n = 0; n < tamanho; n++) {
            // percorre todo o texto
            ehPlv = false; // identificador para verificar se uma palavra será composta
            /* 
            forçar um \n no final e sempre verificar se o próximos 2 formam um \n, caso não tenha pontuação inserir
            por enquanto todo texto deve ter um ponto finalizando o último paragráfo
            */
            if (!regex.test(txt[n]) === true) {
                // diferencia pontos do regex e caracteres comuns
                tipo = 'pnt'; // se for um sinal
            } else {
                tipo = 'ltr'; // se for uma letra
                ehPlv = true; // se for letra pode compor uma palavra
            }

            if (anterior !== 'ltr' && txt[n] === '-') {
                // verifica se o "-" está no início de uma palavra
                tipo = 'pnt'; // "-" para inicio de diálogo é considerado pontuação
                ehPlv = false; // dessa forma palavra é falso
            }
            
            if (txt[n] === ' ') {
                tipo = 'esp'; // verifica se é um espaço vazio para ignorar posteriormente
            }

            if (ehPlv === true && tipo === 'ltr') {
                // quebra de linha é representada por 2 caracteres
                palavra += txt[n]; // inicialmente é tratado como palavra
                if (palavra === '\n') {
                    // se for identificada uma quebra ela será postada como pontuação
                    tempArray.push(palavra);
                    palavra = ''; // reseta a variável de composição de palavras
                    anterior = 'esp'; // a quebra é definida como espaço
                }
            }

            if (anterior === 'ltr' && tipo !== 'ltr') {
                // se o caractere depois de uma letra não for outra, definimos uma palavra a ser gravada
                tempArray.push(palavra);
                palavra = '';
            }

            if (tipo === 'pnt') {
                // tratamento para pontuação e evita problemas com "..."
                if (txt[n] === '.') {
                    ctrlPt = txt[n - 1] === '.' || txt[n + 1] === '.';
                    if (ctrlPt === false) {
                        tempArray.push(txt[n]);
                    }
                } else {
                    tempArray.push(txt[n]);
                }
            }

            if (txt[n - 2] === '.' && txt[n - 1] === '.' && txt[n] === '.') {
                // insere pontuação de "..."
                tempArray.push('...');
            }

            anterior = tipo; // passa um valor para controle do caractere anterior
        }
        setTxtPrep(tempArray); // finaliza a função passando os valores separados para o state como um array 
        if (etapaRen === 1 && resolvendo || alnVisualiza) {
            setEtapaRen(2);
        }  
        if (etapaRen === 1 && resolvendo === undefined && alnVisualiza === undefined) {
            setEtapaRen(3);
        }      
    }

    // mostra / gera atividade
    function geraAtividade(chave, valor, tipo) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp
        // lacf (lacuna no final), lac (lacuna) 
        //console.log(plvExc); - usado para gerar valores da tabela 
        //console.log(respostas[chave]); 

        if (tipo === 'br') {
            return <br key={chave} />;
        }

        if (tipo === 'pln') {
            return (
                <label
                    key={chave}
                    value={valor}
                    className={classesApp.txt_lbl_n}
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plnf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    className={classesApp.txt_lbl_n}
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'pls') {
            return (
                <label
                    key={chave}
                    value={valor}
                    className={classesApp.txt_lbl_n} 
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plsf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    className={classesApp.txt_lbl_n} 
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sat') {
            return (
                <label 
                    key={chave} 
                    value={valor} 
                    className={classesApp.txt_lbl_n}
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sdp') {
            return (
                <label 
                    key={chave} 
                    value={valor} 
                    className={classesApp.txt_lbl_n}
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'lacf') {
            return (
                <span key={chave + '_' + valor + 'spn'} > 
                    {
                        resolvendo ? 
                        <input 
                            key={chave} 
                            value={arrResp[chave]}
                            type="text" 
                            spellCheck="false" 
                            autoComplete="off" 
                            style={{ 
                                width: valor.length === 0 ? '0rem' + 'rem' : tam30carc[valor.length] + 'rem', 
                                display: valor.length === 0 ? 'none' : 'inline', 
                                //width: valor.length * 15, // fui testando multiplicadores para encontrar um tamanho que se ajustasse a  fonte                          
                            }} 
                            
                            id={chave}                     
                            onChange={(e) => gravaResp(e)}                     
                        /> : 
                        <input 
                            key={chave} 
                            value={mResp ? valor : arrResp[chave]?arrResp[chave]:''}
                            type="text" 
                            readOnly
                            style={{ 
                                width: valor.length === 0 ? '0rem' + 'rem' : tam30carc[valor.length] + 'rem', 
                                display: valor.length === 0 ? 'none' : 'inline', borderColor: usuario || teste.tes_apres_resp < 3 ? 'black' : respCorr[chave] ? 'chartreuse' : 'coral'
                                //width: valor.length * 15, // fui testando multiplicadores para encontrar um tamanho que se ajustasse a  fonte                          
                            }} 
                            
                            id={chave}                          
                        />
                    }
                </span>
                
            );
        }
        
        if (tipo === 'lac') { 
            return (
                /* 
                    coloquei lacunas de meio de paragráfo gerando um input e um label com espaço em um span para garantir o 
                    espaço entre o input e a próxima palavra, no anterior não precisa, porque o próximo caractere será uma pontuação
                */
                <span key={chave + '_' + valor + 'spn'} >
                    {
                        resolvendo ? 
                            <input 
                                key={chave} 
                                value={arrResp[chave]}
                                type="text" 
                                spellCheck="false" 
                                autoComplete="off" 
                                style={{ 
                                    width: valor.length === 0 ? '0rem' + 'rem' : tam30carc[valor.length] + 'rem', 
                                    display: valor.length === 0 ? 'none' : 'inline',
                                    //width: valor.length * 15,
                                }} 
                                id={chave}
                                onChange={(e) => gravaResp(e)}                         
                            /> 
                        : 
                            <input 
                                key={chave} 
                                value={mResp ? valor : arrResp[chave]?arrResp[chave]:''}
                                type="text" 
                                readOnly
                                style={{ 
                                    width: valor.length === 0 ? '0rem' + 'rem' : tam30carc[valor.length] + 'rem', 
                                    display: valor.length === 0 ? 'none' : 'inline', borderColor: usuario || teste.tes_apres_resp < 3 ? 'black' : respCorr[chave] ? 'chartreuse' : 'coral'
                                    //width: valor.length * 15,
                                }} 
                                id={chave}                       
                            />
                    }
                    <label key={chave + '_' + valor + 'lbl'} className='txt_lbl_espaco'> </label>
                </span>                
            );            
        }
    } 

    function apresentaAtividade() {
        if (arrPlv[0]) {
            let codlbl;
            let arrlab = txtPrep.map((vlr, i) => {
                codlbl = geraAtividade(i, vlr, arrPlv[i].tdd_tipo);
                return codlbl;
            });
    
            setApresentaAtv(arrlab);
            setEtapaRen(4);
        } else {
            setEtapaRen(9);
        }
    }

    function apresentaResp() { 
        if (mResp === false) {
            if (usuario) {
                setTxtMostraResp('Visão do aluno'); 
            } else {
                setTxtMostraResp('Minhas respostas'); 
            }           
        } else {
            setTxtMostraResp('Ver repostas corretas'); 
        }    
        mResp ? setMresp(false) : setMresp(true);
        setEtapaRen(3);
    } 

    async function carregaRespAln() {
        try {
            let tempArrResp = []; 
            let tempArrIdRespClz = []; 
            let tempArrRespCorr = [];
            const response = await api.get('/respcloze?alntt=' + aln_tt_id); 
            setRespostas(response.data); 
            response.data.forEach(resp => {
                tempArrResp[resp.rclz_pos_arr] = resp.rclz_resposta; 
                tempArrIdRespClz[resp.rclz_pos_arr] = resp.rclz_id; 
                tempArrRespCorr[resp.rclz_pos_arr] = resp.rclz_acertou; 
            }); 
            setArrIdRespClz(tempArrIdRespClz); 
            if (estaRepondendo) {                
                setArrResp(dadosRespAln);                 
            } else {
                setArrResp(tempArrResp); 
                setRespCorr(tempArrRespCorr);
                if (alnVisualiza === false) {
                    gravaRespNav(JSON.stringify(tempArrResp)); //---------------------------------
                }
                
            }            
            setEtapaRen(3);
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            } 
        }
    }

    // armazena respostas
    function gravaResp(e) {
        let tempArrResp = arrResp; 
        tempArrResp[e.target.id] = e.target.value;
        setArrResp(tempArrResp); 
        limpaDadosResp();
        gravaRespNav(JSON.stringify(tempArrResp));
        setEtapaRen(3); 
        //console.log(arrResp);
        // gravar resposta nLac / 5 grava 4 vezes
        //console.log(e.target.id);
    } 

    function somaLac() { 
        let cont = 0;
        arrPlv.forEach(item => {
            if (item.tdd_tipo === 'lac' || item.tdd_tipo === 'lacf') {
                cont++;
            }
        });
        setNLac(cont);
    }

    function confRespUsu() { 
        txtPrep.forEach(mostraRespUsu); 
        setEtapaRen(3);
    }

    function mostraRespUsu(valor, chave) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp
        // lacf (lacuna no final), lac (lacuna)  
        let tempCorr = corr;
        if (valor === arrResp[chave]) {
            tempCorr[chave] = 1; 
        } else {
            tempCorr[chave] = 0;
        }
        setCorr(tempCorr); 
        // contar lacunas em branco
    }

    if (etapaRen === 9) { 
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
            O teste não foi criado corretamente!
            </div>
        );        
    } else {
        return (
            <div className={classesApp.centraliza}>   
                {!resolvendo && (
                    <>                        
                        {(usuario || teste.tes_apres_resp > 3) && (
                            <div className={classesApp.containerFixo} > 
                                <div style={{backgroundColor: '#fafafa', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0.4rem solid #673ab7', padding: '0rem 1rem'}}>
                                    <label>{txtMostraResp}</label>
                                    <MdVisibility size={'2.5rem'} className={classesApp.iconeForm} onClick={() => apresentaResp()} />
                                </div>                                
                            </div> 
                        ) }   
                    </>                
                )}          
                <h1>{titulo}</h1>
                
                {!resolvendo && (
                    <>
                        <div style={{display: 'flex', justifyContent: 'right', width: '100%', wordWrap: 'break-word'}}>
                            <label style={{border: '0.1rem solid black', padding: '0.5rem', marginTop: '1rem', maxWidth: '50%', fontWeight: '300', fontSize: '1.4rem'}}>{referencia}</label>
                        </div> 
                        {
                            usuario && (
                                <div style={{width: '100%', display: 'flex', justifyContent:'right', marginTop: '0.5rem'}}>
                                    <label style={{marginBottom: '0rem'}}>Pontuação máxima: {nLac}</label>
                                </div>  
                            )
                        }  
                    </>                
                )}                        

                <div className={classesApp.textoTeste}>
                    {apresentaAtv}
                </div>
                {resolvendo && (
                    <div style={{width: '100%', padding: '0rem 1.5rem', borderTop: 'solid black', borderBottom: 'solid black', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'left'}}>
                        <label style={{fontWeight: 'normal', fontSize: '2rem', marginRight: '1rem'}}>Você já conhecia este texto?</label>
                        <div className={classesAln.radioDefineTurma} style={{width: '100%', justifyContent: 'left'}}>   
                            <input type="radio" id="conheces" name="conhece" checked={conhece === 1} value="1" onClick={() => setConhece(1)} />
                            <label htmlFor="conheces" style={{fontWeight: 'normal', fontSize: '2rem'}}>Sim</label> 
                                                
                            <input type="radio" id="conhecen" name="conhece" checked={conhece === 0} value="0" onClick={() => setConhece(0)} />
                            <label htmlFor="conhecen" style={{fontWeight: 'normal', fontSize: '2rem'}}>Não</label>
                        </div>
                        <hr style={{border: '0.01rem solid black', width: '100%'}} />
                        <label style={{fontWeight: 'normal', fontSize: '2rem', marginRight: '1rem'}}>Existiam palavras desconhecidas neste texto?</label>
                        <div className={classesAln.radioDefineTurma} style={{width: '100%', justifyContent: 'left'}}> 
                            <input type="radio" id="plvdess" name="plvdes" checked={plvDesc === 1} value="1" onClick={() => setPlvDesc(1)} />
                            <label htmlFor="plvdess" style={{fontWeight: 'normal', fontSize: '2rem'}}>Sim</label>                       
                            <input type="radio" id="plvdesn" name="plvdes" checked={plvDesc === 0} value="0" onClick={() => setPlvDesc(0)} />
                            <label htmlFor="plvdesn" style={{fontWeight: 'normal', fontSize: '2rem'}}>Não</label>
                        </div> 
                        <hr style={{border: '0.01rem solid black', width: '100%'}} /> 
                        <label style={{fontWeight: 'normal', fontSize: '2rem', marginRight: '1rem'}}>Em que tipo de dispositivo o teste foi realizado?</label> 
                        <div className={classesAln.radioDefineTurma} style={{width: '100%', justifyContent: 'left'}}> 
                            <input type="radio" id="dispc" name="disp" checked={disp === 0} value="Computador" onClick={() => setDisp(0)} />
                            <label htmlFor="dispc" style={{fontWeight: 'normal', fontSize: '2rem'}}>Computador</label>
                            <input type="radio" id="disps" name="disp" checked={disp === 1} value="Smartphone" onClick={() => setDisp(1)} />
                            <label htmlFor="disps" style={{fontWeight: 'normal', fontSize: '2rem'}}>Smartphone</label>
                            <input type="radio" id="dispt" name="disp" checked={disp === 2} value="Tablet" onClick={() => setDisp(2)} />
                            <label htmlFor="dispt" style={{fontWeight: 'normal', fontSize: '2rem'}}>Tablet</label>
                        </div>
                    </div>
                )}
                {temp === true && (
                    <div className={classesApp.containerBtnAcoes}>
                        <label className={classesApp.btnAcoes} onClick={() => handleAcaoBtnSair()}>{resolvendo ? 'Finalizar teste' : 'Fechar'}</label>
                    </div> 
                )}                     
            </div>        
        );
    }
    
}
  
export default TestesVisualiza;