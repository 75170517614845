import React from 'react';

import classes from './headerHome.module.css';

import logo from '../../../assets/logo_bco.png';

import MobileNavigation from './mobileNavigation'; 
import Navigation from './navigation';

function Geral() {

    return (
        <div className={classes.containercb}>
            <div className={classes.menu}>
                <div className={classes.titulo}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
                </div>
                <div className={classes.menuSection}>             
                    <Navigation />
                    <MobileNavigation />                    
                </div>                               
            </div>                              
        </div>      
    );
}
  
export default Geral;