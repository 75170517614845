import React from 'react';

import classesApp from '../../App.module.css';

import { MdSearch } from "react-icons/md";

function TextosPesquisa( { renderiza, apresentacao, vlrPesq, txtPesquisa, listaTextos } ) {

    function handleAcaoBtn() {
        if (apresentacao === 3) {
            renderiza(0); // editando, volta para pesquisa
        } else {
            renderiza(3); // adicionar novo
        }
    }

    return (
        <div className={classesApp.containerMenuPg}>
            {/* Mudar para cancelar quando estiver adicionando um texto */}
            <label className={classesApp.botaoMenu} onClick={() => handleAcaoBtn()}>{apresentacao === 3 ? 'Cancelar' : 'Novo texto'}</label> 
            <div className={classesApp.containerPesquisa}>                
                <label style={{marginRight:'1rem'}}>Filtrar por título</label>                
                <input type="text" maxLength={50} onChange={(e) => txtPesquisa(e.target.value)} value={vlrPesq} /> 
                <div>
                    <MdSearch size={'3rem'} className={classesApp.iconeMenuPg} onClick={() => {
                        listaTextos();
                        renderiza(0);
                    }} />
                </div>                
            </div>
        </div>       
    );
}
  
export default TextosPesquisa;