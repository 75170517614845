import React, { useState, useEffect } from 'react'; 
import { useHistory } from "react-router-dom";

import Email from './login/email';
import Senha from './login/senha'; 
import CompletaCad from './finCadAln'; 
import Politicas from './login/politicasPriv'; 
import Termos from './login/termosUso';

import api from '../../../services/api'; 
import { login as signin } from '../../../services/auth';

function Login() {

    const [etapaLog, setEtapaLog] = useState(0); 
    const [tela, setTela] = useState(0);
    const [mensagem, setMensagem] = useState(''); 
    const [tipo, setTipo] = useState(''); // usuario ou aluno
    // 0-email, 1-senha, 2-confEmail, 3-alterSenha
    const [usuario, setUsuario] = useState('');   
    const [nome, setNome] = useState(''); 
    const [id, setId] = useState(0);
    const [completar, setCompletar] = useState(true); // carrega tela
    const [politicas, setPoliticas] = useState(false); // carrega tela
    const [termos, setTermos] = useState(false); // carrega tela     
    const [objLogado, setObjLogado] = useState({});
    let history = useHistory(); // history.push("/homealn"); 

    useEffect(() => { 
        if (tela === 1) setEtapaLog(1); 
        if (tela === 2 || tela === 3) { 
            if (completar == true) {
                setEtapaLog(2); 
            } else if (politicas == false) {
                setEtapaLog(3); 
            } else if (termos == false) {
                setEtapaLog(4);
            } else {
                if (completar == false && politicas == true && termos == true) {
                    // se todas estapas estão ok, passa os valores para o navegador, se não não passa, essa etapa era feita após digitar a senha, porém quando o usuário clicava em atualizar nos termos ia direto para o home
                    signin(JSON.stringify(objLogado)); // ver como passar vários valores em vídeo do dev samurai 
                }
                window.location.reload(true);                
                // reload já verifica qual usuário está logada e redireciona
                /*
                usado antes do controle de rotas privadas
                if (tipo === 'usuario') {
                    history.push("/homeprof"); 
                } 
                if (tipo === 'aluno') {
                    history.push("/homealn");
                }*/
            }
        }         
    }, [tela]); 

    function apresMensagem(mens) {
        setMensagem(mens);
    }

    async function identificaUsu(email) { 
        const dados = {
            email
        }

        try {
            const response = await api.post('/session/email', dados); 
            setUsuario(email); 
            setTipo(response.data.tipo); 
            setNome(response.data.nome) 
            setMensagem(''); 
            setTela(1); 
        } catch (error) { 
            setUsuario('');
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }  
        }        
    } 

    async function login(email, senha) { 
        const dados = {
            email, 
            senha, 
            tipo
        }

        try {
            const response = await api.post('/session/email/senha', dados); 
            //const objLogado = { 
            setObjLogado({
                "id": response.data.idLog, 
                "nome": response.data.nomeLog, 
                "acesso": response.data.nivelAcesso, 
                tipo,
                "token": 'ABCD'
            });
            // signin(JSON.stringify(objLogado)); // ver como passar vários valores em vídeo do dev samurai    
            setId(response.data.idLog); 
            setCompletar(response.data.compCad); 
            setPoliticas(response.data.pol); 
            setTermos(response.data.ter); 
            setTela(2); // direcionar de acordo com a situação
        } catch (error) { 
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }  
            //setTela(0);
            //setEtapaLog(0);
        }        
    } 

    // 0 - verificicar senha, 1 - e-mail, não identificado, 2 - completar cad, 3 - pol, 4 - termos, 5 - ok
    async function confirmaTermos() { 
        const dados = {
            id, 
            tipo
        };
                 
        try {
            const response = await api.patch('/session/email/senha/term', dados); 
            setTermos(response.data.confirma); 
            if (tela === 2){
                setTela(3); 
            } else if (tela === 3) {
                setTela(2);
            }
        } catch (error) { 
            setMensagem(error.response.data.message); 
        }
    }

    async function confirmaPoliticas() { 
        const dados = {
            id, 
            tipo
        };
                 
        try {
            const response = await api.patch('/session/email/senha/pol', dados); 
            setPoliticas(response.data.confirma);             
            if (tela === 2){
                setTela(3); 
            } else if (tela === 3) {
                setTela(2);
            }
        } catch (error) { 
            setMensagem(error.response.data.message); 
        }
    }  
    
    async function finalCadAln(sexo, nascimento) { 
        const aln_sexo = sexo; 
        const aln_dt_nasc = nascimento; 
        const dados = {
            id, 
            aln_sexo, 
            aln_dt_nasc
        };
                 
        try {
            const response = await api.patch('/alunos/finalizacad/', dados); 
            setCompletar(response.data.completar);             
            if (tela === 2){
                setTela(3); 
            } else if (tela === 3) {
                setTela(2);
            }
        } catch (error) { 
            setMensagem(error.response.data.message); 
        }
    }

    switch (etapaLog) { 
        // atualizar e adicionar acesso a termos, politicas, fin, cad aln e mensagem email não conf. após inserir senha
        case 0:
            return <Email identificaUsu={identificaUsu} mensagem={mensagem} apresMensagem={apresMensagem} />             
        case 1:
            return <Senha usuario={usuario} login={login} mensagem={mensagem} tipo={tipo} nome={nome} /> 
        case 2:
            return <CompletaCad finalCadAln={finalCadAln} /> 
        case 3: 
            return <Politicas confirmaPoliticas={confirmaPoliticas} /> 
        case 4: 
            return <Termos confirmaTermos={confirmaTermos} />   
        default:
            return <Email identificaUsu={identificaUsu} mensagem={mensagem} apresMensagem={apresMensagem} />;
    }           
}
  
export default Login;