import React, { useState } from 'react'; 
import { Link } from 'react-router-dom'; 

import classes from './publicas.module.css'; 
import classesApp from '../../App.module.css';

import logo from '../../../assets/logo_rxo.png'; 

import pad from '../../../services/pad'; 

function Cadastro( { finalCadAln } ) { 

    const [mensagem, setMensagem] = useState('');
    const [sexo, setSexo] = useState(-1); // não definido 
    const [nascimento, setNascimento] = useState('');     
    // para data de nascimento - passar string do input
     

    function valida() {         
        
        let tempMens = '';
        if (sexo === -1) tempMens += 'Selecione o sexo. '; 
        if (nascimento === '') tempMens += 'Defina sua data de nascimento.'; 
        
        const dataHj = new Date();
        const data = new Date(nascimento); 
        const dtCtr = data.getFullYear(); 
        if ((dataHj.getFullYear() -  dtCtr) > 100 ) tempMens += 'Defina sua data de nascimento corretamente.';
            
        if (tempMens === '') { 
            const data = new Date(nascimento); 
            const dtCtr = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate() + 1);
            finalCadAln(sexo, dtCtr);
        } else { 
            setMensagem(tempMens);
        }
    }

    return(
        <div className={classes.centralizaCad}>
            <div className={classes.tituloTerPol} style={{margin: '2rem'}}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
            </div>
            <div className={classes.cadastro}>
                <h1 style={{fontSize: '3.2rem', marginBottom: '3rem'}}>Finalizando o cadastro...</h1>
                <label>Sexo</label>
                <div className={classes.containerSexo}>
                    <div className={classes.containerOpSexo}>
                        <input type="radio" name="rdSexo" id="rdFeminino" checked={sexo === 0} value="0" onClick={() => setSexo(0)} />
                        <label htmlFor='rdFeminino'>Feminino</label>
                    </div>
                    <div className={classes.containerOpSexo}>
                        <input type="radio" name="rdSexo" id="rdMasculino" checked={sexo === 1} value="1" onClick={() => setSexo(1)} />
                        <label htmlFor='rdMasculino'>Masculino</label>                        
                    </div>                                    
                </div>            
                <label style={{marginTop: '2rem'}}>Data de nascimento</label>
                <input type="date" value={nascimento} onChange={e => setNascimento(e.target.value)} />                          
                <div className={classes.btnCadastro}>     
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
                    <label 
                        className={classesApp.btnAcaoSecundariaEscuro} 
                        style={{margin: '2rem', padding: '1rem 10rem'}}
                        onClick={() => valida()}                             
                    >Finalizar</label>
                    <label className={classes.avisoErro} style={{fontSize: '1.8rem', textAlign: 'center'}}>{mensagem}</label>
                </div>                                   
                </div>                    
            </div>                
        </div>
    );
}

export default Cadastro;