import React, { useState, useEffect } from 'react'; 
import { useHistory, useParams } from 'react-router-dom'; 

import classes from '../publicas.module.css'; 
import classesApp from '../../../App.module.css';

import logo from '../../../../assets/logo_rxo.png'; 

import api from '../../../../services/api'; 

function PopOk( { mensagem } ) { 
    let history = useHistory();
    return (
        <div className={classesApp.formPopup}>               
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}> 
                <h1 style={{color: '#fafafa', margin: '2rem', textAlign: 'center'}}>{mensagem}</h1> 
                <label onClick={() => history.push("/login")} style={{width: '60%', textAlign: 'center'}}>Ok</label>
            </div>                        
        </div>
    )    
}

function RecSenha() {

    const { tipo, id, token } = useParams(); 
    const [nomeUsu, setNomeUsu] = useState(''); // pegar primeiro nome 
    const [mensagem, setMensagem] = useState('');
    const [sucesso, setSucesso] = useState(false); 

    const [senhaDg1, setSenhaDg1] = useState(''); 
    const [senhaDg2, setSenhaDg2] = useState(''); 

    useEffect(() => { 
        verificaToken();
    }, []); 

    async function verificaToken() {
        try {
            if (tipo === 'usuario') {
                const response = await api.get('/usuario/verifica/' + id + '/' + token); 
                setNomeUsu(response.data.nome);         
            } else if (tipo === 'aluno') {
                const response = await api.get('/alunos/verifica/' + id + '/' + token);
                setNomeUsu(response.data.nome);         
            } else {
                setMensagem('Link de ativação inválido!');
            }                 
        } catch (error) { 
            setMensagem(error.response.data.message);
        }
    } 

    async function altSenhaUsu(usu_id) { 
        let usu_chave_acesso = senhaDg1;
        const dados = {
            usu_id, 
            usu_chave_acesso
        };
                 
        try {
            const response = await api.patch('/usuarios/mudasenha', dados); 
            setMensagem(response.data.message); 
            setSucesso(true);
        } catch (error) { 
            setMensagem(error.response.data.message);
        }
    } 

    async function altSenhaAln(aln_id) { 
        let aln_chave_acesso = senhaDg1; 
        const dados = {
            aln_id, 
            aln_chave_acesso
        };
                 
        try {
            const response = await api.patch('/alunos/mudasenha', dados); 
            setMensagem(response.data.message); 
            setSucesso(true);
        } catch (error) { 
            setMensagem(error.response.data.message);
        }
    } 

    function mudaSenha() {
        if (!(senhaDg1 === '' && senhaDg2 === '')) {
            if (senhaDg1 !== senhaDg2) {
                setMensagem('O valor da senha e da confirmação da senha devem coicidir');
            } else {
                if (tipo === 'usuario') {
                    altSenhaUsu(id); 
                } 
                if (tipo === 'aluno') {
                    altSenhaAln(id); 
                }
            }
        } else {
            setMensagem('O valor da senha e da confirmação da senha devem coicidir');
        }
    }

    return(
        <div>
            {
                sucesso === true && ( 
                    <PopOk mensagem={mensagem} />
                )
            }

            <div  className={classes.centralizaLog} style={{height: '44rem', marginTop: '-22rem'}}>
                <div className={classes.titulo}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
                </div>
                <label>Olá {nomeUsu}</label>
                <input 
                    type="password" 
                    placeholder='Nova senha' 
                    maxLength={20} 
                    onChange={(e) => setSenhaDg1(e.target.value)}
                />
                <input 
                    type="password" 
                    placeholder='Confirmar nova senha' 
                    maxLength={20} 
                    onChange={(e) => setSenhaDg2(e.target.value)} 
                    style={{margin: '1rem 0rem'}}
                />
                <div className={classesApp.containerBtnPub}>
                    <label 
                        className={classesApp.btnAcaoSecundariaEscuro} 
                        onClick={() => mudaSenha()}
                    >Definir nova senha</label>
                </div>
                {
                    mensagem && (
                        <div className={classes.avisoErro} style={{margin: '0rem'}}>
                            <label style={{textAlign: 'center'}}>{mensagem}</label>
                        </div>
                    )
                }
            </div>
        </div>        
    );
}

export default RecSenha;