const STORAGE_KEY = 'onlineCloze'; 

const isLogged = () => !!localStorage.getItem(STORAGE_KEY); // !! tranforma o retorno de getItem em um booleano, assim se houver algum valor em STORAGE_KEY o retorno é verdadeiro. Esse storageKey deve vir da API e validado pela mesma. 
const login = token => localStorage.setItem(STORAGE_KEY, token); 
const logout = () => localStorage.removeItem(STORAGE_KEY); 

const dados = JSON.parse(localStorage.getItem(STORAGE_KEY)); 
const tipo = dados?.tipo; 


export { isLogged, login, logout, tipo, dados }