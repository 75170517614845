import React, { useState, useEffect } from 'react'; 

import classesApp from '../../../App.module.css'; 

import api from '../../../../services/api';

function RespondeForm( { renderiza, teste, aln_tt_id } ) { 

    const [temp, setTemp] = useState(false); 

    useEffect(() => { 
        setTimeout(() => { setTemp(true); }, 20000); // 1000 = 1s
    }, []); 

    async function finalizaQues() {
        try {
            const responseMdEtpPes = await api.patch('/alnteste/mdetapa', {aln_tt_etapa: 2, aln_tt_id});
            renderiza(2);
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }
        }
        
    }

    return (
        <div>     
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <label style={{fontSize: '2rem', color: '#320b86'}}>{teste.tes_identificacao}</label>
                <label style={{fontWeight: 'normal'}}>Responda o questionário abaixo antes de prosseguir para o teste</label>
            </div>                   
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <iframe 
                    src= {teste.tes_link_form} 
                    title='Questionário pré-teste'
                    frameborder= "0" 
                    marginwidth= "0" 
                    marginheight= "0"  
                    allowfullscreen 
                    webkitallowfullscreen 
                    mozallowfullscreen 
                    msallowfullscreen 
                    style={{border: 'none', width: '96%', height: '44rem', margin: '1rem'}}
                > </iframe>
            </div>    
            {temp === true && (
                <div className={classesApp.containerBtnAcoes}>                    
                    <label className={classesApp.btnAcoes} onClick={() => finalizaQues()}>Próxima etapa</label>
                </div>                 
            )}  
            {temp === false && (
                <div className={classesApp.containerBtnAcoes}>                    
                    <label className={classesApp.btnAcoes} disabled>Próxima etapa</label>
                </div>                 
            )}                                             
        </div>        
    );
}
  
export default RespondeForm;