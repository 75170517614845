import React, { useState } from 'react';

import classesApp from '../../App.module.css'; 

import Header from '../professores/headerHomeProf';
import TermosLista from './termosLista'; 
import TermosCadastro from './termosCadastro';

import api from '../../../services/api'; 
import { dados } from '../../../services/auth'; 

function Termos() {
    const [apresentacao, setApresentacao] = useState(0); 
    const [termos, setTermos] = useState([]); 
    const [termoSel, setTermoSel] = useState([]); 

    function renderiza(op) {
        setApresentacao(op);
    }

    async function listaTermos() { 
        try { 
            const response = await api.get('/termos');
            setTermos(response.data); 
        } catch (error) { 
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }  
            setTermos([]); 
        }   
    }  

    async function exibeTermo(id) {
        try {
            const response = await api.post('/termos/perfil', {term_id: id}); 
            setTermoSel(response.data);
            if (apresentacao === 0 || apresentacao === 2)
                renderiza(1);
            else
                renderiza(2);   
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert('Erro ao acessar o servidor:' + error);
            }  
        }        
    } 

    function handleAcaoBtn() {
        if (apresentacao === 3) {
            renderiza(0);
        } else {
            renderiza(3);
        }
    }
    
    if (dados.acesso === 'dende') {
        return (
            <div className={classesApp.centraliza}>            
                <Header />
                <div className={classesApp.home}>                                  
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <h1>Termos</h1> 
                        <label 
                            className={classesApp.btnAcaoSecundariaClaro} 
                            style={{display: 'flex', padding: '0rem 2rem', alignItems: 'center', justifyContent: 'center'}} 
                            onClick={() => handleAcaoBtn()}
                        >{apresentacao === 3 ? 'Cancelar' : 'Novo termo'}</label>                         
                    </div>                
                    
                    {apresentacao <= 2 && (
                        <TermosLista termos={termos} listaTermos={listaTermos} exibeTermo={exibeTermo} />
                    )} 
                    {apresentacao === 1 && (
                        <TermosCadastro renderiza={renderiza} apresentacao={apresentacao} termoSel={termoSel} />
                    )} 
    
                    {apresentacao === 2 && (
                        <TermosCadastro renderiza={renderiza} apresentacao={apresentacao} termoSel={termoSel} />
                    )} 
    
                    {apresentacao === 3 && (
                        <TermosCadastro renderiza={renderiza} apresentacao={apresentacao} termoSel={termoSel} />
                    )}
                             
                </div>              
            </div>        
        );
    } else {
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                Seu usuário não tem permissão para acessar este recurso!
            </div>
        );
    }
    
}
  
export default Termos;