import React from 'react';

import classesApp from '../../App.module.css';

import { MdSearch } from "react-icons/md";

function TestesPesquisa( { renderiza, apresentacao, vlrPesq, tesPesquisa, listaTestes, defPesquisa, tpPesq, defineDdTemp, carregarTxt } ) {

    function handleAcaoBtn() {
        if (apresentacao === 3) {
            renderiza(0);
        } else {
            defineDdTemp(''); 
            carregarTxt([]);
            renderiza(3); // adicionar novo
        }
    }

    return (
        <div className={classesApp.containerMenuPg}>
            <label className={classesApp.botaoMenu} onClick={() => handleAcaoBtn()}>{apresentacao === 3 ? 'Cancelar' : 'Novo teste'}</label> {/* Mudar para cancelar quando estiver adicionando um texto */}
            {/* não precisou de mudar o botão de texto p ícone <label className={classes.Add}>+</label>*/}
            <div className={classesApp.containerPesquisa}>
                <label>Filtrar por: </label>   
                <input type="radio" name="opPesq" id="rdIdentificacao" checked={tpPesq === 'id'} value="nm" onClick={() => {
                        defPesquisa('id'); 
                        tesPesquisa('');
                    }} />
                <label htmlFor="rdIdentificacao">Identificação</label>
                <input type="radio" name="opPesq" id="rdResp" checked={tpPesq === 'resp'} value="resp" onClick={() => {
                        defPesquisa('resp'); 
                        tesPesquisa('0');
                    }} />
                <label htmlFor="rdResp">Resposta</label> 
                {
                    tpPesq === 'id' && (
                        <input type="text" maxLength={50} style={{marginLeft: '1rem'}} onChange={e => tesPesquisa(e.target.value)} value={vlrPesq} />
                    ) 
                }    
                {
                    tpPesq === 'resp' && (
                        <select name="apResp" id="apResp" value={vlrPesq} onChange={e => tesPesquisa(e.target.value)} style={{margin: '0rem 1rem'}}>
                            <option value="0">Não mostrar nada</option>
                            <option value="1">Apenas a pontuação</option> 
                            <option value="2">Respostas do aluno, sem correção</option> 
                            <option value="3">Respostas do aluno, erros e acertos</option> 
                            <option value="4">Respostas do aluno, e respostas corretas</option>
                        </select>
                    ) 
                }            
                <div>
                    <MdSearch size={'3rem'} className={classesApp.iconeMenuPg} onClick={() => {
                        listaTestes(); 
                        renderiza(0);
                    }} />
                </div>                                           
            </div>
        </div>       
    );
}
  
export default TestesPesquisa;