import React, { useState, useEffect } from 'react'; 

import classes from './homeTextos.module.css'; 
import classesApp from '../../App.module.css';

import api from '../../../services/api'; 
import { dados } from '../../../services/auth'; 

function TextosCadastro( { renderiza, apresentacao, txtSel } ) { 

    const [txt_id, setTxt_id] = useState(0); // usado como parâmetro para o filtro?
    const [txt_titulo, setTxt_titulo] = useState(''); 
    const [txt_completo, setTxt_completo] = useState('');
    const [txt_ref, setTxt_ref] = useState('');
    const [txt_autor, setTxt_autor] = useState('');
    const [txt_info, setTxt_info] = useState('');
    const [txt_responsabilidade, setTxt_responsabilidade] = useState(-1);
    const [usu_id, setUsu_id] = useState(dados.id); 

    useEffect(() => {           
        if (apresentacao === 1 || apresentacao === 2) {
            handleCarregaTxt(); 
        }
    }, []);

    function handleCarregaTxt() {
        setTxt_id(txtSel[0].txt_id); 
        setTxt_titulo(txtSel[0].txt_titulo); 
        setTxt_completo(txtSel[0].txt_completo); 
        setTxt_ref(txtSel[0].txt_ref); 
        setTxt_autor(txtSel[0].txt_autor); 
        setTxt_info(txtSel[0].txt_info); 
        setTxt_responsabilidade(txtSel[0].txt_responsabilidade); 
        setUsu_id(txtSel[0].usu_id);
    }

    async function handleCreate(){
        const dadosCad = { 
            txt_titulo, 
            txt_completo, 
            txt_ref, 
            txt_autor, 
            txt_info, 
            txt_responsabilidade, 
            usu_id
        };
        
        try {
            const response = await api.post('/textos', dadosCad); 
            alert("Id do texto: " + response.data);            
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }        
    }

    function handleValida() { 
        let irregulares = '';
        if (txt_titulo === '') irregulares += '- título' + '\n'; 
        if (txt_titulo.length > 0 && txt_titulo.length <= 4) irregulares += '- inserir o título completo' + '\n'; 
        if (txt_completo === '') irregulares += '- texto' + '\n'; 
        if (txt_completo.length > 0 && txt_completo.length <= 50) irregulares += '- o texto deve ter pelo menos 50 caracteres' + '\n'; 
        if (txt_ref === '') irregulares += '- referência do texto' + '\n'; 
        if (txt_ref.length > 0 && txt_ref.length <= 10) irregulares += '- inserir no mínimo o nome completo do autor(es)' + '\n'; 
        if (txt_autor === '') irregulares += '- nome do autor' + '\n'; 
        if (txt_autor.length > 0 && txt_autor.length <= 2) irregulares += '- inserir o nome do autor' + '\n'; 
        if (txt_info === '') irregulares += '- informações sobre o texto' + '\n'; 
        if (txt_info.length > 0 && txt_info.length <= 7) irregulares += '- inserir informações que auxilie na identificação do tipo ou público do texto (mais de 7 caracteres!)' + '\n'; 
        if (txt_responsabilidade === '-1') irregulares += '- definir a resposabilidade sobre o texto inserido' + '\n';  
                    
        if (irregulares === '') {
            return true;
        } else {
            alert("Campos a serem preenchidos: " + '\n' + irregulares);
            return false;
        }
    } 

    async function handleUpdate(id) {
        const dadosAt = {
            txt_titulo, 
            txt_completo, 
            txt_ref, 
            txt_autor, 
            txt_info, 
            txt_responsabilidade
        };
        
        try {
            await api.patch('/textos/' + id, dadosAt); 
            alert("Dados atualizados");
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }
    }

    function handleCadAt(id) {
        if (handleValida() === true) {
            if (apresentacao === 3) {
                handleCreate();            
            } else {
                handleUpdate(id);
            }           
            renderiza(0); 
        }        
    }

    return (
        <div>            
            <label>Título</label>
            <input type="text" maxLength={250} value={txt_titulo} onChange={e => setTxt_titulo(e.target.value)} />            
            <label>Autor(es)</label>
            <input type="text" maxLength={60} value={txt_autor} onChange={e => setTxt_autor(e.target.value)} />
            <br />
            <label>Referência</label>
            <textarea name="txaTexto" id="txaTexto" cols="30" rows="5" maxLength={500} value={txt_ref} onChange={e => setTxt_ref(e.target.value)} ></textarea>
            <br />            
            <label>Informações sobre o texto</label>
            <input type="text" maxLength={500} value={txt_info} onChange={e => setTxt_info(e.target.value)} /> 
            <div className={classes.containerResp}>
                <label>
                    {/* Abrir como modal */}
                    Declaro ciência sobre os <a href="/" target="_blank">direitos e responsabilidades de uso do texto</a>
                </label> 
                <select className={classesApp.lrg30} style={{marginLeft: '1rem'}} value={txt_responsabilidade} onChange={e => setTxt_responsabilidade(e.target.value)} name="selCid" id="selCid">
                    <option value="-1">-</option>
                    <option value="0">Sou o autor</option>
                    <option value="1">Texto livre para fins educacionais</option>
                    <option value="2">Tenho autorização para uso</option>
                </select>
            </div>      
            <label>Texto</label>          
            <textarea name="txaTexto" id="txaTexto" cols="30" rows="30" maxLength={20480} value={txt_completo} onChange={e => setTxt_completo(e.target.value)}></textarea>
            <div className={classesApp.containerBtnAcoes}>
                <label className={classesApp.btnAcoes} onClick={() => handleCadAt(txt_id)}>{apresentacao === 3 ? 'Salvar' : 'Atualizar'}</label>
            </div>                            
        </div>              
    );
}
  
export default TextosCadastro;
