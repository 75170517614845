import React from 'react'; 
import TestesVisualiza from '../components/pages/testes/testesVisualizar'; 

function Carregando() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '2rem'}}>
            <TestesVisualiza resolvendo={true} alnVisualiza={false} idTes={1} aln_tt_id={1}  />
        </div>      
    );
}
  
export default Carregando;