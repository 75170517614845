import React, { useEffect } from 'react';

import classesApp from '../../App.module.css';
import { MdVisibility } from "react-icons/md";


function ListaEscProf( { renderiza, listaInstituicoes, inst, defineIdInst } ) { 

    useEffect(() => {
        listaInstituicoes();
    }, []);  

    function tipoVinculo(cod) {
        switch (cod) {
            case 0:
                return 'Escola'; 
            case 1:
                return 'Professor'; 
            default:
                return 'Pesquisador';
        }
    }

    function mostrarCursos(idIns) { 
        defineIdInst(idIns);
        renderiza(1);
    }

    if (inst[0]) {
        return (
            <div className={classesApp.bordaTable}>
                <table>
                    <thead>
                        <tr>
                            <th className={classesApp.ttTable}>Escola</th>
                            <th className={classesApp.ttTable}>Cidade</th>
                            <th className={classesApp.ttTable}>Vínculo</th>   
                            <th className={classesApp.ttTable}>Ver cursos</th>   
                        </tr>
                    </thead>  
                    <tbody>
                        {                                                                      
                            inst.map(item => ( 
                                <tr key={item.ins_id}> 
                                    <th>{item.ins_nome}</th>
                                    <th>{item.cid_nome} / {item.uf_sigla}</th>
                                    <th>{tipoVinculo(item.usin_vinculado)}</th>
                                    <th><MdVisibility size={'2.5rem'} className={classesApp.iconeTable} onClick={() => mostrarCursos([item.ins_id, item.ins_nome])} /></th>  
                                </tr>                             
                            ))
                        }                                              
                    </tbody>  
                </table> 
            </div>                                                             
        );
    } else {
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                Nenhuma instituição vinsulada ao usuário!
            </div>
        );
    }
}
  
export default ListaEscProf;