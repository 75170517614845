import React from 'react';

import CabecalhoGeral from './pages/cabecalhos/headerHome'; 
import Home from './pages/publicas/home'; 
import Contato from './pages/publicas/contato'; 
import Sobre from './pages/publicas/sobre'; 

function App() {
  return (  
    <div>
      <CabecalhoGeral />      
      <Home />  
      <Sobre /> 
      <Contato />
    </div>    
  );
}

export default App;
