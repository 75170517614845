import classes from '../cabecalhos/headerHome.module.css';
import NavLinks from './navLinksProf';

function NavigationProf() {
    return (
        <nav className={classes.Navigation}>
            <NavLinks />
        </nav>        
    );
}

export default NavigationProf;