import React, { useEffect } from 'react';

import classesApp from '../../App.module.css';
import { MdVisibility, MdAddCircleOutline } from "react-icons/md";


function ListaCursos( { renderiza, listaCursos, cursos, defineIdCur, idInst, atualizaDados } ) { 

    useEffect(() => {
        listaCursos(); 
        atualizaDados(); 
    }, []); 

    function mostrarTurmas(idCur) { 
        defineIdCur(idCur);
        renderiza(3)
    }

    if (cursos[0]) {
        return ( 
            <div>
                <h1>Cursos</h1>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>                
                    <h2 style={{margin: '0rem'}}>{idInst[1]}</h2>
                    <label className={classesApp.btnAcaoSecundariaEscuro} style={{margin: '0rem', padding: '0rem', width: '8rem', textAlign: 'center'}} onClick={() => renderiza(0)}>Voltar</label>
                </div>
                <div className={classesApp.bordaTable}>
                    <table>
                        <thead>
                            <tr>
                                <th className={classesApp.ttTable}>Curso</th>
                                <th className={classesApp.ttTable}>Tipo</th> 
                                <th className={classesApp.ttTable}>Área</th> 
                                <th className={classesApp.ttTable}>Ver turmas</th>   
                            </tr>
                        </thead>  
                        <tbody>
                            {                                                                      
                                cursos.map(curso => ( 
                                    <tr key={curso.cur_id}> 
                                        <th>{curso.cur_nome}</th>
                                        <th>{curso.tpa_nome}</th>
                                        <th>{curso.arc_nome}</th>
                                        <th><MdVisibility size={'2.5rem'} className={classesApp.iconeTable} onClick={() => mostrarTurmas([curso.cur_id, curso.cur_nome])} /></th>  
                                    </tr>                             
                                ))
                            }                                                      
                        </tbody>  
                    </table> 
                    <div className={classesApp.containerBtnAcoes}>
                        <MdAddCircleOutline size={'2.5rem'} className={classesApp.iconeForm} style={{margin: '0rem 1rem'}} onClick={() => renderiza(7)} />
                    </div> 
                </div>
            </div>                                                                     
        );
    } else {
        return (
            <>
                <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                    Nenhum curso cadastrado na instituição!
                </div> 
                <div className={classesApp.containerBtnAcoes} style={{alignItems: 'center'}}>
                    <label className={classesApp.btnAcaoSecundariaEscuro} style={{margin: '0rem', padding: '0rem', width: '8rem', textAlign: 'center'}} onClick={() => renderiza(0)}>Voltar</label>
                    <MdAddCircleOutline size={'2.5rem'} className={classesApp.iconeForm} style={{margin: '0rem 1rem'}} onClick={() => renderiza(7)} />
                </div> 
            </>            
        );
    }
}
  
export default ListaCursos;