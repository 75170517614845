import React, { useState, useEffect } from 'react'; 

import classesApp from '../../../App.module.css';

import logo from '../../../../assets/logo_rxo.png';

import AlnTtDefineTurma from './alnTtDefineTurma'; 
import AlnTtQuestionario from './alnTtForm'; 
import Instrucoes from './alnTtInstrucoes'; 
import TestesVisualiza from '../../testes/testesVisualizar'; 
import AlnTtResultado from './alnTtResultados';

function HomeAlnTeste( { cursos, usu, defAlnTtId, aln_tt_id, teste, defInfoTes, defTxtDados, txtDados, recupTt,  renderizaAln} ){
    const [apresentacao, setApresentacao] = useState(0); 

    useEffect(() => {
        if (recupTt > 0) {
            setApresentacao(recupTt);
        }
    }, []);

    function renderiza(op) {
        setApresentacao(op);
    }

    return(
        <div className={classesApp.centraliza}>
            <div className={classesApp.home} style={{border: '0.5rem solid #320b86', padding: '0.5rem', marginBottom: '1rem', marginTop: '1rem'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem'}}>
                    <img style={{height: '8rem', width: '8rem'}} src={logo} alt="Logotipo teste cloze" />
                    <label style={{fontSize: '3.7rem', margin: '2rem', color: '#320b86'}}>OnlineCloze</label>
                </div>
                {apresentacao === 0 && (
                    <AlnTtDefineTurma renderiza={renderiza} cursos={cursos} usu={usu} defAlnTtId={defAlnTtId} teste={teste} />
                )}     

                {apresentacao === 1 && (
                    <AlnTtQuestionario renderiza={renderiza} teste={teste} aln_tt_id={aln_tt_id} /> 
                )}  

                {apresentacao === 2 && (
                    <Instrucoes renderiza={renderiza} teste={teste} defTxtDados={defTxtDados} txtDados={txtDados} aln_tt_id={aln_tt_id} />                   
                )}

                {apresentacao === 3 && (
                    <TestesVisualiza renderizaTeste={renderiza} resolvendo={true} alnVisualiza={false} idTes={teste.tes_id} aln_tt_id={aln_tt_id}  />
                )}                
                
                {apresentacao === 4 && (
                    <>
                        <h1 style={{textAlign: 'center', marginTop: '4rem'}}>Resultados</h1>
                        <AlnTtResultado alnVisualiza={false} teste={teste} aln_tt_id={aln_tt_id} renderizaAln={renderizaAln} permissao={teste.tes_apres_resp} />
                    </>                    
                )}                                                
            </div>            
        </div>
    );
}

export default HomeAlnTeste;