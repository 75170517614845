import React, { useEffect, useState } from 'react';

import classesApp from '../../App.module.css';

//import { MdAddBusiness } from "react-icons/md";

import api from '../../../services/api';
import Carregando from '../../../services/loadingPage';

function EscolasLista( { renderiza, carregaEscola, apresentacao, escolas, listaEscolas } ) {

    const [comunicandoApi, setComunicandoApi] = useState(true);

    useEffect(() => {
        listaEscolas(); 
        setComunicandoApi(false); 
    }, [apresentacao]); 

    async function exibeEscola(n) {
        setComunicandoApi(true);
        const response = await api.get('/instituicoes/perfil/' + n);
        carregaEscola(response.data);
        if (apresentacao === 0 || apresentacao === 1) {
            renderiza(2);
        } else {
            renderiza(1);
        } 
        setComunicandoApi(false); 
    }

    if (comunicandoApi === true) {
        return <Carregando />
    }

    if (escolas.length > 0) {
        return (
            <div className={classesApp.bordaTable}>            
                <table>
                    <thead>
                        <tr>
                            <th className={classesApp.ttTable}>Escola</th>                        
                            <th className={classesApp.ttTable}>Cidade</th>
                            <th className={classesApp.ttTable}>Cód. Cad. Prof.</th>
                        </tr>
                    </thead>  
                    <tbody>                    
                        {                               
                            escolas.map(escola => ( 
                                <tr key={escola.ins_id} onClick={() => exibeEscola(escola.ins_id)}>
                                    <th>{escola.ins_nome}</th>
                                    <th>{escola.cid_nome}</th>   
                                    <th>{escola.ins_cod_cad_prof}</th>   
                                </tr>                             
                            ))
                        }                                                                
                    </tbody>  
                </table>
            </div>       
        );
    } else {
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                Nenhum resultado foi encontrado!
            </div>       
        ); 
    }
    
}
  
export default EscolasLista;