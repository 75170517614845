import React, { useEffect } from 'react';

import classesApp from '../../App.module.css';
import { MdContentCopy, MdVisibility, MdAddCircleOutline } from "react-icons/md";


function ListaTurmas( { renderiza, idInst, idCur, listaTurmas, turmas, defineIdTurm, atualizaDados } ) { 

    useEffect(() => {
        listaTurmas(); 
        atualizaDados(); 
    }, []); 

    function tipoTurno(cod) {
        switch (cod) {
            case 0:
                return 'Matutino'; 
            case 1:
                return 'Vespertino'; 
            case 2:
                return 'Noturno';                
            default:
                return 'Integral';
        }
    }


    function mostrarAlunos(idTurm) { 
        defineIdTurm(idTurm);
        renderiza(5);
    }

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText('Insira o código a seguir no cadastro de aluno ou para inserir um aluno em uma turma: ' + copyMe);
            //alert('Código copiado');
        } catch (err) {
            alert('Falha ao copiar!');
        }
    };

    if (turmas[0]) {
        return (
            <div>
                <h1>Turmas</h1>
                <h2>{idInst[1]}</h2>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>                
                    <h2 style={{margin: '0rem'}}>{idCur[1]}</h2>
                    <label className={classesApp.btnAcaoSecundariaEscuro} style={{margin: '0rem', padding: '0rem', width: '8rem', textAlign: 'center'}} onClick={() => renderiza(1)}>Voltar</label>
                </div>

                <div className={classesApp.bordaTable}>
                    <table>
                        <thead>
                            <tr>
                                <th className={classesApp.ttTable}>Nome</th>
                                <th className={classesApp.ttTable}>Turno</th>
                                <th className={classesApp.ttTable}>Semestre/Ano</th> 
                                <th className={classesApp.ttTable}>Código aluno</th>   
                                <th className={classesApp.ttTable}>Copiar</th>  
                                <th className={classesApp.ttTable}>Ver alunos</th>   
                            </tr>
                        </thead>  
                        <tbody>
                            {                                                                      
                                turmas.map(turma => ( 
                                    <tr key={turma.trm_id}> 
                                        <th>{turma.trm_nome}</th>
                                        <th>{tipoTurno(turma.trm_turno)}</th>
                                        <th>{turma.trm_semestre_inicio}/{turma.trm_ano_inicio}</th>
                                        <th>{turma.trm_cod_cad_aln}</th>
                                        <th><MdContentCopy size={'2rem'} className={classesApp.iconeTable} onClick={() => copyToClipBoard(turma.trm_cod_cad_aln)} /></th>
                                        <th><MdVisibility size={'2.5rem'} className={classesApp.iconeTable} onClick={() => mostrarAlunos([turma.trm_id, turma.trm_nome, turma.trm_semestre_inicio, turma.trm_ano_inicio])} /></th>  
                                    </tr>                             
                                ))
                            }                            
                        </tbody>  
                    </table> 
                    <div className={classesApp.containerBtnAcoes}>
                        <MdAddCircleOutline size={'2.5rem'} className={classesApp.iconeForm} style={{margin: '0rem 1rem'}} onClick={() => renderiza(8)} />
                    </div> 
                </div>             
            </div>                                                            
        );
    } else {
        return (
            <>
                <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                    Nenhuma turma cadastrada no curso!
                </div> 
                <div className={classesApp.containerBtnAcoes} style={{alignItems: 'center'}}>
                    <label className={classesApp.btnAcaoSecundariaEscuro} style={{margin: '0rem', padding: '0rem', width: '8rem', textAlign: 'center'}} onClick={() => renderiza(1)}>Voltar</label>
                    <MdAddCircleOutline size={'2.5rem'} className={classesApp.iconeForm} style={{margin: '0rem 1rem'}} onClick={() => renderiza(8)} />
                </div> 
            </>            
        );
    }
}
  
export default ListaTurmas;