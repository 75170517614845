import React, { useState, useEffect } from 'react';

import classesApp from '../../App.module.css';
import { MdMailOutline, MdWifiProtectedSetup } from "react-icons/md"; //MdWifiProtectedSetup

import api from '../../../services/api';

function PopSubstEmail( { aln_id, nomeAln, defPopEmail } ) { 
    const [email, setEmail] = useState(''); 
    const [confEmail, setConfEmail] = useState(''); 
    const [mensErr, setMensErr] = useState(''); 
    const [mensSucess, setMensSucess] = useState('');
    const [emailOk, setEmailOk] = useState(false);

    function valida() { 
        let erro = '';
        if (validateEmail(email) === false) erro = 'Erro: E-mail inválido';
        if (email === '') erro = 'Erro: Preencher o e-mail'; 
        if (!(confEmail === email)) erro = 'Os valores preenchidos devem ser iguais.'; 
        if (erro === '') { 
            modEmail();
            setMensErr('');             
        } else {
            setMensErr(erro); 
        }
    } 

    function validateEmail(email) { 
        // https://www.horadecodar.com.br/2020/09/13/como-validar-email-com-javascript/
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    } 

    async function modEmail() { 
        const aln_email = email; 
        const dados = {
            aln_id, 
            aln_email 
        };
                 
        try {
            const response = await api.patch('/alunos/email/', dados); 
            setMensSucess(response.data.message); 
            setEmailOk(true);                        
        } catch (error) { 
            setMensErr(error.response.data.message); 
        }
    }

    return (
        <div 
            className={classesApp.formPopup}             
        >            
            <div style={{display: 'flex', justifyContent: 'right', width: '100%', marginBottom: '0rem'}} onClick={() => defPopEmail()}>
                <label style={{fontSize: '1.6rem', textAlign: 'center', color: 'coral', margin: '0rem 1rem', border: '0rem', padding: '0rem',  width: '100%'}}>{mensErr}</label>
                <label style={{fontSize: '1.4rem', borderRadius: '20rem', margin: '0rem', width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>X</label>                
            </div>
            {/* Insere e-mail */}
            <div style={emailOk ? {display: 'none'} : { display: 'inline-block' }}>
                <h3 style={{color: '#fafafa', textAlign: 'center'}}>Insira o novo email para o aluno:</h3>
                <h3 style={{color: '#fafafa', textAlign: 'center'}}>{nomeAln}.</h3>                
                <input type="text" placeholder='Novo e-mail' value={email} maxLength={70} onChange={e => setEmail(e.target.value)} style={{margin: '1rem 0rem', textAlign: 'center'}} /> 
                <input type="text" placeholder='Confirmar e-mail' value={confEmail} maxLength={70} onChange={e => setConfEmail(e.target.value)} style={{margin: '1rem 0rem', textAlign: 'center'}} />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <label 
                        style={{width: '20rem', textAlign: 'center', marginTop: '1rem'}}
                        onClick={() => {                                
                            valida();
                        }}
                    >Realizar alteração</label>
                </div>                
            </div>    
            <div style={emailOk ? {display: 'flex', height: '100%'} : { display: 'none' }}> 
                <h1 style={{color: '#fafafa', margin: '2rem', textAlign: 'center'}}>{mensSucess}</h1>
            </div>                        
        </div>
    )    
}


function ListaAlunos( { renderiza, idInst, idCur, idTurm, listaAlunos, alunos } ) { 

    const [popEmail, setPopEmail] =  useState(false); 
    const [aln_id, setAlnId] = useState(); 
    const [nomeAln, setNomeAln] = useState('');
    
    useEffect(() => {
        listaAlunos();
    }, [popEmail]); 

    function chamaInsereEmail(id, aln_nome) { 
        setAlnId(id); 
        setNomeAln(aln_nome); 
        setPopEmail(true);
    }

    function defPopEmail() {
        setPopEmail(false);
    }

    async function altSenhaAln(aln_id) { 
        let aln_chave_acesso = '123456'; 
        const dados = {
            aln_id, 
            aln_chave_acesso
        };
                 
        try {
            const response = await api.patch('/alunos/mudasenha', dados); 
            alert(response.data.message + '\n' + 'Nova senha: 123456');             
        } catch (error) { 
            alert(error.response.data.message);
        }
    } 

    if (alunos[0]) {
        return (
            <div>

                {
                    popEmail === true && ( 
                        <PopSubstEmail aln_id={aln_id} nomeAln={nomeAln} defPopEmail={defPopEmail} />
                    )
                }

                <h2>{idInst[1]}</h2>
                <h2>{idCur[1]}</h2>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>                
                    <h2 style={{margin: '0rem'}}>{idTurm[1]} - {idTurm[2]}/{idTurm[3]}</h2>
                    <label className={classesApp.btnAcaoSecundariaEscuro} style={{margin: '0rem', padding: '0rem', width: '8rem', textAlign: 'center'}} onClick={() => renderiza(3)}>Voltar</label>
                </div>
                
                <div className={classesApp.bordaTable}>
                    <table>
                        <thead>
                            <tr>
                                <th className={classesApp.ttTable}>Nome</th>
                                <th className={classesApp.ttTable}>E-mail</th>
                                <th className={classesApp.ttTable}>Corrigir e-mail</th>
                                <th className={classesApp.ttTable}>Resetar senha</th>
                            </tr>
                        </thead>  
                        <tbody>
                            {   
                                alunos.map(aluno => ( 
                                    <tr key={aluno.aln_id}> 
                                        <th>{aluno.aln_nome}</th>
                                        <th>{aluno.aln_email}</th>
                                        <th><MdMailOutline size={'2.5rem'} className={classesApp.iconeTable} onClick={() => chamaInsereEmail(aluno.aln_id, aluno.aln_nome)} /></th>
                                        <th><MdWifiProtectedSetup size={'2.5rem'} className={classesApp.iconeTable}  onClick={() => altSenhaAln(aluno.aln_id)} /></th> 
                                    </tr>                             
                                ))
                            }                                                      
                        </tbody>  
                    </table> 
                </div>  
            </div>                                                           
        );
    } else {
        return (
            <>
                <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                    Nenhuma aluno nesta turma!
                </div> 
                <div className={classesApp.containerBtnAcoes} style={{alignItems: 'center'}}>
                    <label className={classesApp.btnAcaoSecundariaEscuro} style={{margin: '0rem', padding: '0rem', width: '8rem', textAlign: 'center'}} onClick={() => renderiza(3)}>Voltar</label>                    
                </div> 
            </>            
        );
    }
}
  
export default ListaAlunos;