import React, {  useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css';

import HeaderHomeAln from './hearderHomeAln'; 
import AlnAcessoTeste from './alnAcessarTeste'; 
import AlnListaCursos from './alnListaCursos'; 
import AlnListaTestes from './alnListaTestes'; 
import Pontuacao from './alnTeste/alnTtResultados';
import VerTeste from '../testes/testesVisualizar'; 
import InicioTeste from './alnTeste/homeAlnTeste';

import api from '../../../services/api';
import { dados } from '../../../services/auth'; 

function HomeAln(){ 

    const [showMessage, setShowMessage] = useState(false); 
    const [codCur, setCodCur] = useState('');  
    const [apresentacao, setApresentacao] = useState(0); 
    const [nomeUsu, setNomeUsu] = useState(dados.nome); 
    const [cursos, setCursos] = useState([]); 
    const [mensAddCur, setMensAddCur] = useState('Insira o código para o curso'); 
    const [aln_tt_id, setAln_tt_id] = useState(0); 
    const [teste, setTeste] = useState([]); 
    const [txtDados, setTxtDados] = useState([]) 
    const [testes, setTestes] = useState([]); 
    const [recupTt, setRecupTt] = useState(0); // se não for zero sigifica que o aluno vai continuar um teste

    useEffect(() => {
        if (apresentacao === -1) {
            setApresentacao(1);
        }
    }, [apresentacao]);

    function verCad() {
        setShowMessage(true); 
    }

    function renderiza(op) {
        setApresentacao(op);
    }

    function renderizaTest() {
        //--
    } 

    async function buscaCursos() { 
        try {
            const response = await api.get('/alntrm?aln=' + dados.id); 
            setCursos(response.data); 
        } catch (error) { 
            setCursos([]); 
        }   
    } 

    async function addAlnCur() { 
        // vincular aluno ao curso
        const dadosAlnCur = {
            aln_id: dados.id, 
            trm_cod_cad_aln: codCur             
        };
        try { 
            const response = await api.post('/alntrm', dadosAlnCur); 
            alert(response.data.message);
            setMensAddCur('Insira o código para o curso'); 
            buscaCursos();
            setShowMessage(false);             
        } catch (error) { 
            setMensAddCur(error.response.data.message);
        }
        
    }

    function defInfoTes(arr) { 
        setTeste(arr);
    }

    function defAlnTtId(id) {
        setAln_tt_id(id); 
    } 

    function defTxtDados(arr) {
        setTxtDados(arr);
    }

    async function listaTestesAln() {
        try {
            const response = await api.get('/alnteste/lista/' + dados.id);                
            setTestes(response.data); 
        } catch (error) { 
            alert(error.response.data.message); 
            setTestes([]); 
        } 
    }

    function defRecupTt(valor) {
        setRecupTt(valor);
    }

    return(
        <> 
            {apresentacao < 2 && (
            <div className={classesApp.centraliza}>
                <HeaderHomeAln />
                <div className={classesApp.home}>                
                    <div 
                        className={classesApp.formPopup}                     
                        style={!showMessage ? {display: 'none'} : { display: 'flex' }}
                    >
                        <div style={{display: 'flex', justifyContent: 'right', width: '100%', margin: '0rem'}} onClick={() => setShowMessage(false)}>
                            <label style={{fontSize: '1.4rem', borderRadius: '20rem', margin: '0rem', width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>X</label>                
                        </div>
                        <h2 style={{color: '#fafafa'}}>{mensAddCur}</h2>                
                        <input type="text" value={codCur} maxLength={18} onChange={e => setCodCur(e.target.value)} style={{margin: '2rem 0rem', textAlign: 'center'}} />
                        <div style={{display: 'flex', justifyContent: 'right'}}>
                            <label 
                                onClick={() => {                                
                                    addAlnCur();                                
                                }}
                            >Continuar</label>
                        </div>                
                    </div>

                    {/* Inicial */}
                    {apresentacao === 0 && (
                        <>
                            <AlnAcessoTeste renderiza={renderiza} defInfoTes={defInfoTes} />
                            <h1 className={classesApp.destacaH1}>Olá, {nomeUsu}</h1>
                            <h1>Cursos</h1>
                            <AlnListaCursos verCad={verCad} buscaCursos={buscaCursos} cursos={cursos} /> 
                            <AlnListaTestes renderiza={renderiza} listaTestesAln={listaTestesAln} testes={testes} defInfoTes={defInfoTes} defAlnTtId={defAlnTtId} defRecupTt={defRecupTt} aln_tt_id_ctr={aln_tt_id} /> 
                        </>                    
                    )} 

                    {/* Visão teste */}
                    {apresentacao === 1 && (
                        <>
                            {
                                teste.tes_apres_resp < 2 && (
                                    <Pontuacao alnVisualiza={true} renderizaAln={renderiza} permissao={teste.tes_apres_resp} teste={teste} aln_tt_id={aln_tt_id} />
                                )
                            }
                            {
                                teste.tes_apres_resp > 1 && (
                                <>
                                    <Pontuacao alnVisualiza={true} renderizaAln={renderiza} permissao={teste.tes_apres_resp} teste={teste} aln_tt_id={aln_tt_id} />
                                    <VerTeste alnVisualiza={true} renderizaAln={renderiza} idTes={teste.tes_id} resolvendo={false} teste={teste} renderizaTest={renderizaTest} aln_tt_id={aln_tt_id} />
                                </>
                                )
                            } 
                        </>
                    )}                                  
                </div>
            </div>
            )}
            {/* Iniciar teste */}
            {apresentacao === 2 && (
                <div>
                    <InicioTeste renderizaAln={renderiza} cursos={cursos} usu={dados.id} defAlnTtId={defAlnTtId} aln_tt_id={aln_tt_id} teste={teste} defTxtDados={defTxtDados} txtDados={txtDados} recupTt={recupTt} /> 
                </div>
            )}
        </>
    );
}

export default HomeAln;