import React from 'react';

import classesApp from '../../App.module.css';

import PageHeader from '../cabecalhos/headerPages';

function Contato() {
    return (
        <div className={classesApp.centraliza}>
            <div className={classesApp.home} id='contato'>
                <PageHeader title={'Fale com a gente!'}/>                                    
                <h2>Contato</h2>
                <p>
                    Para saber mais sobre o projeto, ou caso deseja utilizar a plataforma entre em contato através do e-mail: contato@onlinecloze.com.br
                </p>               
                <br />   
                <br />                
            </div>
        </div>        
    );
}
  
export default Contato;