import React, { useState, useEffect } from 'react'; 

import classes from './homeTestes.module.css'
import classesApp from '../../App.module.css';

function TestesFixLac( { renderiza, txtSelTeste, defineNlac, defineApresPlv } ) { 
    //const [plvExc, setPlvExc] = useState([]); // tipos de itens do arrTxt 
    const [txtLbl, setTxtLbl] = useState(); // array gerado map para renderização de labels
    let arrTpPlv = []; // array temporário para controle de envio de dados para plvExc 

    useEffect(() => {
        // 2ª função chamada paraa separar os pontos e as palavras                   
        pLabel(txtSelTeste);
    }, []); 
    
     

    // 2ª função usada para identificar o que é palavra e o que não é - o parâmetro é state array com palavras e pontuações separados
    function pLabel(txt) {
        let arrExcluidas = []; // array temporário
        let codlbl; // usada para armazenar o retorno que será em jsx
        const atpl = /[“, (]/; //sinais antes palavra
        const dppl = /[!, ., ..., ?, ), :, ”, ;]/; //sinais depois da palavra
        const sinais = /[“, (, !, ., ?, ), :, ”, ;]/; // todos os sinais
        let tAtpl; // armazena resultado da verificação se é um sinal que vem antes da palavra
        let tDppl; // armazena resultado da verificação se é um sinal que vem depois da palavra
        let tSinal; // armazena resultado da verificação se é um sinal
        let tipo; // variável temporária para o tipo
        let arrlab = txt.map((vlr, i) => {
            tAtpl = atpl.test(vlr); //verifica se eh sinal antes
            tDppl = dppl.test(vlr); // verifica se eh sinal depois
            tSinal = sinais.test(vlr); // verifica só se é sinal
            // verifica se atual é sinal antes e próximo é palavra ou se é palavra e próximo é sinal depois
            if (tSinal === true) {
                if (tAtpl === true) {
                    tipo = 'sat'; // sinal antes de palavra
                }
                if (tDppl === true) {
                    const idfptesp = sinais.test(txt[i + 1]);
                    if (idfptesp === true) {
                        tipo = 'sat';
                        } else {
                            tipo = 'sdp'; // sinal depois de palavra
                        }
                }
            } else {
                // se estiver classificado como palavra distingue quebra e palavras
                if (vlr === '\n') {
                    tipo = 'br';
                } else {
                    const idf = dppl.test(txt[i + 1]); // verifica se o próximo valor é uma pontuação
                    //console.log(txt[i] + ' - ' + idf);
                    if (idf === true) {
                    tipo = 'plnf'; // palavra em final de paragrafo se o próximo for pontuação
                    } else {
                    tipo = 'pln'; // palavra normal, onde será inserido um espaço após a inserção da palavra no label
                    }
                }
            }
            arrExcluidas.push(tipo); // insere o tipo referente a posição no array da palavra

            codlbl = geraComponente(i, vlr, tipo); // gera o que será renderizado em jsx para cada posição do array
            return codlbl; // esta função retornar o jsx de cada posição para o array temporário
        });

        arrTpPlv = arrExcluidas; // passa valor do tipo das posições para variável de auxílio - precisei dela para atualizar os valores corretamente através do setPlvExl
        defineApresPlv(arrTpPlv); // atualiza state com array de tipo de palavras
        setTxtLbl(arrlab); // atualizar array que exibe o texto em labels
    }

    // 3ª função usada para retornar um JSX com a palavra ou pontuação
    function geraComponente(chave, valor, tipo) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp

        if (tipo === 'br') {
            return <br key={chave} />;
        }

        if (tipo === 'pln') {
            return (
                <label
                    key={chave}
                    value={valor}
                    onClick={() => excPlv(chave)}
                    //className="txt_lbl_n" 
                    className={classesApp.txt_lbl_n}
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plnf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    onClick={() => excPlv(chave)}
                    //className="txt_lbl_n"
                    className={classesApp.txt_lbl_n}
                >
                    {valor}
                </label>
                );
        }

        if (tipo === 'pls') {
            return (
                <label
                    key={chave}
                    value={valor}
                    onClick={() => excPlv(chave)}
                    //className="txt_lbl_s" // formatação diferente para palavras que foram excluídas da possibilidade de se tornar lacunas
                    className={classesApp.txt_lbl_s}
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plsf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    onClick={() => excPlv(chave)}
                    //className="txt_lbl_s" // formatação diferente para palavras que foram excluídas da possibilidade de se tornar lacunas
                    className={classesApp.txt_lbl_s}
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sat') {
            return (
                <label key={chave} value={valor} className={classesApp.txt_lbl_n}>
                    {valor}
                </label>
            );
        }

        if (tipo === 'sdp') {
            return (
                <label key={chave} value={valor} className={classesApp.txt_lbl_n}>
                    {valor + ' '}
                </label>
            );
        }
    } 

    // 4ª função retirar palavras do texto
    function excPlv(chave) {
        let plvExcTemp = arrTpPlv; // array tipo palavra será atualizado, por isso criado um temporário

        // mudar valor ao clicar, com base no valor anterior o atual é invertido
        if (plvExcTemp[chave] === 'plnf') {
            plvExcTemp[chave] = 'plsf';
        } else if (plvExcTemp[chave] === 'pln') {
            plvExcTemp[chave] = 'pls';
        } else if (plvExcTemp[chave] === 'plsf') {
            plvExcTemp[chave] = 'plnf';
        } else if (plvExcTemp[chave] === 'pls') {
            plvExcTemp[chave] = 'pln';
        }

        // renderizar
        let codlbl;
        // percorre todo array temporário de tipo de palavras para gerar uma visualização atualizada, destacando a palavra excluida da possibilidade de se tornar uma lacuna
        let arrlab = txtSelTeste.map((vlr, i) => {
            codlbl = geraComponente(i, vlr, plvExcTemp[i]);
            return codlbl;
        });

        // atualiza os valores da lista de tipos e o que será renderizado como labels
        arrTpPlv = plvExcTemp;
        defineApresPlv(arrTpPlv);
        setTxtLbl(arrlab);
    }

    function seguirEtapa(n) {
        defineNlac(n);
        renderiza(5); 
    }

    return (
        <div className={classesApp.centraliza}>
                <h1>Fixar palavras e definir lacunas</h1> 
                <p>Clique nas palavras que deseja manter sem lacunas</p>   
                <div className={classesApp.textoTeste}>
                    {txtLbl}
                </div>
                <fieldset>
                        <legend>Lacunas a cada...</legend>
                        <div className={classes.containerBotoesDefLac}>
                            <label className={classesApp.btnAcoes} onClick={() => seguirEtapa(5)}>5 palavras</label>
                            <label className={classesApp.btnAcoes} onClick={() => seguirEtapa(7)}>7 palavras</label>
                            <label className={classesApp.btnAcoes} onClick={() => seguirEtapa(10)}>10 palavras</label>
                            <label className={classesApp.btnAcoes} onClick={() => seguirEtapa(0)}>Escolher palavras</label>
                        </div>                        
                </fieldset>          
        </div>        
    );
}
  
export default TestesFixLac;