import React, { useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css';

import api from '../../../services/api'; 
import pad from '../../../services/pad';

function TermosCadastro( { renderiza, apresentacao, termoSel } ) { 

    const [term_id, setTerm_id] = useState(0); // usado como parâmetro para o filtro?
    const [term_dt_pub, setTerm_dt_pub] = useState(''); 
    const [term_texto, setTerm_texto] = useState('');

    useEffect(() => {           
        if (apresentacao === 1 || apresentacao === 2) {
            handleCarregaTermo(); 
        }
    }, []);

    function handleCarregaTermo() {
        setTerm_id(termoSel.term_id); 
        setTerm_dt_pub(apresentaData(termoSel.term_dt_pub)); 
        setTerm_texto(termoSel.term_texto);
    }

    async function handleCreate(){
        const dadosCad = { 
            term_dt_pub: gravaData(term_dt_pub), 
            term_texto
        };
        console.log(term_texto);
        try {
            const response = await api.post('/termos', dadosCad); 
            alert("Id do termo: " + response.data);            
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message); 
            } else {
                alert(error);
            }  
        }        
    }

    function handleValida() { 
        let irregulares = '';
        if (term_dt_pub === '') irregulares += '- data' + '\n'; 
        if (term_texto === '') irregulares += '- texto do termo' + '\n'; 
        if (term_texto.length > 0 && term_texto.length <= 50) irregulares += '- o texto do termo deve ter pelo menos 50 caracteres' + '\n'; 
                    
        if (irregulares === '') {
            return true;
        } else {
            alert("Campos a serem corrigidos: " + '\n' + irregulares);
            return false;
        }
    } 

    function handleCadAt() {
        if (apresentacao === 3) {
            if (handleValida() === true) {
                handleCreate(); 
                renderiza(0);   
            }                                           
        } 
        if (apresentacao !== 3) {
            renderiza(0);
        }
    }

    function apresentaData(dtBd) {
        let data = new Date(termoSel.term_dt_pub); 
        const dt = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate());
        return dt;
    } 

    function gravaData(dataCad) {
        const data = new Date(dataCad); 
        const dtCtr = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate() + 1);
        return dtCtr; 
    }    

    return (
        <div>            
            <label>Data publicação</label>
            <input type="date" maxLength={250} value={term_dt_pub} onChange={e => setTerm_dt_pub(e.target.value)} />            
            <label>Termo</label>
            <br />
            <textarea name="txaTexto" id="txaTexto" cols="30" rows="5" maxLength={8000} value={term_texto} onChange={e => setTerm_texto(e.target.value)}></textarea>
            <br />       
            <div className={classesApp.containerBtnAcoes}>
                <label className={classesApp.btnAcoes} onClick={() => handleCadAt()}>{apresentacao === 3 ? 'Salvar' : 'Voltar'}</label>
            </div>                            
        </div>              
    );
}
  
export default TermosCadastro;
