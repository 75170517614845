import React, { useEffect, useRef } from 'react';

import classesApp from '../../App.module.css';

import { MdCheck } from "react-icons/md"; 

// usado para posicionar a página após pesquisa em conjunto com o hook useRef
const useScroll = () => {
    const elRef = useRef(null);
    const executeScroll = () => elRef.current.scrollIntoView();

    return [executeScroll, elRef];
};

function TextosLista( { teste, apresentacaoTes, carregarTxt, textos, listaTextos, exibeTexto, apresentacao } ) { 

    const [executeScroll, elRef] = useScroll(); // usado para posicionar a página após pesquisa

    useEffect(() => {
        listaTextos();
    }, [apresentacao]); 
    
    if (textos[0]) {
        return (
            <div className={classesApp.bordaTable} ref={elRef}>
                <table>
                    <thead>
                        <tr>
                            <th className={classesApp.ttTable} style={{width: '40%'}} >Título</th>
                            <th className={classesApp.ttTable} style={{width: '40%'}}>Sobre o texto</th>   
                            {
                                teste && (
                                    <th className={classesApp.ttTable}>Selecionar</th>
                                )   
                            }
                            
                        </tr>
                    </thead>  
                    <tbody>
                        {                                                                      
                            textos.map(texto => ( 
                                <tr key={texto.txt_id}>
                                    <th onClick={() => {                                        
                                        exibeTexto(texto.txt_id);
                                        executeScroll(); 
                                    }}>{texto.txt_titulo}</th>
                                    <th onClick={() => {
                                        exibeTexto(texto.txt_id); 
                                        executeScroll();
                                    }}>{texto.txt_info}</th>
                                    {
                                        teste && (
                                            <th><MdCheck size={'2.5rem'} className={classesApp.iconeTable} onClick={() => {
                                                carregarTxt([texto.txt_id, texto.txt_titulo]);
                                                apresentacaoTes(3); 
                                            }} /></th>
                                        )
                                    }   
                                </tr>                             
                            ))
                        }                          
                    </tbody>  
                </table>
            </div>       
        );
    } else {
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                Nenhum resultado foi encontrado!
            </div>
        );
    }
}
  
export default TextosLista;