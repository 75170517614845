import { Link } from 'react-router-dom'; 

function NavLinks(props) {

    return ( 
        <ul>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <a href="/#">Início</a>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <a href="/#sobre">Sobre</a>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <a href="/#contato">Contato</a>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <Link to='/login'>Login</Link> 
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <Link to='/cadusu'>Cadastrar</Link>
            </li>
        </ul> 
    );
}

export default NavLinks;