import React, { useState, useEffect } from 'react'; 
import { Link, useParams } from 'react-router-dom'; 

import classes from '../publicas.module.css'; 
import classesApp from '../../../App.module.css';

import logo from '../../../../assets/logo_rxo.png'; 

import api from '../../../../services/api';

function ConfEmail() { 

    const { tipo, id, token } = useParams(); 
    const [nomeUsu, setNomeUsu] = useState(''); // pegar primeiro nome 
    const [mensagem, setMensagem] = useState('');
    const [erro, setErro] = useState(false);

    useEffect(() => { 
        verificaToken();
    }, []); 

    async function verificaToken() {
        try {
            if (tipo === 'usuario') {
                const response = await api.get('/usuario/verifica/' + id + '/' + token); 
                setNomeUsu(response.data.nome);         
                ativaUsu(response.data.id); 
            } else if (tipo === 'aluno') {
                const response = await api.get('/alunos/verifica/' + id + '/' + token);
                setNomeUsu(response.data.nome);         
                ativaAln(response.data.id); 
            } else {
                setMensagem('Link de ativação inválido!');
            }                 
        } catch (error) { 
            setErro(true);
            setMensagem(error.response.data.message);
        }
    } 

    async function ativaUsu(usu_id) { 
        const dados = {
            usu_id
        };
                 
        try {
            const response = await api.patch('/usuarios/verifica', dados); 
            setMensagem(response.data.message);
        } catch (error) { 
            setErro(true);
            setMensagem(error.response.data.message);
        }
    } 

    async function ativaAln(aln_id) { 
        const dados = {
            aln_id
        };
                 
        try {
            const response = await api.patch('/alunos/verifica/', dados); 
            setMensagem(response.data.message);
        } catch (error) { 
            setErro(true);
            setMensagem(error.response.data.message);
        }
    }    

    return(
        <div  className={classes.centralizaLog} style={{height: '34rem', marginTop:'-17rem'}}>
            <div className={classes.titulo}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
                </div>
                <label style={{marginTop: '2rem'}}>Olá {nomeUsu}</label>                 
                <label style={{color: erro ? 'coral' : '#320b86', margin: '2rem 0rem'}}>{mensagem}</label>
                <div className={classesApp.containerBtnPub} style={{marginTop: '1rem', width:'40%', textAlign:'center'}}>                    
                    <Link 
                        to='/login' 
                        className={classesApp.btnAcaoSecundariaEscuro}                         
                    >Prosseguir</Link> 
                </div>                
        </div>
    );
}

export default ConfEmail;