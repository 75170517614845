import React, { useEffect } from 'react';
import classesApp from '../../App.module.css';

import { MdAddCircleOutline } from "react-icons/md";

function AlnListaCursos( { verCad, buscaCursos, cursos } ) { 

    const cursando = ['Cursando', 'Formado', 'Desistente'];

    useEffect(() => {
        buscaCursos();
    }, []);  

    if (cursos.length > 0) {
        return (
            <div className={classesApp.bordaTableSemSel}>
                <table>
                    <thead>
                        <tr>
                            <th className={classesApp.ttTable}>Instituição</th>
                            <th className={classesApp.ttTable}>Curso</th>
                            <th className={classesApp.ttTable}>Período</th>
                            <th className={classesApp.ttTable}>Situação</th> 
                        </tr>
                    </thead>  
                    <tbody>
                        {                                                                      
                            cursos.map(item => ( 
                                <tr key={item.aln_id}> 
                                    <th>{item.ins_nome}</th>
                                    <th>{item.cur_nome}</th>
                                    <th>{item.trm_periodo === 0 ? 'Semestral' : 'Anual'}</th>
                                    <th>{cursando[item.aln_trm_cursando]}</th>
                                </tr>                             
                            ))
                        }                                              
                    </tbody>  
                </table> 
                <div className={classesApp.containerBtnAcoes} onClick={() => verCad()}>
                    <MdAddCircleOutline size={'2.5rem'} className={classesApp.iconeForm} style={{margin: '0rem 1rem'}} />
                </div> 
            </div>       
        );
    } else { 
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                Nenhum curso vinculado ao usuário!
            </div>
        );
    }
}

export default AlnListaCursos;