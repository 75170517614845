import React, { useState } from 'react'; 

import classesApp from '../../App.module.css';

import Header from './headerHomeProf';
import ListaEscProf from './listaEscolasProf'; 
import ListaCursos from '../cursos/listaCursos'; 
import ListaTurmas from '../turmas/listaTurmas'; 
import ListaAln from '../alunos/alnLista'; 
import CadCursos from '../cursos/cadCursos'; 
import CadTurmas from '../turmas/cadTurmas'; 
import DadosProf from './dadosHomeProf';

import { dados } from '../../../services/auth'; 
import api from '../../../services/api';

function Home() { 
    const [apresentacao, setApresentacao] = useState(0); 
    const [usuLog, setUsuLog] = useState(dados.id);
    const [inst, setInst] = useState([]); // lista instituições vinculadas ao usuário
    const [idInst, setIdInst] = useState([]); // id da instituição selecionada e nome
    const [cursos, setCursos] = useState([]); // lista de cursos de uma instituição 
    const [idCur, setIdCur] = useState([]); // id do curso selecionado e nome 
    const [turmas, setTurmas] = useState([]); // lista turmas selecionadas 
    const [idTurm, setIdTurm] = useState([]); // id da tura selecionada e nome 
    const [alunos, setAlunos] = useState([]); // alunos da turma 
    const [statusVis, setStatusVis] = useState(0); // status para garantir atualização ao cadastrar turmas e cursos

    function renderiza(op) {
        setApresentacao(op);
    } 

    function atualizaDados() {
        if (statusVis === 0) {
            setStatusVis(1);  
        } else { 
            setStatusVis(0);
        }
    }

    async function listaInstituicoes() { 
        try {
            if (dados.acesso === 'dende') {
                const response = await api.get('/usuins');                
                setInst(response.data);
            } else {
                const response = await api.get('/usuins?usu=' + usuLog);                
                setInst(response.data); 
            }
        } catch (error) { 
            setInst([]); 
        }   
    } 

    function defineIdInst(id) {
        setIdInst(id);
    } 

    async function listaCursos() { 
        try {
            const response = await api.get('/cursos?ins=' + idInst[0]);                
            setCursos(response.data); 
        } catch {
            setCursos([]); 
        }   
    } 

    function defineIdCur(id) {
        setIdCur(id);
    }

    async function listaTurmas() { 
        try {
            const response = await api.get('/turmas?cur=' + idCur[0]);                
            setTurmas(response.data); 
        } catch {
            setTurmas([]); 
        }   
    } 

    function defineIdTurm(id) {
        setIdTurm(id);
    }

    async function listaAlunos() { 
        try {
            const response = await api.get('/alntrm?trm=' + idTurm[0]);                
            setAlunos(response.data); 
        } catch {
            setAlunos([]); 
        }   
    }     

    return (
        <div className={classesApp.centraliza}>
            <Header />
            <div className={classesApp.home} id='home'> 
                {apresentacao === 0 && (
                    <DadosProf />
                )}
                {apresentacao === 0 && (
                    <ListaEscProf renderiza={renderiza} listaInstituicoes={listaInstituicoes} inst={inst} defineIdInst={defineIdInst} atualizaDados={atualizaDados} />
                )}


                {apresentacao === 1 && (
                    <ListaCursos renderiza={renderiza} idInst={idInst} listaCursos={listaCursos} cursos={cursos} defineIdCur={defineIdCur} atualizaDados={atualizaDados} />
                )} 

                {apresentacao === 2 && (
                    <ListaCursos renderiza={renderiza} idInst={idInst} listaCursos={listaCursos} cursos={cursos} defineIdCur={defineIdCur} atualizaDados={atualizaDados} />
                )} 


                {apresentacao === 3 && (
                    <ListaTurmas renderiza={renderiza} idInst={idInst} idCur={idCur} listaTurmas={listaTurmas} turmas={turmas} defineIdTurm={defineIdTurm} atualizaDados={atualizaDados} /> 
                )}

                {apresentacao === 4 && (
                    <ListaTurmas renderiza={renderiza} idInst={idInst} idCur={idCur} listaTurmas={listaTurmas} turmas={turmas} defineIdTurm={defineIdTurm} atualizaDados={atualizaDados} /> 
                )}                


                {apresentacao === 5 && (
                    <ListaAln renderiza={renderiza} idInst={idInst} idCur={idCur} idTurm={idTurm} listaAlunos={listaAlunos} alunos={alunos} atualizaDados={atualizaDados} />
                )}


                {apresentacao === 7 && (
                    <CadCursos renderiza={renderiza} idInst={idInst} statusVis={statusVis} /> 
                )}  


                {apresentacao === 8 && (
                    <CadTurmas renderiza={renderiza} idInst={idInst} idCur={idCur} statusVis={statusVis} />
                )} 
            </div>              
        </div>        
    );
}
  
export default Home;