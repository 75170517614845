import React, {  useState, useEffect } from 'react'; 
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import classes from './homeTestes.module.css'; 
import classesApp from '../../App.module.css';

import api from '../../../services/api';

import { MdTextSnippet } from "react-icons/md";

function TesteDados( { renderiza, idTes } ) { 

    const [idade_ini, setIdade_ini] = useState('0'); 
    const [idade_fin, setIdade_fin] = useState('120'); 
    const [ins_id, setIns_id] = useState(0); 
    const [cur_id, setCur_id] = useState(0); 
    const [trm_id, setTrm_id] = useState(0); 
    const [trm_periodo, setTrm_periodo] = useState(-1); 
    const [aln_sexo, setAln_sexo] = useState(-1); 
    const [pesquisa, setPesquisa] = useState([]); 
    const [aln_tt_pts, setAln_tt_pts] = useState(-1); //busca todos, 0 busca apenas testes concluídos
    const [estatistica, setEstatistica] = useState([]); 
    const [lacunas, setLacunas] = useState(0); 
    const [apresentacaoDados, setApresentacaoDados] = useState(0); // 0 resumido, 1 completo, 2 pesquisa
    const [dadosLista, setDadosLista] = useState([]); 

    const periodo = ['Matutino', 'Vespertino', 'Noturno', 'Integral']; 
    const sexo = ['Feminino', 'Masculino']; 
    const dispositivos = ['Computador', 'Smartphone', 'Tablet'];

    useEffect(() => { 
        dadosTeste(); 
        dadosEstatistica(); 
        atribuiDadosLista(); 
        identNlac();
    }, []); 

    async function dadosTeste() {
        // busca dados alnTeste
        const dadosCons = {
            tes_id : idTes, 
            ins_id, 
            cur_id, 
            trm_id, 
            trm_periodo, 
            aln_sexo, 
            aln_tt_pts,  
            idade_ini, 
            idade_fin
        }

        try {
            const registraResp = await api.post('/alnteste/dados', dadosCons);
            setPesquisa(registraResp.data);            
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }
        } 
    }

    async function atribuiDadosLista() {
        // busca dados alnTeste
        const dadosCons = {
            tes_id : idTes, 
            ins_id, 
            cur_id, 
            trm_id, 
            trm_periodo, 
            aln_sexo, 
            aln_tt_pts,  
            idade_ini, 
            idade_fin
        }

        try { 
            // buscar sexo, instituição, curso, turma, período
            const registraResp = await api.post('/alnteste/listas', dadosCons);
            setDadosLista(registraResp.data);            
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }
        } 
    }

    async function dadosEstatistica() {
        // busca dados alnTeste
        const dadosCons = {
            tes_id : idTes, 
            ins_id, 
            cur_id, 
            trm_id, 
            trm_periodo, 
            aln_sexo, 
            aln_tt_pts,  
            idade_ini, 
            idade_fin
        }

        try {
            const buscaEstat = await api.post('/alnteste/estatistica', dadosCons);
            setEstatistica(buscaEstat.data);            
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }
        } 
    }

    // valida pesquisa
    function valida() {
        let mensagem = ''; 

        if (idade_ini < 0) mensagem += '- a idade inicial não pode ser menor que 0\n'; 
        if (idade_fin > 120) mensagem += '- a idade final não pode ser maior que 120\n';
        if (idade_fin < idade_ini) mensagem += '- a idade final não pode ser menor que a idade inicial\n'; 
        if (idade_ini == '') mensagem += '- o valor da idade inicial não pode ficar em branco\n'; 
        if (idade_fin == '') mensagem += '- o valor da idade final não pode ficar em branco\n';

        if (mensagem === '') {
            return true;
        } else {
            alert('Para atualizar a pesquisa é neessário corrigir o(s) seguinte(s) problema(s): \n' + mensagem); 
            return false;
        }
    }

    function filtra() {
        if (valida() === true) { 
            dadosTeste(); 
            dadosEstatistica(); 
            //atribuiDadosLista(); //assim não muda as opções a cada vez que filtrar os resultados
        }
    }

    async function identNlac() { 
        try {
            const buscaEstat = await api.get('/txtdados/lac/' + idTes);
            setLacunas(buscaEstat.data);            
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }
        } 
    }

    if (pesquisa[0]) {
        return (
            <div className={classesApp.Centraliza}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h1>Dados do teste</h1> 
                    <label className={classesApp.btnAcoes} onClick={() => renderiza(0)}>Voltar</label>                  
                </div>                   
                <fieldset>
                    <legend>Filtrar testes por...</legend>
                    <div className={classes.filtroBusca}>
                        <div className={classesApp.colunas}>
                            <label className={classesApp.lrg50}>Escola</label>                        
                            <label className={classesApp.lrg50} style={{marginLeft: '1rem'}}>Curso</label>
                        </div>
                        <div className={classesApp.colunas}>
                            <select name="escola" id="escola" value={ins_id} onChange={e => setIns_id(e.target.value)}>
                                <option value="0">Todas</option> 
                                {
                                    dadosLista.inst ? dadosLista.inst.map(lista => ( 
                                        // não permitir repetição de escola - fazer o mesmo com os outros itens listados - já fazer essa filtragem no sql para trazer a lista correta aqui
                                        <option key={lista.ins_id} value={lista.ins_id}>{lista.ins_nome}</option>
                                    )) : null
                                } 
                            </select>
                            <select name="curso" id="curso" style={{marginLeft: '1rem'}} value={cur_id} onChange={e => setCur_id(e.target.value)}>
                                <option value="0">Todos</option>
                                {
                                    dadosLista.curso ? dadosLista.curso.map(lista => (
                                        <option key={lista.cur_id} value={lista.cur_id}>{lista.cur_nome}</option>
                                    )) : null
                                }
                            </select>   
                        </div>
                        <div className={classesApp.colunas}>
                            <label className={classesApp.lrg33}>Turma</label>
                            <label className={classesApp.lrg33} style={{marginLeft: '1rem'}}>Periodo</label> 
                            <label className={classesApp.lrg33} style={{marginLeft: '1rem'}}>Apenas testes concluídos</label>
                        </div>
                        <div className={classesApp.colunas}>
                            <select name="turma" id="turma" className={classesApp.lrg33} value={trm_id} onChange={e => setTrm_id(e.target.value)}>
                                <option value="0">Todas</option>
                                {
                                    dadosLista.turma ? dadosLista.turma.map(lista => (
                                        <option key={lista.trm_id} value={lista.trm_id}>{lista.trm_nome}</option>
                                    )) : null
                                }
                            </select>  
                            <select name="periodo" id="periodo" className={classesApp.lrg33} style={{marginLeft: '1rem'}} value={trm_periodo} onChange={e => setTrm_periodo(e.target.value)}>
                                <option value="-1">Todos</option>
                                {
                                    dadosLista.periodo ? dadosLista.periodo.map(lista => (
                                        <option key={lista.trm_periodo} value={lista.trm_periodo}>{periodo[lista.trm_periodo]}</option>
                                    )) : null
                                }
                            </select>
                            <select name="tt_conc" id="tt_conc" className={classesApp.lrg33} style={{marginLeft: '1rem'}} value={aln_tt_pts} onChange={e => setAln_tt_pts(e.target.value)}>
                                <option value="-1">Não</option>
                                <option value="0">Sim</option>
                            </select>
                        </div>

                        <div className={classesApp.colunas}>
                        <label className={classesApp.lrg33}>Sexo</label>
                        <label className={classesApp.lrg33} style={{marginLeft: '1rem'}}>Idade inicial</label>
                        <label className={classesApp.lrg33} style={{marginLeft: '1rem'}}>Idade final</label>
                        </div>
                        <div className={classesApp.colunas}>
                            <select name="sexo" id="sexo" className={classesApp.lrg33} value={aln_sexo} onChange={e => setAln_sexo(e.target.value)}>
                                <option value="-1">Ambos</option>
                                {
                                    dadosLista.sexo ? dadosLista.sexo.map(lista => (
                                        <option key={lista.aln_sexo} value={lista.aln_sexo}>{sexo[lista.aln_sexo]}</option>
                                    )) : null
                                }
                            </select>
                            <input type="number" name='idadeIni' id='idadeIni' min="0" max="110" value={idade_ini} onChange={e => setIdade_ini(e.target.value)} className={classesApp.lrg33} style={{marginLeft: '1rem'}}/>                         
                            <input type="number" name='idadeFim' id='idadeFim' min="0" max="120" value={idade_fin} onChange={e => setIdade_fin(e.target.value)} className={classesApp.lrg33} style={{marginLeft: '1rem'}}/> 
                        </div>
                    </div>                                                        
                </fieldset> 
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end'}}> 
                    <label className={classesApp.btnAcoes} style={{width: '15rem', textAlign: 'center'}} onClick={() => filtra()}>Filtrar</label>                  
                </div>
                <div className={classes.resultados}>
                    <div className={classesApp.colunas}>
                        <label className={classesApp.lrg25}>Qtd. alunos total: {estatistica.qtdAln}</label>
                        <label className={classesApp.lrg25}>Masculino: {(estatistica.qtdMasc) ? estatistica.qtdMasc : 0}</label>
                        <label className={classesApp.lrg25}>Feminino: {(estatistica.qtdFem) ? estatistica.qtdFem : 0}</label>
                        <label className={classesApp.lrg25}>Média de idade: {Math.trunc(estatistica.medId)}</label>
                    </div>  
                    <div className={classesApp.colunas}>
                        <label className={classesApp.lrg25}>Média das notas: {parseInt(estatistica.medNotas).toFixed(2)}</label>
                        <label className={classesApp.lrg25}>Nota Máxima: {estatistica.maxNota}</label>
                        <label className={classesApp.lrg25}>Nota Mínima: {estatistica.minNota}</label>
                        <label className={classesApp.lrg25}>Conheciam o texto: {estatistica.qtdCon}</label>
                    </div>
                </div>  

                {/* Níveis */}
                <div style={{display: 'flex', width: '100%'}}>
                    <label style={{border: '0.3rem solid coral', borderRadius: '2rem', padding: '0.8rem', width: '16rem', textAlign: 'center', backgroundColor: estatistica.medNotas * 100 / lacunas <= 44 ? 'coral' : '#fafafa' }}>{'<= 44%'}</label>
                    <label style={{border: '0.3rem solid #e4ff1a', borderRadius: '2rem', padding: '0.8rem', width: '16rem', textAlign: 'center', backgroundColor: estatistica.medNotas * 100 / lacunas > 44 && estatistica.medNotas * 100 / lacunas <= 57  ? '#e4ff1a' : '#fafafa', marginLeft: '1rem', marginRight: '1rem'}}>{'> 44% e <= 57%'}</label>
                    <label style={{border: '0.3rem solid chartreuse', borderRadius: '2rem', padding: '0.8rem', width: '16rem', textAlign: 'center', backgroundColor: estatistica.medNotas * 100 / lacunas > 57 ? 'chartreuse' : '#fafafa'}}>{'> 57%'}</label>
                </div>     


                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}> 
                    <div style={{width: '50%'}}>
                        <label style={{fontSize: '2rem'}}>Pontuação máxima do teste: {lacunas}</label>
                    </div>
                    <div className={classesApp.containerBtnAcoes} style={{width: '50%', margin: '0rem 0rem'}}>
                        <label className={classesApp.btnAcoes} style={{display: (apresentacaoDados === 1 || apresentacaoDados === 2) ? 'inline' : 'none'}} onClick={() => setApresentacaoDados(0)}>Resumo</label>
                        <label className={classesApp.btnAcoes} style={{display: (apresentacaoDados === 0 || apresentacaoDados === 2) ? 'inline' : 'none'}} onClick={() => setApresentacaoDados(1)}>Completo</label>
                        {/*<label className={classesApp.btnAcoes} style={{display: (apresentacaoDados === 0 || apresentacaoDados === 1) ? 'inline' : 'none'}} onClick={() => setApresentacaoDados(2)}>Pesquisa</label>*/}
                    </div>                    
                </div> 
                <div className={classesApp.bordaTable}>
                    {(apresentacaoDados === 0) && (
                        <table id="table-to-xls">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Desempenho</th>
                                    <th>Pontuação</th> 
                                    <th>Lac. s/ resp.</th>   
                                    {/*<th>Ver teste</th>----------iplementação futura*/}
                                </tr>
                            </thead>  
                            <tbody>
                                {
                                    pesquisa.map((registro, n = n++) => (
                                        <tr key={registro.aln_tt_id}>
                                            {/*<th>{registro.aln_nome}</th>*/}
                                            <th>{'Aluno ' + (n + 1)}</th>
                                            <th style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><div style={{width: '1.1rem', height: '1.1rem', borderRadius: '100%', margin: '1rem', backgroundColor: registro.aln_tt_pts * 100 / lacunas <= 44 ? 'coral' : registro.aln_tt_pts * 100 / lacunas > 44 && registro.aln_tt_pts * 100 / lacunas <= 57 ? '#e4ff1a': 'chartreuse'}}></div></th>
                                            <th>{registro.aln_tt_pts}</th>
                                            <th>{registro.aln_tt_branco}</th>
                                            {/*<th><MdTextSnippet size={'2.5rem'} className={classesApp.iconeTable} /></th>*/}
                                        </tr>
                                    ))
                                }                                              
                            </tbody>  
                        </table>  
                    )} 
                    {(apresentacaoDados === 1) && (
                        <table id="table-to-xls">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Idade</th> 
                                    <th>Sexo</th> 
                                    <th>Ano / Módulo</th>
                                    <th>Curso</th> 
                                    <th>Período</th>
                                    <th>Nota</th> 
                                    <th>Desempenho (1 - frustração, 2 - instrucional e 3 - independente)</th>
                                    <th>Lac. s/ resp.</th>
                                    <th>Conhecia o texto</th> 
                                    <th>Identificou palavras desconhecidas</th> 
                                    <th>Dispositivo utilizado</th> 
                                </tr>
                            </thead>  
                            <tbody>
                                {
                                    pesquisa.map(registro => (
                                        <tr key={registro.aln_tt_id}>
                                            <th>{registro.aln_nome}</th>
                                            <th>{registro.aln_tt_idade}</th> 
                                            <th>{sexo[registro.aln_sexo]}</th> 
                                            <th>{registro.etapa}</th>
                                            <th>{registro.cur_nome}</th> 
                                            <th>{periodo[registro.trm_periodo]}</th>
                                            <th>{registro.aln_tt_pts}</th>
                                            <th>{registro.aln_tt_pts * 100 / lacunas <= 44 ? '1' : registro.aln_tt_pts * 100 / lacunas > 44 && registro.aln_tt_pts * 100 / lacunas <= 57 ? '2': '3'}</th>
                                            <th>{registro.aln_tt_branco}</th>
                                            <th>{registro.aln_tt_conhece_txt === 1 ? 'Sim' : 'Não'}</th> 
                                            <th>{registro.aln_tt_plv_desc === 1 ? 'Sim' : 'Não'}</th> 
                                            <th>{dispositivos[registro.aln_tt_dispositivo]}</th>
                                        </tr>
                                    ))
                                }                                              
                            </tbody>  
                        </table>  
                    )}               
                </div> 
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end'}}> 
                    <ReactHTMLTableToExcel 
                        id="test-table-xls-button"
                        className={classesApp.btnAcoes}
                        table="table-to-xls"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Download em XLS"                             
                    />                   
                </div>                
                <br />                                
            </div>        
        );
    } else {
        return (
            <>
                <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                    Nenhum resultado foi encontrado!
                </div>
                <div className={classesApp.containerBtnAcoes}>
                    <label className={classesApp.btnAcoes} onClick={() => renderiza(0)}>Voltar</label>
                </div>
            </>                      
        );
    }
}
  
export default TesteDados;