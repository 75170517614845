import React, { useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css';

import api from '../../../services/api';
import { dados } from '../../../services/auth'; 

function DadosProf() {

    const [ttTxt, setTtTxt] = useState(0); 
    const [ttTes, setTtTes] = useState(0);

    useEffect(() => {
        carregaDados(); 
    }, []); 
    
    // contar número de textos e de testes
    async function carregaDados() {
        try {
            const response = await api.get('/usuario/info/' + dados.id);
            setTtTxt(response.data.textos);  
            setTtTxt(response.data.testes);           
        } catch (error) {
            alert(error.response.data.message);
        }                
        
    }

    return (
        <div>
            <h1 className={classesApp.destacaH1}>Olá, {dados.nome}</h1>
            <h2>Textos inseridos: {ttTxt}</h2>
            <h2>Testes criados: {ttTes}</h2>
            <h1>Escolas vinculadas</h1>                
        </div>
    );
}

export default DadosProf;