import React, { useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css';

import api from '../../../services/api';

function CadCursos( { renderiza, idInst, statusVis } ) { 

    const [tipos, setTipos] = useState([]); 
    const [idTipo, setIdTipo] = useState(-1); 
    const [areas, setAreas] = useState([]); 
    const [idArea, setIdArea] = useState(-1); 
    const [nomeCurso, setNomeCurso] = useState('');

    useEffect(() => {
        listaTipo();
    }, []);

    async function listaTipo() { 
        try {            
            const response = await api.get('/tipoc');                  
            setTipos(response.data); 
        } catch {
            setTipos([]); 
        }           
    } 

    async function listaAreas(idTp) { 
        setIdArea(-1);
        setIdTipo(idTp); 
        try {
            const response = await api.get('/tpa?tpa=' + idTp);                
            setAreas(response.data); 
        } catch {
            setAreas([]); 
        } 
    } 

    async function handleCreate(){ 
        const ins_id = idInst[0];
        const cur_nome = nomeCurso;
        const arc_id = idArea; 
        const tpa_id = idTipo;
        const dados = {
            ins_id, 
            cur_nome, 
            arc_id, 
            tpa_id             
        };
        
        try {
            const response = await api.post('/cursos', dados); 
            alert("Id do curso: " + response.data);            
        } catch (err) {
            alert("Erro no cadastro, tente novamente.\n" + err);
        }        
    } 

    function handleValida() { 
        let irregulares = '';
        if (nomeCurso === '') irregulares += '- nome do curso\n';   
        if (nomeCurso.length > 0 && nomeCurso.length <= 6) irregulares += '- inserir o nome completo do curso (pelo menos 6 caracteres)\n';
        if (idTipo === -1) irregulares += '- selecione o tipo de curso\n';  
        if (idArea === -1) irregulares += '- selecione a área do curso\n';          
                    
        if (irregulares === '') {
            return true;
        } else {
            alert('Campos a serem preenchidos: \n' + irregulares);
            return false;
        }
    }

    function cadastradaCurso() {
        if (handleValida() === true) { 
            handleCreate(); 
            if (statusVis === 1) {
                renderiza(2);    
            } else {
                renderiza(1);
            }             
        }  
    }

    return (
        <div> 
            <h1>Cadastro de cursos</h1>
            <h2 style={{marginBottom: '2rem'}}>{idInst[1]}</h2>
            <label>Nome do curso</label>
            <input type="text" maxLength={60} name='nomeCur' id='nomeCur' value={nomeCurso} onChange={e => setNomeCurso(e.target.value)} />            
            <div className={classesApp.colunas}>
                <label className={classesApp.lrg50}>Tipo</label>                    
                <label className={classesApp.lrg50} style={{marginLeft: '1rem'}}>Área</label>                  
            </div> 
            <div className={classesApp.colunas}>
                <select className={classesApp.lrg50} value={idTipo} onChange={e => listaAreas(e.target.value)} name="tipoCur" id="tipoCur">
                    <option value="-1" style={{height: '2rem'}}>-</option>
                    {
                        tipos.map(tipo => (
                            <option key={tipo.tpa_id} value={tipo.tpa_id}>{tipo.tpa_nome}</option>
                        ))
                    }
                </select>
                <select className={classesApp.lrg50} style={{marginLeft: '1rem'}} value={idArea} onChange={e => setIdArea(e.target.value)} name="areaCur" id="areaCur">
                    <option value="-1">-</option>
                    {
                        areas.map(area => (
                            <option key={area.arc_id} value={area.arc_id}>{area.arc_nome}</option>
                        ))
                    }
                </select>                               
            </div>                                                       

            <div className={classesApp.containerBtnAcoes}> 
                <label className={classesApp.btnAcoes} onClick={() => renderiza(1)}>Cancelar</label>
                <label className={classesApp.btnAcoes} onClick={() => cadastradaCurso()} >Registrar novo curso</label>
            </div>                                                   
        </div>                          
    );
}
  
export default CadCursos;