import React, {  useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css';

import api from '../../../services/api';
import pad from '../../../services/pad';

import { MdTextSnippet } from "react-icons/md";

function AlnListaCursos( { renderiza, listaTestesAln, testes, defInfoTes, defAlnTtId, defRecupTt } ) { 

    const txtEtapa = ['Confirmar turma', 'Respondendo questionário', 'Instruções', 'Em andamento', 'Finalizado', 'Encerrado'];    

    useEffect(() => {
        listaTestesAln();
    }, []);

    function apresentaData(dtBd) {
        let data = new Date(dtBd); 
        const dt =  pad(data.getDate()) + '/' + pad(data.getUTCMonth() + 1) + '/' + data.getFullYear(); 
        return dt;
    } 

    async function mostraTeste(aln_tt_id, tes_id, aln_tt_etapa) { 
        try {            
            const response = await api.get('/testes/teste/' + tes_id); 
            defInfoTes(response.data); 
            defAlnTtId(aln_tt_id); 
            // verificar a situação do teste 
            if (aln_tt_etapa < 4) { // significa que ainda não foi finalizado
                defRecupTt(aln_tt_etapa);
                renderiza(2);                
            } else { 
                renderiza(-1); // para ver os resultados se possível
            }            
        } catch (error) { 
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }            
        }
    }

    if (testes[0]) {
        return (
            <>
                <h1>Testes realizados</h1>
                <div className={classesApp.bordaTable}>                
                    <table>
                        <thead>
                            <tr>
                                <th className={classesApp.ttTable}>Identificação</th>
                                <th className={classesApp.ttTable}>Data</th>
                                <th className={classesApp.ttTable}>Etapa</th>
                                <th className={classesApp.ttTable}>Ver teste</th>
                            </tr>
                        </thead>  
                        <tbody>
                            {                                                                      
                                testes.map(teste => (                             
                                    <tr key={teste.aln_tt_id}> 
                                        <th>{teste.tes_identificacao}</th>
                                        <th>{apresentaData(teste.aln_tt_data)}</th>
                                        <th>{txtEtapa[teste.aln_tt_etapa]}</th>  {/* Em andamento se não estiver finalizado, assim é possível continuar clicando aqui, passado duas horas sem o teste ser finalizado o mesmo será dado como finalizado e ao invés do link para continuar será mostrada a nota */}
                                        <th><MdTextSnippet size={'2.5rem'} className={classesApp.iconeTable} onClick={() => mostraTeste(teste.aln_tt_id, teste.tes_id, teste.aln_tt_etapa)} /></th> 
                                    </tr>                             
                                ))
                            }                   
                        </tbody>  
                    </table>            
                </div>
            </>                   
        );
    } else {
        return <></>
    }
}

export default AlnListaCursos;