import React, { useState, useEffect } from 'react';

import classesApp from '../../App.module.css';

import Header from '../professores/headerHomeProf';
import MenuTexto from './textosPesquisa'; 
import TextosLista from './textosLista'; 
import TextosCadastro from './textosCadastro';

import api from '../../../services/api'; 
import { dados } from '../../../services/auth'; 

function Textos( { teste, carregarTxt, apresentacaoTes } ) {
    const [apresentacao, setApresentacao] = useState(0); 
    const [textos, setTextos] = useState([]); 
    const [vlrPesq, setVlrPesq] = useState(''); 
    const [txtSel, setTxtSel] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // usado para posicionar no início da tela ao carregar esta página
    }, []);     

    function renderiza(op) {
        setApresentacao(op);
    }

    async function listaTextos() { 
        try { 
            let response = [];
            if (dados.acesso === 'dende') {
                response = await api.get('/textos?titulo=' + vlrPesq);
            } else {
                response = await api.get('/textos?usu_id=' + dados.id + '&titulo='  + vlrPesq);  
            }            
            
            setTextos(response.data); 
        } catch {
            setTextos([]); 
        }   
    }  
    
    function txtPesquisa(vlr) {
        setVlrPesq(vlr);
    }

    async function exibeTexto(id) {
        const response = await api.get('/textos/perfil/' + id); 
        defineTxt(response.data);
        if (apresentacao === 0 || apresentacao === 2)
        renderiza(1);
        else
        renderiza(2);
    }

    function defineTxt(texto) {
        setTxtSel(texto); 
    }
        
    return (
        <div className={classesApp.centraliza}>            
            {!teste && (
                <Header />
            )}
            <div className={classesApp.home} style={teste ? {padding: '0rem'} : {}}> 
            {/* inicia com listagem dos textos com paginação */}                      
                <h1>Textos</h1> 
                <MenuTexto renderiza={renderiza} apresentacao={apresentacao} vlrPesq={vlrPesq} txtPesquisa={txtPesquisa} listaTextos={listaTextos} />
                {apresentacao < 3 && ( // só lista
                    <TextosLista teste={teste} apresentacaoTes={apresentacaoTes} carregarTxt={carregarTxt} textos={textos} listaTextos={listaTextos} exibeTexto={exibeTexto} apresentacao={apresentacao} />
                )}
                {apresentacao === 1 && ( // edita
                    <TextosCadastro renderiza={renderiza} apresentacao={apresentacao} txtSel={txtSel} />
                )}    
                {apresentacao === 2 && ( // edita
                    <TextosCadastro renderiza={renderiza} apresentacao={apresentacao} txtSel={txtSel} />
                )}                 
                {apresentacao === 3 && ( // só cadastra
                    <TextosCadastro renderiza={renderiza} apresentacao={apresentacao} />
                )}                               
            </div>              
        </div>        
    );
}
  
export default Textos;