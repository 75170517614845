import React, { useState } from 'react'; 
import { useHistory } from "react-router-dom"; // manipular página apresentada via código

import classes from '../publicas.module.css'; 
import classesApp from '../../../App.module.css';

import { MdVisibility } from "react-icons/md"; 

import logo from '../../../../assets/logo_rxo.png'; 

import api from '../../../../services/api'; 

function PopConfEmail( { usuario, defPopConfEmail, tipo, nome } ) { 
    const [email, setEmail] = useState(usuario);  
    const [mensErr, setMensErr] = useState(''); 
    const [mensSucess, setMensSucess] = useState('');
    const [emailOk, setEmailOk] = useState(false);     

    let history = useHistory(); // manipular página apresentada via código

    function valida() { 
        let erro = '';
        if (validateEmail(email) === false) erro = 'Erro: E-mail inválido';
        if (email === '') erro = 'Erro: Preencher o e-mail'; 
        if (erro === '') { 
            ativaRecSenha(); 
            setMensErr('');    
        } else {
            setMensErr(erro); 
        }
    } 

    function validateEmail(email) { 
        // https://www.horadecodar.com.br/2020/09/13/como-validar-email-com-javascript/
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    } 

    async function ativaRecSenha() {  
        const dados = {
            email, 
            tipo 
        };
                 
        try {
            const response = await api.post('/session/email/rec/senha', dados); 
            setMensSucess(response.data.message); 
            setEmailOk(true);                        
        } catch (error) { 
            setMensErr(error.response.data.message); 
        }
    }

    return (
        <div className={classesApp.formPopup}> 
            <div style={{display: 'flex', justifyContent: 'right', width: '100%', marginBottom: '0rem'}} onClick={() => defPopConfEmail()}>
                <label style={{fontSize: '1.6rem', textAlign: 'center', color: 'coral', margin: '0rem 1rem', border: '0rem', padding: '0rem',  width: '100%'}}>{mensErr}</label>
                <label style={{fontSize: '1.4rem', borderRadius: '20rem', margin: '0rem', width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>X</label>                
            </div>
            {/* Insere e-mail */}
            <div style={emailOk ? {display: 'none'} : { display: 'inline-block' }}>
                <h3 style={{color: '#fafafa', textAlign: 'center', marginBottom: '1rem'}}>
                    Olá {nome}!
                </h3>
                <h3 style={{color: '#fafafa', textAlign: 'center', marginBottom: '1rem'}}>
                    Confirme ou insira abaixo o e-mail utilizado nesta plataforma para efetuar a recuperação da senha!
                </h3>   

                <input 
                    type="text" 
                    placeholder='Novo e-mail' 
                    value={email} 
                    maxLength={70} 
                    onChange={e => setEmail(e.target.value)} 
                    style={{margin: '1rem 0rem', textAlign: 'center'}} 
                />
                            
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <label 
                        style={{width: '20rem', textAlign: 'center', marginTop: '1rem'}}
                        onClick={() => valida()}
                    >Recuperar senha</label>
                </div>                
            </div>    
            <div 
                onClick={() => history.push("/")}
                style={emailOk ? {display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'} : { display: 'none' }}
            > 
                <h1 style={{color: '#fafafa', margin: '2rem', textAlign: 'center', fontSize: '2.5rem'}}>{mensSucess}</h1>
                <label 
                    style={{width: '20rem', textAlign: 'center', marginTop: '1rem'}}
                    onClick={() => valida()}
                >Ok</label>
            </div>                        
        </div>
    )    
}

function Senha( { usuario, login, mensagem, tipo, nome } ) {

    const [senhaDg, setSenhaDg] = useState(''); 
    const [popEmail, setPopEmail] =  useState(false); 
    const [mostraSenha, setMostraSenha] = useState(false); 
    let history = useHistory();

    function exibe() {
        mostraSenha ? setMostraSenha(false) : setMostraSenha(true); 
    }

    function defPopConfEmail() {
        setPopEmail(false);
    }

    return(
        <div>
            {
                popEmail === true && ( 
                    <PopConfEmail usuario={usuario} nome={nome} defPopConfEmail={defPopConfEmail} tipo={tipo} />
                )
            }  
            
            <div  className={classes.centralizaLog}>            
                <div className={classes.titulo}>
                    <img className={classes.logo} src={logo} alt="Logotipo teste cloze" onClick={() => history.push("/")} style={{cursor: 'pointer'}} />
                    <label className={classes.txtTitulo}>OnlineCloze</label>
                </div>
                <label>{usuario}</label>
                <input 
                    //type="password" 
                    type={mostraSenha ? "text" : "password"}
                    placeholder='senha' 
                    value={senhaDg}
                    onChange={(e) => setSenhaDg(e.target.value)}
                />
                <MdVisibility size={'2.5rem'} className={classesApp.iconeForm} style={{position: 'absolute', right: '6rem', top: '18rem'}} onClick={() => exibe()} /> 
                <div className={classes.containerBtnAuxilio}>
                    <label 
                        className={classes.btnAuxilio} 
                        onClick={() => setPopEmail(true)}
                    >Esqueceu a senha?</label>
                </div>
                <div className={classesApp.containerBtnPub}>
                    <label 
                        className={classesApp.btnAcaoSecundariaEscuro} 
                        onClick={() => login(usuario, senhaDg)} 
                        style={{margin: '0rem 0.5rem'}}
                    >Acessar o sistema</label>
                </div>
                {
                    mensagem && (
                        <div className={classes.avisoErro} style={{margin: '0rem 0.5rem'}}>
                            <label>{mensagem}</label>
                        </div>
                    )
                }
            </div>            
        </div>        
    );
}

export default Senha;