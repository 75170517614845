import React from 'react';

import classes from  './headerPages.module.css';

function HeaderPages(props) {
    return (
        <div className={classes.container}>
            <hr />                            
            <h1>{props.title}</h1>
        </div>      
    );
}
  
export default HeaderPages;