import React, {  useState } from 'react'; 

import classesApp from '../../App.module.css';

import api from '../../../services/api';

//import { MdEditNote } from "react-icons/md";

function AlnAcessoTeste( { renderiza, defInfoTes } ) { 

    const [cod, setCod] = useState(''); 

    async function validaCodTeste() {
        try {            
            const response = await api.post('/testes/verifica', {tes_cod_acesso: cod}); 
            defInfoTes(response.data); 
            renderiza(2)
        } catch (error) { 
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }            
        }
    }
    return (
        <div className={classesApp.containerMenuPg}>
            <label style={{whiteSpace:'nowrap', padding:'0rem 2rem'}}>Insira o código do teste</label> 
            <input type="text" value={cod} onChange={e => setCod(e.target.value)} />
            <label onClick={() => validaCodTeste()} className={classesApp.botaoMenu} >Fazer teste</label> 
        </div>       
    );
}
  
export default AlnAcessoTeste;