import React, {  useState } from 'react'; 
import { useHistory } from "react-router-dom";

import classesApp from '../../../App.module.css';
import classes from './alnTeste.module.css' 

import pad from '../../../../services/pad'; 
import api from '../../../../services/api';

function EscolheTurma( { renderiza, cursos, usu, defAlnTtId, teste } ) { 

    const data = new Date(); 
    const dtCtr = data.getFullYear() + '-' + pad(data.getUTCMonth() + 1) + '-'  + pad(data.getDate());
    const [trm_id, setTrm_id] = useState(0); 
    const [aln_tt_etapa, setAln_tt_etapa] = useState(0); //0 – Confirmar turma, 1 – Responder questionário, 2 – Fazer teste, 3 – Teste concluído
    let history = useHistory();
    
    // com a turma selecionado criar o testeAln - info: aln_id, trm_id, tes_id, aln_tt_data, aln_tt_etapa -> definir valores --- valores temporários para: aln_tt_idade, aln_tt_conhece_txt, aln_tt_pts, aln_tt_plv_desc, aln_dispositivo
    async function geraAtv() { 
        const dadosCad = { 
            aln_id: usu, 
            trm_id, 
            tes_id: teste.tes_id, 
            aln_tt_data: dtCtr, 
            aln_tt_etapa
        }
        try {
            if (trm_id === 0) {
                alert('Para prosseguir é necessário escoher a turma pela qual o teste será realizado!'); 
            } else {
                const response = await api.post('/alnteste', dadosCad); 
                defAlnTtId(response.data.aln_tt_id);                 
                
                // tipo teste pesquisa(0) 1 - atividade(1) 2
                if (teste.tes_link_form) { {/* Se tiver link */}
                    const responseMdEtpPes = await api.patch('/alnteste/mdetapa', {aln_tt_etapa: 1, aln_tt_id: response.data.aln_tt_id});
                    renderiza(1); 
                } else { 
                    const responseMdEtpAtv = await api.patch('/alnteste/mdetapa', {aln_tt_etapa: 2, aln_tt_id: response.data.aln_tt_id});
                    renderiza(2); 
                }               
            }            
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);                 
            } else {
                alert(error);
            }             
            history.push("/login");
        }

    }  

    return (
        <div>
            <h2 style={{marginBottom: '1rem', marginLeft: '1rem'}}>Selecione a turma em que você fará o teste</h2>
            <div className={classesApp.bordaTable} style={{margin: '0rem 1rem'}}>
                <table>
                    <thead>
                        <tr>
                            <th className={classesApp.ttTable}>Selecionar</th>
                            <th className={classesApp.ttTable}>Curso</th>
                            <th className={classesApp.ttTable}>Turma</th>                          
                        </tr>
                    </thead>  
                    <tbody>    
                        {                                                                      
                            cursos.map(item => ( 
                                <tr key={item.trm_id}> 
                                    <th className={classes.radioDefineTurma}><input type="radio" id={item.trm_id} name="selTrm" value={item.trm_id} onClick={() => setTrm_id(item.trm_id)} /></th>
                                    <th>{item.cur_nome}</th>
                                    <th>{item.trm_nome}</th>
                                </tr>                             
                            ))
                        }                                                                        
                    </tbody>  
                </table> 
            </div> 

            <div className={classesApp.containerBtnAcoes}>                    
                <label className={classesApp.btnAcoes} onClick={() => geraAtv()}>Próxima etapa</label>
            </div>
        </div>                                                                    
    );
}
  
export default EscolheTurma;