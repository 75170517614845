import React, { useState } from 'react';

import classesApp from '../../App.module.css'; 

import Header from '../professores/headerHomeProf';
import PoliticasLista from './politicasLista'; 
import PoliticasCadastro from './politicasCadastro';

import api from '../../../services/api'; 
import { dados } from '../../../services/auth'; 

function Politicas() {
    const [apresentacao, setApresentacao] = useState(0); 
    const [politicas, setPoliticas] = useState([]); 
    const [politicasSel, setPoliticasSel] = useState([]); 

    function renderiza(op) {
        setApresentacao(op);
    }

    async function listaPoliticas() { 
        try { 
            const response = await api.get('/politicas');
            setPoliticas(response.data); 
        } catch (error) { 
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }  
            setPoliticas([]); 
        }   
    }  

    async function exibePolitica(id) {
        try {
            const response = await api.post('/politicas/perfil', {pol_id: id}); 
            setPoliticasSel(response.data);
            if (apresentacao === 0 || apresentacao === 2)
                renderiza(1);
            else
                renderiza(2);   
        } catch (error) {
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert('Erro ao acessar o servidor:' + error);
            }  
        }        
    } 

    function handleAcaoBtn() {
        if (apresentacao === 3) {
            renderiza(0);
        } else {
            renderiza(3);
        }
    }
    
    if (dados.acesso === 'dende') {
        return (
            <div className={classesApp.centraliza}>            
                <Header />
                <div className={classesApp.home}>                                  
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <h1>Políticas</h1> 
                        <label 
                            className={classesApp.btnAcaoSecundariaClaro} 
                            style={{display: 'flex', padding: '0rem 2rem', alignItems: 'center', justifyContent: 'center'}} 
                            onClick={() => handleAcaoBtn()}
                        >{apresentacao === 3 ? 'Cancelar' : 'Nova política'}</label> 
                    </div>                
                    
                    {apresentacao <= 2 && (
                        <PoliticasLista politicas={politicas} listaPoliticas={listaPoliticas} exibePolitica={exibePolitica} />
                    )} 
                    {apresentacao === 1 && (
                        <PoliticasCadastro renderiza={renderiza} apresentacao={apresentacao} politicasSel={politicasSel} />
                    )} 
    
                    {apresentacao === 2 && (
                        <PoliticasCadastro renderiza={renderiza} apresentacao={apresentacao} politicasSel={politicasSel} />
                    )} 
    
                    {apresentacao === 3 && (
                        <PoliticasCadastro renderiza={renderiza} apresentacao={apresentacao} politicasSel={politicasSel} />
                    )}
                             
                </div>              
            </div>        
        );
    } else {
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                Seu usuário não tem permissão para acessar este recurso!
            </div>
        );
    }
    
}
  
export default Politicas;