import React from 'react';

import classes from './publicas.module.css';
import classesApp from '../../App.module.css';

import { FcReading } from "react-icons/fc";
import { GiBookshelf } from "react-icons/gi"; 

function Home() {
    return (
        <div className={classesApp.centraliza}>
            <div className={classesApp.home} id='/'> 
                {/*<h1 className={classesApp.destacaH1}>Hello, world.</h1>*/}
                <div className={classes.apresentacao}>
                    <div>                        
                        <h1>Esta plataforma permite que professores e pesquisadores apliquem e analisem resultados de testes de Cloze.</h1>  
                        <p>
                        O teste de Cloze é utilizado para diversas finalidades, como a identificação do nível de compreensão de leitura, testes de proficiência, programas de aprimoramento do nível de leitura, atividades para identificação de compreensão de termos técnicos, pesquisas sobre qualidade de textos direcionados a determinados públicos...  
                        </p>      
                        <p>
                        Existem diversos tipos de Cloze, porém neste ambiente oferecemos a possibilidade da criação de atividades em que lacunas devem ser preenchidas, e a correção dos valores inseridos ocorre de forma literal. Quem elaborar a atividade, tem a possibilidade de definir um número fixo de lacunas ou escolher as palavras manualmente.
                        </p>                   
                    </div> 
                    <div className={classes.leitor}>
                        <FcReading
                            size={'15rem'}
                        /> 
                    </div>    
                </div>                             
                <div className={classes.livros}>
                    <GiBookshelf
                        size={'5.0rem'} 
                        color='#320b86'
                    />
                </div>                          
            </div>              
        </div>        
    );
}
  
export default Home;