import { Link } from 'react-router-dom'; 
import { logout, dados } from '../../../services/auth'; 

function NavLinksProf(props) {
    return ( 
        <ul>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <Link to='/homeprof'>Início</Link>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <Link to='/homeprof/textos'>Textos</Link>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <Link to='/homeprof/testes'>Testes</Link>
            </li>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <Link to='/homeprof/escolas'>Escolas</Link>                
            </li>               
            {
                dados.acesso === 'dende' && (
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <Link to='/homeprof/politicas'>Políticas</Link>                
                    </li>   
                )
            }
            {
                dados.acesso === 'dende' && (
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <Link to='/homeprof/termos'>Termos</Link>                
                    </li>   
                )
            }    
            {
                dados.acesso === 'dende' && (
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                        <Link to='/homeprof/config'>Configurações</Link>                
                    </li>   
                )
            }                 
            <li onClick={() => { 
                logout();
                props.isMobile && props.closeMobileMenu();
            }}>
                <Link to='/'>Sair</Link>
            </li>
        </ul> 
    );
}

export default NavLinksProf;