import { Link } from 'react-router-dom'; 
import { logout } from '../../../services/auth';

function NavLinksAln(props) {
    return ( 
        <ul>
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <Link to='/homealn'>Início</Link>
            </li>  
{/*                        
            <li onClick={() => props.isMobile && props.closeMobileMenu()}>
                <Link to='/homealn/config'>Configurações</Link>                
            </li>
*/}            
            <li onClick={
                () => {
                    logout();
                    props.isMobile && props.closeMobileMenu();
                }
            }>
                <Link to='/'>Sair</Link>
            </li>
        </ul> 
    );
}

export default NavLinksAln;