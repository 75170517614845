import React from 'react';

/*import '../../App.css';*/

function CadTextos({ texto, atualizaTexto }) {

    return (
        <div>
            <div>
                <div style={{ width: '600px' }}>
                <h1>Inserir texto aqui</h1>
                <textarea
                    value={texto}
                    onChange={atualizaTexto}
                    style={{ width: '119%', height: '300px', marginTop: 10, fontSize: 'large', borderColor: 'chartreuse', borderWidth: 2, borderRadius: 10 }}
                />                
                </div>
            </div>
        </div>      
    );
}
  
export default CadTextos;
  