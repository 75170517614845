import React, { useState, useEffect } from 'react'; 
import { useHistory } from "react-router-dom";

import classesApp from '../../../App.module.css'; 

import api from '../../../../services/api';

function ResultadosFinalTt( { teste, renderizaAln, permissao, aln_tt_id, alnVisualiza } ) { 

    const [nota, setNota] = useState(0); 
    const [total, setTotal] = useState(0);
    let history = useHistory();

    useEffect(() => {
        apresentaRes();
    }, []);

    function handleAcaoBtnSair() {
        //history.push("/homealn");
        renderizaAln(0);
    }

    async function apresentaRes() {
        try {            
            const response = await api.get('/respcloze/res?alntt=' + aln_tt_id);             
            setNota(response.data.nota); 
            setTotal(response.data.total);           
        } catch (error) { 
            if (error.response) {
                alert(error.response.data.message);
            } else {
                alert(error);
            }            
        }
    }

    return (
        <div>  
            {!alnVisualiza && (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem'}}>
                    <label style={{fontSize: '2rem'}}>Teste finalizado com sucesso!</label>                    
                </div>                     
            )}

            {permissao === 0 && (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem'}}>
                    <label style={{fontSize: '2rem'}}>O responsável pelo teste não liberou a apresentação da nota!</label>                    
                </div>                     
            )} 

            {permissao > 0 && (       
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem'}}>
                    <label style={{fontSize: '2rem'}}>Pontuação Máxima: {total}</label>
                    <label style={{fontSize: '2rem'}}>Minha pontuação: {nota}</label>
                </div>    
            )}  
            {(permissao < 2 || !alnVisualiza) && (       
                <div className={classesApp.containerBtnAcoes}>                    
                    <label className={classesApp.btnAcoes} style={{width: '10rem', display: 'flex', justifyContent: 'center'}} onClick={() => handleAcaoBtnSair()}>Ok</label>
                </div>  
            )}                          
        </div>        
    );
}
  
export default ResultadosFinalTt;