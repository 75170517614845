// https://www.youtube.com/watch?v=hWukAkEzBB4
import React from 'react';
import InputMask from 'react-input-mask'; 

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

function MaskedInput({ value, onChange }) {

    function handleChange(event) {
        onChange({
            ...event, 
            target: {
                ...event.target, 
                value: onlyNumbers(event.target.value),
            }
        }); 
    }

    return (
        <InputMask 
            mask={"99.999.999/9999-99"} 
            value={value} 
            onChange={handleChange} 
            style={{marginLeft: '1rem'}}
        />
    );

}

export default MaskedInput;

    