import classes from './headerHome.module.css';
import NavLinks from './navLinks';

function Navigation() {
    return (
        <nav className={classes.Navigation}>
            <NavLinks />
        </nav>        
    );
}

export default Navigation;